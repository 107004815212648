import { JetClient, JetMarket } from '@jet-lab/jet-engine';
import { BN, Wallet } from '@project-serum/anchor';
import * as anchor from '@project-serum/anchor';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  Token,
  TOKEN_PROGRAM_ID,
  AccountLayout as TokenAccountLayout,
} from '@solana/spl-token';
import {
  Keypair,
  Signer,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
} from '@solana/web3.js';
import { CITRUS_MARKET_AUTHORITY_PUBKEY, CITRUS_MARKET_PUBLICKEY } from '../consts';
import { TxnResponse } from '../faucet/jetFaucet';
import { Amount, Asset, AssetStore, CitrusReserve, Reserve, ReserveMetadata } from './JetTypes';
import {
  findCollateralAddress,
  findDepositNoteAddress,
  findDepositNoteDestAddress,
  findLoanNoteAddress,
  findObligationAddress,
  InstructionAndSigner,
  sendAllTransactions,
  sendTransaction,
} from './programUtil';
import { TokenAmount } from './tokenAmount';
import { Store } from '../../../store/useStore';
import notification from 'antd/lib/notification';
// import reloadLending from '../../../hooks/reloadLending';

const SECONDS_PER_HOUR: BN = new BN(3600);
const SECONDS_PER_DAY: BN = SECONDS_PER_HOUR.muln(24);
const SECONDS_PER_WEEK: BN = SECONDS_PER_DAY.muln(7);
const MAX_ACCRUAL_SECONDS: BN = SECONDS_PER_WEEK;
// Account
export const SOL_DECIMALS = 9;

export const getAssetPubkeys = async (
  program: anchor.Program,
  wallet: Wallet,
  reserves: ReserveMetadata[],
): Promise<AssetStore> => {
  let [obligationPubkey, obligationBump] = await findObligationAddress(
    // @ts-ignore
    program,
    CITRUS_MARKET_PUBLICKEY,
    wallet.publicKey,
  );

  let assetStore = {
    sol: new TokenAmount(new BN(0), SOL_DECIMALS),
    obligationPubkey,
    obligationBump,
    tokens: {},
  };

  const promises = reserves.map(reserve => {
    const doit = async () => {
      let tokenMintPubkey = reserve.accounts.tokenMint;
      let [depositNoteDestPubkey, depositNoteDestBump] = await findDepositNoteDestAddress(
        // @ts-ignore
        program,
        reserve.accounts.reserve,
        wallet.publicKey,
      );
      let [depositNotePubkey, depositNoteBump] = await findDepositNoteAddress(
        // @ts-ignore
        program,
        reserve.accounts.reserve,
        wallet.publicKey,
      );
      let [loanNotePubkey, loanNoteBump] = await findLoanNoteAddress(
        // @ts-ignore
        program,
        reserve.accounts.reserve,
        obligationPubkey,
        wallet.publicKey,
      );
      let [collateralPubkey, collateralBump] = await findCollateralAddress(
        // @ts-ignore
        program,
        reserve.accounts.reserve,
        obligationPubkey,
        wallet.publicKey,
      );

      let asset: Asset = {
        tokenMintPubkey,
        walletTokenPubkey: await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          tokenMintPubkey,
          wallet.publicKey,
        ),
        walletTokenExists: false,
        walletTokenBalance: TokenAmount.zero(reserve.decimals),
        depositNotePubkey,
        depositNoteBump,
        depositNoteExists: false,
        depositNoteBalance: TokenAmount.zero(reserve.decimals),
        depositBalance: TokenAmount.zero(reserve.decimals),
        depositNoteDestPubkey,
        depositNoteDestBump,
        depositNoteDestExists: false,
        depositNoteDestBalance: TokenAmount.zero(reserve.decimals),
        loanNotePubkey,
        loanNoteBump,
        loanNoteExists: false,
        loanNoteBalance: TokenAmount.zero(reserve.decimals),
        loanBalance: TokenAmount.zero(reserve.decimals),
        collateralNotePubkey: collateralPubkey,
        collateralNoteBump: collateralBump,
        collateralNoteExists: false,
        collateralNoteBalance: TokenAmount.zero(reserve.decimals),
        collateralBalance: TokenAmount.zero(reserve.decimals),
        maxDepositAmount: 0,
        maxWithdrawAmount: 0,
        maxBorrowAmount: 0,
        maxRepayAmount: 0,
      };

      // difficult to solve race condition issue. I'm pretty sure this does.
      assetStore = {
        ...assetStore,
        tokens: { ...assetStore.tokens, [reserve.abbrev]: asset },
      };
    };

    return doit();
  });

  await Promise.all(promises);

  return assetStore;
};

// Deposit
export const deposit = async (
  uiValue: string,
  reserve: Reserve,
  program: anchor.Program,
  wallet: Wallet,
  assetStore: AssetStore | null,
): Promise<[res: TxnResponse, txid: string[]]> => {
  // const connection = program.connection;
  // TODO: maybe use all reserves?
  // const [res, txid] = await refreshOldReserves([reserve], program);
  // if (res !== TxnResponse.Success) {
  //   return [res, txid];
  // }
  if (!assetStore || !wallet) {
    return [TxnResponse.Failed, []];
  }

  // hack
  const connection = program.provider.connection;
  const abbrev = reserve.abbrev;
  let asset = assetStore.tokens[abbrev];
  let depositSourcePubkey = asset.walletTokenPubkey;
  // const lamports = TokenAmount.tokens(uiValue, reserve.decimals);
  const lamports = new BN(Number.parseFloat(uiValue) * 10 ** reserve.decimals);

  // Optional signers
  let depositSourceKeypair: Keypair | undefined;

  // Optional instructions
  // Create wrapped sol ixs
  let createTokenAccountIx: TransactionInstruction | undefined;
  let initTokenAccountIx: TransactionInstruction | undefined;
  let closeTokenAccountIx: TransactionInstruction | undefined;

  // Initialize Obligation, deposit notes, collateral notes
  let initObligationIx: TransactionInstruction | undefined;
  let initDepositAccountIx: TransactionInstruction | undefined;
  let initCollateralAccountIx: TransactionInstruction | undefined;

  // When handling SOL, ignore existing wsol accounts and initialize a new wrapped sol account
  if (asset.tokenMintPubkey.equals(NATIVE_MINT)) {
    // Overwrite the deposit source
    // The app will always wrap native sol, ignoring any existing wsol
    depositSourceKeypair = Keypair.generate();
    depositSourcePubkey = depositSourceKeypair.publicKey;

    const rent = await connection.getMinimumBalanceForRentExemption(TokenAccountLayout.span);
    createTokenAccountIx = SystemProgram.createAccount({
      fromPubkey: wallet.publicKey,
      newAccountPubkey: depositSourcePubkey,
      programId: TOKEN_PROGRAM_ID,
      space: TokenAccountLayout.span,
      lamports: parseInt(lamports.addn(rent).toString()),
    });

    initTokenAccountIx = Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      NATIVE_MINT,
      depositSourcePubkey,
      wallet.publicKey,
    );

    closeTokenAccountIx = Token.createCloseAccountInstruction(
      TOKEN_PROGRAM_ID,
      depositSourcePubkey,
      wallet.publicKey,
      wallet.publicKey,
      [],
    );
  }

  // Create the deposit note dest account if it doesn't exist
  if (!asset.depositNoteExists) {
    initDepositAccountIx = program.instruction.initDepositAccount(asset.depositNoteBump, {
      accounts: {
        market: CITRUS_MARKET_PUBLICKEY,
        marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

        reserve: reserve.accountPubkey,
        depositNoteMint: reserve.depositNoteMintPubkey,

        depositor: wallet.publicKey,
        depositAccount: asset.depositNotePubkey,

        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      },
    });
  }

  if (!assetStore.obligation) {
    initObligationIx = buildInitObligationIx(program, assetStore, wallet);
  }

  // Obligatory refresh instruction
  const refreshReserveIx = buildRefreshReserveIx(reserve, program);
  const amount = Amount.tokens(lamports);

  const depositIx = program.instruction.deposit(asset.depositNoteBump, amount, {
    accounts: {
      market: CITRUS_MARKET_PUBLICKEY,
      marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

      reserve: reserve.accountPubkey,
      vault: reserve.vaultPubkey,
      depositNoteMint: reserve.depositNoteMintPubkey,

      depositor: wallet.publicKey,
      depositAccount: asset.depositNotePubkey,
      depositSource: depositSourcePubkey,

      tokenProgram: TOKEN_PROGRAM_ID,
    },
  });

  // Initialize the collateral account if it doesn't exist
  if (!asset.collateralNoteExists) {
    initCollateralAccountIx = program.instruction.initCollateralAccount(asset.collateralNoteBump, {
      accounts: {
        market: CITRUS_MARKET_PUBLICKEY,
        marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

        obligation: assetStore.obligationPubkey,
        reserve: reserve.accountPubkey,
        depositNoteMint: reserve.depositNoteMintPubkey,

        owner: wallet.publicKey,
        collateralAccount: asset.collateralNotePubkey,

        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      },
    });
  }
  const depositCollateralBumpSeeds = {
    collateralAccount: asset.collateralNoteBump,
    depositAccount: asset.depositNoteBump,
  };
  let depositCollateralIx = program.instruction.depositCollateral(
    depositCollateralBumpSeeds,
    amount,
    {
      accounts: {
        market: CITRUS_MARKET_PUBLICKEY,
        marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

        reserve: reserve.accountPubkey,

        obligation: assetStore.obligationPubkey,
        owner: wallet.publicKey,
        depositAccount: asset.depositNotePubkey,
        collateralAccount: asset.collateralNotePubkey,

        tokenProgram: TOKEN_PROGRAM_ID,
      },
    },
  );

  const ix = [
    createTokenAccountIx,
    initTokenAccountIx,
    initDepositAccountIx,
    initObligationIx,
    initCollateralAccountIx,
    refreshReserveIx,
    depositIx,
    depositCollateralIx,
    closeTokenAccountIx,
  ].filter(ix => ix) as TransactionInstruction[];
  console.log('ix', ix);
  const signers = [depositSourceKeypair].filter(signer => signer) as Keypair[];

  try {
    const [res, txid] = await sendTransaction(program.provider, ix, signers);
    txid.length !== 0 &&
      notification.success({
        placement: 'bottomLeft',
        message: 'Deposit success',
        description: txid,
      });
    // reloadLending(market, setStore, wallet);
    return [res, txid];
  } catch (err) {
    notification.error({
      placement: 'bottomLeft',
      message: 'Deposit failed',
    });
    // rollbar.error(`Deposit error: ${transactionErrorToString(err)}`);
    return [TxnResponse.Failed, []];
  }
};

export const borrow = async (
  uiValue: string,
  reserve: Reserve,
  reserves: Reserve[],
  wallet: Wallet,
  assetStore: AssetStore | null,
  program: anchor.Program,
  setStore: (fn: (store: Store) => void) => void,
): Promise<[res: TxnResponse, txid: string[]]> => {
  if (!assetStore || !wallet || !program) {
    return [TxnResponse.Failed, []];
  }
  const connection = program.provider.connection;

  // TODO uncomment
  // const [res, txid] = await refreshOldReserves();
  // if (res !== TxnResponse.Success) {
  //   return [res, txid];
  // }

  const asset = assetStore.tokens[reserve.abbrev];

  let receiverAccount = asset.walletTokenPubkey;

  // Create token account ix
  let createTokenAccountIx: TransactionInstruction | undefined;

  // Create loan note token ix
  let initLoanAccountIx: TransactionInstruction | undefined;

  // Wrapped sol ixs
  let wsolKeypair: Keypair | undefined;
  let createWsolTokenAccountIx: TransactionInstruction | undefined;
  let initWsoltokenAccountIx: TransactionInstruction | undefined;
  let closeTokenAccountIx: TransactionInstruction | undefined;

  if (asset.tokenMintPubkey.equals(NATIVE_MINT)) {
    // Create a token account to receive wrapped sol.
    // There isn't an easy way to unwrap sol without
    // closing the account, so we avoid closing the
    // associated token account.
    const rent = await Token.getMinBalanceRentForExemptAccount(connection);

    wsolKeypair = Keypair.generate();
    receiverAccount = wsolKeypair.publicKey;
    createWsolTokenAccountIx = SystemProgram.createAccount({
      fromPubkey: wallet.publicKey,
      newAccountPubkey: wsolKeypair.publicKey,
      programId: TOKEN_PROGRAM_ID,
      space: TokenAccountLayout.span,
      lamports: rent,
    });
    initWsoltokenAccountIx = Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      reserve.tokenMintPubkey,
      wsolKeypair.publicKey,
      wallet.publicKey,
    );
  } else if (!asset.walletTokenExists) {
    // Create the wallet token account if it doesn't exist
    createTokenAccountIx = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      asset.tokenMintPubkey,
      asset.walletTokenPubkey,
      wallet.publicKey,
      wallet.publicKey,
    );
  }

  // Create the loan note account if it doesn't exist
  if (!asset.loanNoteExists) {
    initLoanAccountIx = program.instruction.initLoanAccount(asset.loanNoteBump, {
      accounts: {
        market: CITRUS_MARKET_PUBLICKEY,
        marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

        obligation: assetStore.obligationPubkey,
        reserve: reserve.accountPubkey,
        loanNoteMint: reserve.loanNoteMintPubkey,

        owner: wallet.publicKey,
        loanAccount: asset.loanNotePubkey,

        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
      },
    });
  }

  // Obligatory refresh instruction
  const refreshReserveIxs = buildRefreshReserveIxs(
    assetStore,
    reserves,
    // @ts-ignore
    program,
  );

  // Rounding errors be careful
  const amount = Amount.tokens(new BN(Number.parseFloat(uiValue) * 10 ** reserve.decimals));
  const borrowIx = program.instruction.borrow(asset.loanNoteBump, amount as any, {
    accounts: {
      market: CITRUS_MARKET_PUBLICKEY,
      marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

      obligation: assetStore.obligationPubkey,
      reserve: reserve.accountPubkey,
      vault: reserve.vaultPubkey,
      loanNoteMint: reserve.loanNoteMintPubkey,

      borrower: wallet.publicKey,
      loanAccount: asset.loanNotePubkey,
      receiverAccount,

      tokenProgram: TOKEN_PROGRAM_ID,
    },
  });

  // If withdrawing SOL, unwrap it by closing
  if (asset.tokenMintPubkey.equals(NATIVE_MINT)) {
    closeTokenAccountIx = Token.createCloseAccountInstruction(
      TOKEN_PROGRAM_ID,
      receiverAccount,
      wallet.publicKey,
      wallet.publicKey,
      [],
    );
  }

  const ixs: InstructionAndSigner[] = [
    {
      ix: [
        createTokenAccountIx,
        createWsolTokenAccountIx,
        initWsoltokenAccountIx,
        initLoanAccountIx,
      ].filter(ix => ix) as TransactionInstruction[],
      signers: [wsolKeypair].filter(ix => ix) as Signer[],
    },
    {
      ix: [
        // ...refreshReserveIxs,
        borrowIx,
        closeTokenAccountIx,
      ].filter(ix => ix) as TransactionInstruction[],
    },
  ];

  if (
    [
      createTokenAccountIx,
      createWsolTokenAccountIx,
      initWsoltokenAccountIx,
      initLoanAccountIx,
    ].filter(ix => ix).length === 0
  ) {
    ixs.shift();
  }
  console.log('ixs', ixs);
  try {
    // Make deposit RPC call
    const [res, txids] = await sendAllTransactions(program.provider, ixs);
    console.log('what I am looking for', txids);
    txids.length !== 0 &&
      notification.success({
        placement: 'bottomLeft',
        message: 'Borrow success',
        description: txids[0],
      });

    // reloadLending(market, setStore, wallet);

    return [res, txids];
  } catch (err) {
    console.error(`Borrow error: ${err}`);
    notification.error({
      placement: 'bottomLeft',
      message: 'Borrow Failed',
    });
    return [TxnResponse.Failed, []];
  }
};

export const withdraw = async (
  uiValue: string,
  reserve: Reserve,
  reserves: Reserve[],
  wallet: Wallet,
  assetStore: AssetStore | null,
  program: anchor.Program,
): Promise<[res: TxnResponse, txid: string[]]> => {
  if (!assetStore || !wallet) {
    return [TxnResponse.Failed, []];
  }

  const connection = program.provider.connection;
  // const [res, txid] = await refreshOldReserves();
  // if (res !== TxnResponse.Success) {
  //   return [res, txid];
  // }

  const asset = assetStore.tokens[reserve.abbrev];

  let withdrawAccount = asset.walletTokenPubkey;

  // Create token account ix
  let createAssociatedTokenAccountIx: TransactionInstruction | undefined;

  // Wrapped sol ixs
  let wsolKeypair: Keypair | undefined;
  let createWsolIx: TransactionInstruction | undefined;
  let initWsolIx: TransactionInstruction | undefined;
  let closeWsolIx: TransactionInstruction | undefined;

  if (asset.tokenMintPubkey.equals(NATIVE_MINT)) {
    // Create a token account to receive wrapped sol.
    // There isn't an easy way to unwrap sol without
    // closing the account, so we avoid closing the
    // associated token account.
    const rent = await Token.getMinBalanceRentForExemptAccount(connection);

    wsolKeypair = Keypair.generate();
    withdrawAccount = wsolKeypair.publicKey;
    createWsolIx = SystemProgram.createAccount({
      fromPubkey: wallet.publicKey,
      newAccountPubkey: withdrawAccount,
      programId: TOKEN_PROGRAM_ID,
      space: TokenAccountLayout.span,
      lamports: rent,
    });
    initWsolIx = Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      reserve.tokenMintPubkey,
      withdrawAccount,
      wallet.publicKey,
    );
  } else if (!asset.walletTokenExists) {
    // Create the wallet token account if it doesn't exist
    createAssociatedTokenAccountIx = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      asset.tokenMintPubkey,
      withdrawAccount,
      wallet.publicKey,
      wallet.publicKey,
    );
  }

  // Obligatory refresh instruction
  // const refreshReserveIxs = buildRefreshReserveTxs(reserves, program as any);
  const refreshReserveIx = buildRefreshReserveIx(reserve, program);

  const withdrawCollateralBumps = {
    collateralAccount: asset.collateralNoteBump,
    depositAccount: asset.depositNoteBump,
  };

  const lamports = new BN(Number.parseFloat(uiValue) * 10 ** reserve.decimals);
  const amount = Amount.tokens(lamports);
  const withdrawCollateralIx = program.instruction.withdrawCollateral(
    withdrawCollateralBumps,
    amount as any,
    {
      accounts: {
        market: CITRUS_MARKET_PUBLICKEY,
        marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

        reserve: reserve.accountPubkey,

        obligation: assetStore.obligationPubkey,
        owner: wallet.publicKey,
        depositAccount: asset.depositNotePubkey,
        collateralAccount: asset.collateralNotePubkey,

        tokenProgram: TOKEN_PROGRAM_ID,
      },
    },
  );

  const withdrawIx = program.instruction.withdraw(asset.depositNoteBump, amount as any, {
    accounts: {
      market: CITRUS_MARKET_PUBLICKEY,
      marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

      reserve: reserve.accountPubkey,
      vault: reserve.vaultPubkey,
      depositNoteMint: reserve.depositNoteMintPubkey,

      depositor: wallet.publicKey,
      depositAccount: asset.depositNotePubkey,
      withdrawAccount,

      tokenProgram: TOKEN_PROGRAM_ID,
    },
  });

  // Unwrap sol
  if (asset.tokenMintPubkey.equals(NATIVE_MINT) && wsolKeypair) {
    console.log('should be here lol');
    closeWsolIx = Token.createCloseAccountInstruction(
      TOKEN_PROGRAM_ID,
      withdrawAccount,
      wallet.publicKey,
      wallet.publicKey,
      [],
    );
  }

  // console.log(refreshReserveIxs);5MHdKo5Tn4bonbfcM4QSmCBsYHCSZAsV8JJtm41P8hNEaXy3oNgzPoqyc9GU9hft5MjE8RrMA1pZjz7xd5fPNXLa
  const ixs: InstructionAndSigner[] = [
    // ...refreshReserveIxs,
    {
      ix: [createAssociatedTokenAccountIx, createWsolIx, initWsolIx].filter(
        ix => ix,
      ) as TransactionInstruction[],
      signers: [wsolKeypair].filter(signer => signer) as Signer[],
    },
    {
      ix: [
        // ...refreshReserveIxs,
        refreshReserveIx,
        withdrawCollateralIx,
        withdrawIx,
        closeWsolIx,
      ].filter(ix => ix) as TransactionInstruction[],
    },
  ];

  console.log(ixs);

  try {
    const [res, txids] = await sendAllTransactions(program.provider, ixs);
    txids.length !== 0 &&
      notification.success({
        placement: 'bottomLeft',
        message: 'Withdraw success',
        description: txids[txids.length - 1],
      });

    // reloadLending(market, setStore, wallet);
    return [res, txids];
  } catch (err) {
    console.error(`Withdraw error: ${err}`);
    notification.error({
      placement: 'bottomLeft',
      message: 'Withdraw Failed',
    });
    return [TxnResponse.Failed, []];
  }
};

export const repay = async (
  uiValue: string,
  reserve: Reserve,
  wallet: Wallet,
  program: anchor.Program,
  assetStore: AssetStore | null,
): Promise<[res: TxnResponse, txid: string[]]> => {
  if (!assetStore || !wallet) {
    return [TxnResponse.Failed, []];
  }
  console.log('repay account', program.provider.wallet.publicKey.toString());

  // TODO: uncomment
  // const [res, txid] = await refreshOldReserves([reserve], program);
  // if (res !== TxnResponse.Success) {
  //   return [res, txid];
  // }

  const connection = program.provider.connection;

  const asset = assetStore.tokens[reserve.abbrev];
  let depositSourcePubkey = asset.walletTokenPubkey;

  // Optional signers
  let depositSourceKeypair: Keypair | undefined;

  // Optional instructions
  // Create wrapped sol ixs
  let createTokenAccountIx: TransactionInstruction | undefined;
  let initTokenAccountIx: TransactionInstruction | undefined;
  let closeTokenAccountIx: TransactionInstruction | undefined;

  const amount = Amount.tokens(new BN(Number.parseFloat(uiValue) * 10 ** reserve.decimals));
  // When handling SOL, ignore existing wsol accounts and initialize a new wrapped sol account
  if (asset.tokenMintPubkey.equals(NATIVE_MINT)) {
    // Overwrite the deposit source
    // The app will always wrap native sol, ignoring any existing wsol
    depositSourceKeypair = Keypair.generate();
    depositSourcePubkey = depositSourceKeypair.publicKey;

    // Do our best to estimate the lamports we need
    // 1.002 is a bit of room for interest

    const lamports = amount.units.loanNotes
      ? reserve.loanNoteExchangeRate
          .mul(amount.value)
          .div(new BN(Math.pow(10, 15)))
          .muln(1.002)
      : amount.value;

    const rent = await connection.getMinimumBalanceForRentExemption(TokenAccountLayout.span);
    createTokenAccountIx = SystemProgram.createAccount({
      fromPubkey: wallet.publicKey,
      newAccountPubkey: depositSourcePubkey,
      programId: TOKEN_PROGRAM_ID,
      space: TokenAccountLayout.span,
      lamports: parseInt(lamports.addn(rent).toString()),
    });

    initTokenAccountIx = Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      NATIVE_MINT,
      depositSourcePubkey,
      wallet.publicKey,
    );

    closeTokenAccountIx = Token.createCloseAccountInstruction(
      TOKEN_PROGRAM_ID,
      depositSourcePubkey,
      wallet.publicKey,
      wallet.publicKey,
      [],
    );
  } else if (!asset.walletTokenExists) {
    return [TxnResponse.Failed, []];
  }

  // Obligatory refresh instruction
  const refreshReserveIx = buildRefreshReserveIx(reserve, program as any);

  const repayIx = program.instruction.repay(amount as any, {
    accounts: {
      market: CITRUS_MARKET_PUBLICKEY,
      marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

      obligation: assetStore.obligationPubkey,
      reserve: reserve.accountPubkey,
      vault: reserve.vaultPubkey,
      loanNoteMint: reserve.loanNoteMintPubkey,

      payer: wallet.publicKey,
      loanAccount: asset.loanNotePubkey,
      payerAccount: depositSourcePubkey,

      tokenProgram: TOKEN_PROGRAM_ID,
    },
  });

  const ix = [
    createTokenAccountIx,
    initTokenAccountIx,
    refreshReserveIx,
    repayIx,
    closeTokenAccountIx,
  ].filter(ix => ix) as TransactionInstruction[];
  const signers = [depositSourceKeypair].filter(signer => signer) as Signer[];

  try {
    const [res, txid] = await sendTransaction(program.provider, ix, signers);
    txid.length !== 0 &&
      notification.success({
        placement: 'bottomLeft',
        message: 'Repay success',
        description: txid,
      });

    // reloadLending(market, setStore, wallet);
    return [res, txid];
  } catch (err) {
    notification.error({
      placement: 'bottomLeft',
      message: 'Repay Failed',
    });
    console.error(`Repay error: ${err}`);
    return [TxnResponse.Failed, []];
  }
};

const buildInitObligationIx = (
  program: anchor.Program,
  assetStore: AssetStore,
  wallet: anchor.Wallet,
): TransactionInstruction | undefined => {
  if (!program || !assetStore || !wallet) {
    return;
  }

  return program.instruction.initObligation(assetStore.obligationBump, {
    accounts: {
      market: CITRUS_MARKET_PUBLICKEY,
      marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

      borrower: wallet.publicKey,
      obligation: assetStore.obligationPubkey,

      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    },
  });
};

/** Creates ixs to refresh all reserves. */
const buildRefreshReserveIxs = (
  assetStore: AssetStore,
  reserves: Reserve[],
  program: anchor.Program,
) => {
  const ix: TransactionInstruction[] = [];

  if (!assetStore) {
    return ix;
  }

  reserves.forEach(reserve => {
    const tokenStore = assetStore.tokens[reserve.abbrev];
    if (tokenStore.collateralNoteExists || tokenStore.loanNoteExists) {
      const refreshReserveIx = buildRefreshReserveIx(reserve, program);
      if (refreshReserveIx) {
        ix.push(refreshReserveIx);
      }
    }
  });
  return ix;
};

const buildRefreshReserveIx = (reserve: Reserve, program: anchor.Program) => {
  return program.instruction.refreshReserve({
    accounts: {
      market: CITRUS_MARKET_PUBLICKEY,
      marketAuthority: CITRUS_MARKET_AUTHORITY_PUBKEY,

      reserve: reserve.accountPubkey,
      feeNoteVault: reserve.feeNoteVaultPubkey,
      depositNoteMint: reserve.depositNoteMintPubkey,

      pythOraclePrice: reserve.pythPricePubkey,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
  });
};

const buildRefreshReserveTxs = (reserves: Reserve[], program: anchor.Program) => {
  const txArray: InstructionAndSigner[] = [];
  const MAX_RESERVES_PER_TX = 6;
  for (let i = 0; i < reserves.length / MAX_RESERVES_PER_TX; ++i) {
    const ix: TransactionInstruction[] = [];
    for (let j = 0; j < MAX_RESERVES_PER_TX && MAX_RESERVES_PER_TX * i + j < reserves.length; ++j) {
      const reserve = reserves[MAX_RESERVES_PER_TX * i + j];
      ix.push(buildRefreshReserveIx(reserve, program));
    }
    txArray.push({ ix });
  }
  return txArray;
  // due to transaction size can only refresh 6 reserves in one tx
};

const refreshOldReserves = async (
  reserves: Reserve[],
  program: anchor.Program,
): Promise<[res: TxnResponse, txid: string[]]> => {
  if (!program) {
    return [TxnResponse.Failed, []];
  }

  let res: TxnResponse = TxnResponse.Success;
  let txid: string[] = [];

  for (const reserve of reserves) {
    let accruedUntil = reserve.accruedUntil;
    while (accruedUntil.add(MAX_ACCRUAL_SECONDS).lt(new BN(Math.floor(Date.now() / 1000)))) {
      const refreshReserveIx = buildRefreshReserveIx(reserve, program);

      const ix = [refreshReserveIx].filter(ix => ix) as TransactionInstruction[];

      try {
        [res, txid] = await sendTransaction(program.provider, ix);
      } catch (err) {
        console.log(err);
        return [TxnResponse.Failed, []];
      }
      accruedUntil = accruedUntil.add(MAX_ACCRUAL_SECONDS);
    }
  }

  return [res, txid];
};

export default async function fetchCoinStats() {
  const coinGeckoTrendingUrl = `https://api.coingecko.com/api/v3/search/trending`;
  const bitcoinPriceUrl =
    'https://api.coingecko.com/api/v3/coins/bitcoin?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false';
  for (let i = 0; i < 200; ++i) {
    try {
      console.log('fetch');
      const { coins } = await fetch(coinGeckoTrendingUrl).then(res => res.json());
      const bitcoinPrice = await fetch(bitcoinPriceUrl).then(res => res.json());
      return { coins, bitcoinPrice };
    } catch (e) {}
  }
  return { coins: [null, null, null, null, null, null, null], bitcoinPrice: 0 };
}

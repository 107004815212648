import { setSourceChain } from '../../store/transferSlice';
import { ChainInfo, CHAIN_INFO } from '../../utils/consts';
import {
  ChainId,
  CHAIN_ID_AVAX,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
  CHAIN_ID_SOLANA,
  CHAIN_ID_TERRA,
} from '@certusone/wormhole-sdk';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface ChainSelectProps {
  chains: ChainInfo[];
  value: ChainId;
  onChange: any;
}

export function getLinkFromId(id: ChainId) {
  switch (id) {
    case CHAIN_ID_ETH:
      return '/eth';
    case CHAIN_ID_AVAX:
      return '/avax';
    case CHAIN_ID_TERRA:
      return '/luna';
    case CHAIN_ID_POLYGON:
      return '/matic';
    case CHAIN_ID_BSC:
      return '/bnb';
    default:
      return '/';
  }
}

export default function ChainSelect({ chains, value }: ChainSelectProps) {
  const dispatch = useDispatch();

  const handleSourceChange = (id: ChainId) => {
    dispatch(setSourceChain(id));
  };
  const currentChain = value === CHAIN_ID_SOLANA ? undefined : CHAIN_INFO[value];
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={clsx(
            'flex inline-flex justify-center items-center w-full rounded-md border border-slate-300 px-4 py-2 bg-white text-sm font-medium text-slate-700 hover:bg-slate-50',
            'focus:outline-none focus:ring-offset-slate-100 ',
          )}
        >
          {currentChain ? (
            <div className="flex justify-center items-center">
              <svg className="mr-1" width="16" height="16">
                <image xlinkHref={currentChain.logo} width="16" height="16" />
              </svg>
              {currentChain.name}
            </div>
          ) : (
            'Select Chain'
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 focus:outline-none',
          )}
        >
          <div className="py-1">
            {chains.map(({ id, logo, name }) => (
              <Menu.Item key={id}>
                {({ active }) => (
                  <Link to={getLinkFromId(id)}>
                    <div
                      onClick={() => handleSourceChange(id)}
                      className={clsx(
                        active ? 'bg-slate-100 text-slate-900' : 'text-slate-700',
                        'flex justify-left items-center block px-4 py-2 text-sm',
                      )}
                    >
                      <svg className="mr-2" width="24" height="24">
                        <image xlinkHref={logo} width="24" height="24" />
                      </svg>
                      {name}
                    </div>
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

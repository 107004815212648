import useStore, { OwnedAccount } from '../../store/useStore';
import { AssetStore, Reserve } from '../../utils/lending/jet/JetTypes';
import { Disclosure } from '@headlessui/react';
import * as anchor from '@project-serum/anchor';
import { Connection } from '@solana/web3.js';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import LendingTabs from './LendingTabs';
interface AssetListProps {
  wallet: anchor.Wallet | undefined;
  provider: anchor.Provider | null;
  connection: Connection;
  reserves: Reserve[];
  assetStore: AssetStore | null;
  ownedAccounts: OwnedAccount[];
}

// this is all for devnet. Find a way to import them in mainnet. Or maybe can just expand this list forever...?
import { ReactComponent as BitcoinIcon } from '../../icons/bitcoin.svg';
// import { ReactComponent as BNBIcon } from '../../icons/bsc.svg';
// import { ReactComponent as SolanaIcon } from '../../icons/solOutline.svg';
import { ReactComponent as USDCIcon } from '../../icons/usdc.svg';
import { ReactComponent as USDTIcon } from '../../icons/usdt.svg';
// import { ReactComponent as LunaIcon } from '../../icons/luna.svg';

import ethLogo from '../../icons/eth.png';
import avaxLogo from '../../icons/avax.png';
import solLogo from '../../icons/sol.png';
import lunaLogo from '../../icons/luna.png';
import ustLogo from '../../icons/ust.png';
import bnbLogo from '../../icons/bnb.png';
import usdtLogo from '../../icons/usdt.png';
import msolLogo from '../../icons/msol.png';
import stsolLogo from '../../icons/stsol.png';
import { useMediaQuery } from 'react-responsive';

const tokenIcons = {
  BNB: <img src={bnbLogo} />,
  Ether: <img src={ethLogo} />,
  Bitcoin: <BitcoinIcon />,
  USDC: <USDCIcon />,
  Solana: <img src={solLogo} className="rounded-full" />,
  Luna: <img src={lunaLogo} />,
  UST: <img src={ustLogo} />,
  Avalanche: <img src={avaxLogo} />,
  USDT: <USDTIcon className="h-8 w-8" />,
  mSOL: <img src={msolLogo} />,
  stSOL: <img src={stsolLogo} />,
};

let marketSizeDirection = 1;
let borrowDirection = 1;

let depositAPYDirection = 1;
let borrowAPRDirection = 1;
let depositBalanceDirection = 1;
let borrowBalanceDirection = 1;

function AssetList() {
  const [reserves, user, setStore] = useStore(state => [state.reserves, state.user, state.set]);

  const isShort = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const normalize = (num: number) => {
    if (num === 0) return 0;
    if (num < 0.01) {
      return '<0.01';
    }
    return Number(num.toFixed(2).toString());
  };

  const sortMarketSize = useCallback(() => {
    marketSizeDirection *= -1;
    setStore(state => {
      state.reserves = state.reserves.sort(
        (a, b) =>
          marketSizeDirection *
          (a.marketSize.uiAmountFloat * a.price - b.marketSize.uiAmountFloat * b.price),
      );
    });
  }, [setStore]);

  const sortTotalBorrow = useCallback(() => {
    borrowDirection *= -1;
    setStore(state => {
      state.reserves = state.reserves.sort(
        (a, b) =>
          borrowDirection *
          (a.outstandingDebt.uiAmountFloat * a.price - b.outstandingDebt.uiAmountFloat * b.price),
      );
    });
  }, [setStore]);

  const sortDepositAPY = useCallback(() => {
    depositAPYDirection *= -1;
    setStore(state => {
      state.reserves = state.reserves.sort(
        (a, b) => depositAPYDirection * (a.depositRate - b.depositRate),
      );
    });
  }, [setStore]);

  const sortBorrowAPR = useCallback(() => {
    borrowAPRDirection *= -1;
    setStore(state => {
      state.reserves = state.reserves.sort(
        (a, b) => borrowAPRDirection * (a.borrowRate - b.borrowRate),
      );
    });
  }, [setStore]);

  const sortDepositBalance = useCallback(() => {
    depositBalanceDirection *= -1;
    setStore(state => {
      state.reserves = state.reserves.sort(
        (a, b) =>
          depositBalanceDirection *
            (state.user.assets?.tokens[a.abbrev]?.collateralBalance?.uiAmountFloat ?? 0) *
            a.price -
          (state.user.assets?.tokens[b.abbrev]?.collateralBalance?.uiAmountFloat ?? 0) * b.price,
      );
    });
  }, [setStore]);

  const sortBorrowBalance = useCallback(() => {
    borrowBalanceDirection *= -1;
    setStore(state => {
      state.reserves = state.reserves.sort(
        (a, b) =>
          borrowBalanceDirection *
            (state.user.assets?.tokens[a.abbrev]?.loanBalance?.uiAmountFloat ?? 0) *
            a.price -
          (state.user.assets?.tokens[b.abbrev]?.loanBalance?.uiAmountFloat ?? 0) * b.price,
      );
    });
  }, [setStore]);

  return (
    <>
      <div className="mb-4 w-full border-slate-100">
        <div className="flex justify-center bg-slate-900">
          <div className="w-11/12 rounded-t-md border border-t-0  bg-white pt-2 md:w-1000">
            <div
              className={clsx(
                'px-4 pt-2 pb-4 font-bold',
                'flex flex-row items-center',
                'text-base md:text-lg',
              )}
            >
              <h1>Lending Markets</h1>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="w-11/12 rounded-b-md bg-white md:w-1000">
            <div className="flex flex-row items-center border border-t-0 border-b-0 py-2.5 px-4 text-xs font-bold text-slate-400 md:py-4">
              {isShort ? (
                <>
                  <div className="w-1/5">
                    <div className="">Asset</div>
                  </div>
                  <div className="flex w-full flex-row text-right">
                    <button
                      className="w-1/6 text-right font-bold hover:text-slate-900"
                      onClick={() => sortMarketSize()}
                    >
                      Market Size
                    </button>
                    <button
                      className="w-1/6 text-right font-bold hover:text-slate-900"
                      onClick={() => sortTotalBorrow()}
                    >
                      Total Borrow
                    </button>{' '}
                    <button
                      className="w-1/6 text-right font-bold hover:text-slate-900"
                      onClick={() => sortDepositAPY()}
                    >
                      Deposit APY
                    </button>{' '}
                    <button
                      className="w-1/6 text-right font-bold hover:text-slate-900"
                      onClick={() => sortBorrowAPR()}
                    >
                      Borrow APR
                    </button>{' '}
                    <button
                      className="w-1/6 text-right font-bold hover:text-slate-900"
                      onClick={() => sortDepositBalance()}
                    >
                      Deposit Balance
                    </button>{' '}
                    <button
                      className="w-1/6 text-right font-bold hover:text-slate-900"
                      onClick={() => sortBorrowBalance()}
                    >
                      Borrow Balance
                    </button>{' '}
                  </div>
                </>
              ) : (
                <div className="flex w-full flex-row">
                  <div className="w-3/5">Asset</div>
                  <div className="w-full text-right">Wallet</div>
                </div>
              )}
            </div>
            {reserves.length === 0 &&
              isShort &&
              [1, 2, 3, 4].map(num => (
                <div
                  key={num}
                  className={clsx(
                    'flex w-full justify-between border',
                    'text-left text-sm font-medium text-slate-900 hover:bg-slate-50',
                    'py-6 px-4',
                    // i === 0 ? 'border-t-1',
                    '-mt-px',
                  )}
                >
                  <div className="flex w-1/5 flex-row items-center text-base">
                    <div className="mr-4 h-8 w-8 animate-pulse items-center justify-center rounded-full bg-slate-200"></div>
                    <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                  </div>
                  <div className="flex w-full flex-row items-center font-medium">
                    <div className="w-1/7 flex justify-end">
                      <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                    </div>
                    <div className="w-1/7 flex justify-end">
                      <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                    </div>
                    <div className="w-1/7 flex justify-end">
                      <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                    </div>
                    <div className="w-1/7 flex justify-end">
                      <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                    </div>
                    <div className="w-1/7 flex justify-end">
                      <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                    </div>
                    <div className="w-1/7 flex justify-end">
                      <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                    </div>
                  </div>
                </div>
              ))}

            {reserves.length === 0 &&
              !isShort &&
              [1, 2, 3, 4].map(num => (
                <div
                  key={num}
                  className={clsx(
                    'flex w-full justify-between border',
                    'text-left text-sm font-medium text-slate-900 hover:bg-slate-50',
                    'py-2 px-4',
                    'rounded-b-md',
                    // open ? 'border-b-0' : ' bg-white',
                    // i === 0 ? 'border-t-1',
                    '-mt-px',
                  )}
                >
                  <div className="flex w-1/5 flex-row items-center text-base">
                    <div className="mr-4 h-8 w-8 animate-pulse items-center justify-center rounded-full bg-slate-200"></div>
                  </div>
                  <div className="flex w-full flex-row items-center justify-end font-medium">
                    <div className="flex w-1/4 justify-end">
                      <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                    </div>
                  </div>
                </div>
              ))}
            {reserves.map((reserve, i) => (
              <Disclosure key={reserve.tokenMintPubkey.toString()}>
                {({ open }) => {
                  return (
                    <div className="">
                      <Disclosure.Button
                        className={clsx(
                          'flex w-full justify-between border',
                          'text-left text-sm font-medium text-slate-900 hover:bg-slate-50',
                          '-mt-px',
                          i === reserves.length - 1 && 'rounded-b-lg',
                        )}
                      >
                        {isShort ? (
                          <span
                            className={clsx(
                              'flex w-full flex-row border-l-4 px-4 py-6',
                              open
                                ? 'border-purple-400'
                                : 'border-transparent hover:border-purple-400',
                            )}
                          >
                            <div className="flex w-1/5 flex-row items-center text-base">
                              <div className="mr-4 h-8 w-8 items-center justify-center rounded-full bg-slate-100 ">
                                {tokenIcons[reserve.name]}
                              </div>
                              <div>
                                <div className="font-semibold text-slate-900">{reserve.name}</div>
                                <div className="text-sm text-slate-400">
                                  ${(reserve.price ?? 0).toFixed(2)}
                                </div>
                              </div>
                            </div>
                            <div className="flex w-full flex-row items-center text-right text-base font-semibold">
                              <div className="w-1/6">
                                <div>{normalize(reserve.marketSize.uiAmountFloat)}</div>
                                <div className="text-sm text-slate-400">
                                  $
                                  {(
                                    reserve.marketSize.uiAmountFloat * (reserve.price ?? 1)
                                  ).toFixed(2)}
                                </div>
                              </div>
                              <div className="w-1/6">
                                <div>{normalize(reserve.outstandingDebt.uiAmountFloat)}</div>
                                <div className="text-sm text-slate-400">
                                  $
                                  {normalize(
                                    (reserve.marketSize.uiAmountFloat -
                                      reserve.availableLiquidity.uiAmountFloat) *
                                      (reserve.price ?? 1),
                                  )}
                                </div>
                              </div>
                              <div className="w-1/6">
                                <div>
                                  {(
                                    100 * (isNaN(reserve.depositRate) ? 0 : reserve.depositRate)
                                  ).toFixed(2)}
                                  %
                                </div>
                                <div className="py-2.5" />
                              </div>
                              <div className="w-1/6">
                                {(
                                  100 * (isNaN(reserve.borrowRate) ? 0 : reserve.borrowRate)
                                ).toFixed(2)}
                                %
                                <div className="py-2.5" />
                              </div>
                              <div className="w-1/6">
                                {user.assets?.tokens[reserve.abbrev] == null ? (
                                  <div className="flex justify-end">
                                    <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                                  </div>
                                ) : (
                                  <div>
                                    <div>
                                      {normalize(
                                        user.assets?.tokens[reserve.abbrev]?.collateralBalance
                                          .uiAmountFloat ?? 0,
                                      )}
                                    </div>
                                    <div className="text-sm text-slate-400">
                                      $
                                      {(
                                        (user.assets?.tokens[reserve.abbrev]?.collateralBalance
                                          .uiAmountFloat ?? 0) * (reserve.price ?? 1)
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="w-1/6">
                                {user.assets?.tokens[reserve.abbrev] == null ? (
                                  <div className="flex justify-end">
                                    <div className="w-1/2 animate-pulse rounded-full bg-slate-200 py-1" />
                                  </div>
                                ) : (
                                  <div>
                                    <div>
                                      {normalize(
                                        user.assets?.tokens[reserve.abbrev]?.loanBalance
                                          .uiAmountFloat ?? 0,
                                      )}
                                    </div>
                                    <div className="text-sm text-slate-400">
                                      $
                                      {(
                                        (user.assets?.tokens[reserve.abbrev]?.loanBalance
                                          .uiAmountFloat ?? 0) * (reserve.price ?? 1)
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </span>
                        ) : (
                          <span
                            className={clsx(
                              'flex w-full flex-row border-l-4 px-4 py-2',
                              open
                                ? 'border-purple-400'
                                : 'border-transparent hover:border-purple-400',
                            )}
                          >
                            <div className="flex w-full flex-row items-center ">
                              <div className="mr-4 h-6 w-6 items-center justify-center rounded-full bg-slate-100 font-normal ">
                                {tokenIcons[reserve.name]}
                              </div>
                              <div>
                                <div className="font-normal">{reserve.name}</div>
                                <div className="text-xs text-slate-400">
                                  {(100 * reserve.depositRate).toFixed(2)}%
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-end">
                              {user.walletBalances[reserve.abbrev]?.toFixed(2)}
                            </div>{' '}
                            <div className="flex items-center"> &nbsp;{reserve.abbrev}</div>
                          </span>
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel
                        className={clsx(
                          ' text-sm text-slate-500',
                          open && i !== reserves.length - 1 && 'border-b-1',
                        )}
                      >
                        <LendingTabs icon={tokenIcons[reserve.name]} reserve={reserve} />
                      </Disclosure.Panel>
                    </div>
                  );
                }}
              </Disclosure>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetList;

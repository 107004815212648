import useLoadJet from '../../hooks/useLoadLending';
import AssetList from './AssetList';
import React from 'react';
import LendingHeader from './LendingHeader';

import useStore from '../../store/useStore';
function Lending() {
  // const assetStore = useStore(store => store.assetStore);
  useLoadJet();

  return (
    <div className="">
      <LendingHeader />
      <AssetList />
      <div className="p-6" />
    </div>
  );
}

export default Lending;

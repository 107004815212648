import { useEthereumProvider } from '../../contexts/EthereumProviderContext';
import useIsWalletReady from '../../hooks/useIsWalletReady';
import { selectSourceAmount } from '../../store/selectors';
import { setSourceAmount } from '../../store/transferSlice';
import { getTerraBalance } from '../../utils/actions';
import { ChainInfo, CHAINS } from '../../utils/consts';
import NumericalInput from '../NumericalInput';
import { ChainId, CHAIN_ID_TERRA, isEVMChain } from '@certusone/wormhole-sdk';
import { useConnectedWallet } from '@terra-money/wallet-provider';
import clsx from 'clsx';
import { formatUnits } from 'ethers/lib/utils';
import React, { useEffect, useState } from 'react';
import { ArrowDown } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

type AmountInputPanelProps = {
  sourceChain: ChainId;
};

const createCurrencyIcon = ({ shorthand, coinIcon }: ChainInfo) => (
  <div className="flex h-full items-center">
    <svg className="block" width="24" height="24">
      <image xlinkHref={coinIcon} width="24" height="24" />
    </svg>
    <div className="ml-2 block">{shorthand}</div>
  </div>
);
function AmountInputPanel({ sourceChain }: AmountInputPanelProps) {
  const dispatch = useDispatch();
  const value = useSelector(selectSourceAmount);
  const [balance, setBalance] = useState<string>('0.0');
  const { provider, signerAddress } = useEthereumProvider();
  const { isReady, statusMessage } = useIsWalletReady(sourceChain);
  const terraWallet = useConnectedWallet();
  const onUserInput = (value: string) => {
    dispatch(setSourceAmount(value));
  };

  const chainInfo = sourceChain >= 2 ? CHAINS[sourceChain - 2] : null;

  useEffect(() => {
    if (isEVMChain(sourceChain)) {
      const fetchEthBalance = async () => {
        if (!isReady) {
          setBalance('...');
          return;
        }
        if (provider == null) {
          setBalance('...');
          return;
        }
        if (signerAddress == null) {
          setBalance('...');
          return;
        }

        const ethBalance = await provider.getBalance(signerAddress);
        const formattedBalance = formatUnits(ethBalance);
        const toFixed = Number.parseFloat(formattedBalance).toPrecision(4);

        setBalance(toFixed ?? '0.0');
      };
      fetchEthBalance();
    } else if (sourceChain === CHAIN_ID_TERRA) {
      const fetchTerraBalance = async () => {
        if (terraWallet != null) {
          const res = await getTerraBalance(terraWallet);
          setBalance(res.toString());
        } else {
          setBalance('...');
        }
      };
      fetchTerraBalance();
    }
  }, [sourceChain, isReady, provider, signerAddress, terraWallet]);

  return (
    <div className={clsx('flex flex-col')}>
      <div
        id="container"
        className={clsx(
          'rounded-lg',
          'w-full',
          'border border-transparent hover:border-slate-200',
          'bg-slate-50 hover:shadow-inner',
          'p-4',
        )}
      >
        <div className={clsx('flex flex-row', 'items-center justify-between')}>
          <NumericalInput className="token-amount-input" value={value} onUserInput={onUserInput} />
          {chainInfo && (
            <>
              <div
                className={clsx(
                  'block rounded-xl border border-slate-200 shadow-inner',
                  'flex flex-row',
                  'px-4 py-2',
                )}
              >
                {createCurrencyIcon(chainInfo)}
              </div>
            </>
          )}
        </div>
        <div className={clsx('text-trunacte tabular-nums text-slate-500')}>Balance: {balance}</div>
      </div>
      <div className={clsx('mx-auto flex flex-row justify-center')}>
        <ArrowDown />
      </div>
    </div>
  );
}

export default AmountInputPanel;

import clsx from 'clsx';
import React from 'react';
import { CitrusReserve, Reserve } from '../../utils/lending/jet/JetTypes';

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group

interface NumericalInputProps {}
export default function InnerInput({
  value,
  onUserInput,
  reserve,
  placeholder,
  prependSymbol,
  disabled,
}: {
  value: string;
  onUserInput: (input: string) => void;
  reserve: Reserve;
  error?: boolean;
  disabled?: boolean;
  fontSize?: string;
  align?: 'right' | 'left';
  prependSymbol?: string | undefined;
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput);
    }
  };

  const usdValue = (reserve.price ?? 0) * Number.parseFloat(value === '' ? '0' : value);

  //         ${usdValue}

  return (
    <div className="flex w-full flex-row items-center">
      <div className="flex h-full items-center rounded-l-md border border-r-0 bg-slate-100 px-1 py-2 text-base">
        Amount
      </div>

      <div className="rounded-r-md border border-slate-200 py-1 pl-2 pr-1  hover:border-purple-400">
        <input
          className={clsx(
            'text-base font-medium text-slate-900',
            'trucate',
            'hover:bg-white',
            'tabular-nums',
            'focus:outline-none',
          )}
          value={prependSymbol && value ? prependSymbol + value : value}
          onChange={event => {
            if (prependSymbol) {
              const value = event.target.value;

              // cut off prepended symbol
              const formattedValue = value.toString().includes(prependSymbol)
                ? value.toString().slice(1, value.toString().length + 1)
                : value;

              // replace commas with periods, because uniswap exclusively uses period as the decimal separator
              enforcer(formattedValue.replace(/,/g, '.'));
            } else {
              enforcer(event.target.value.replace(/,/g, '.'));
            }
          }}
          // universal input options
          inputMode="decimal"
          autoComplete="off"
          autoCorrect="off"
          // text-specific options
          type="text"
          pattern="^[0-9]*[.,]?[0-9]*$"
          placeholder={placeholder || '0.0'}
          minLength={1}
          maxLength={79}
          spellCheck="false"
        />
        <div className="-mt-1 text-xs text-slate-400">${usdValue.toFixed(2)}</div>
      </div>
    </div>
  );
}

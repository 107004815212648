export const VALID_SOL_ADDRESS_STRING = 'Valid SOL address';
export const INVALID_SOL_ADDRESS_STRING = 'Invalid SOL address';
export const SOL_ADDRESS_PLACEHOLDER = 'Enter SOL address';
export const SEND_TRANSACTION_STRING = 'Send transaction';
export const TRANSACTION_TOO_SMALL_STRING = 'Too small: min output 0.11 SOL';
export const SOURCE_TOO_SMALL_STRING = 'Too small: min input 0.01 ETH';
export const WALLET_NOT_CONNECTED_STRING = 'Wallet is not connected';
export const INVALID_SOLANA_ADDRESS_STRING = 'Invalid Solana Address';

export const DISCLOSURE_HOW_IT_WORKS = 'How does Citrus work?';
export const DISCLOSURE_HOW_IT_WORKS_BODY = (chainCurrencyName: string) =>
  `Citrus wraps your native ${chainCurrencyName} into a wrapped version using Wormhole Token Bridge. After redeeming the wrapped token on Solana, 
  Citrus uses Serum markets to convert it 
  to Native SOL with high liquidity and competitive rates.`;

export const WHAT_DOES_STAR_MEAN = 'What does a * mean on the Ouput order size?';
export const WHAT_DOES_STAR_MEAN_BODY = `Serum Orderbooks support a high volume of liquidity on wrapped tokens, 
  but not infinite. If attempting to make an extremely large order 
  its reccomended that you split the order into multiple smaller parts.`;
export const ATRIX_PLUG = 'Where can I trade SOL?';
export const ATRIX_PLUG_BODY = 'You can trade SOL ';

export const WHY_SOL_HEADER = 'Why did I receive some USDC?';
export const WHY_SOL_BODY = `There is a minimum order size of 0.1 SOL in the USDC/SOL Serum market, which is used during swaps from AVAX, MATIC, BNB, and LUNA.
This is the reason the SOL you receive is usually in increments of 0.1 SOL, and Citrus sends any spillage of USDC used in the transaction. You deserve it, after all. This is also the reason for the minimuim transaction size. `;

export const FEE_HEADER = 'Does Citrus take any fees?';
export const FEE_BODY = 'No: Citrus does not take any fees for transactions';

export const WHAT_IS_CITRUS_HEADER = 'What is Citrus?';
export const WHAT_IS_CITRUS_BODY = `Citrus is a way for you to turn your Ethereum, Avax, Matic, Luna, and Bnb into native Solana without using
any fees like in a centralized exchange or requiring KYC`;

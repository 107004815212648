// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
// import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
// import {
//   LedgerWalletAdapter,
//   PhantomWalletAdapter,
//   SlopeWalletAdapter,
//   SolflareWalletAdapter,
//   SolletExtensionWalletAdapter,
//   SolletWalletAdapter,
//   TorusWalletAdapter,
// } from '@solana/wallet-adapter-wallets';
import { WalletKitProvider } from '@gokiprotocol/walletkit';
import { clusterApiUrl } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo } from 'react';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

const Context: FC<{ children: ReactNode }> = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  // const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  // const wallets = useMemo(
  //   () => [
  //     new PhantomWalletAdapter(),
  //     new SlopeWalletAdapter(),
  //     new SolflareWalletAdapter({ network }),
  //     new TorusWalletAdapter(),
  //     new LedgerWalletAdapter(),
  //     new SolletWalletAdapter({ network }),
  //     new SolletExtensionWalletAdapter({ network }),
  //   ],
  //   [network],
  // );

  return (
    <WalletKitProvider
      // defaultNetwork="devnet"
      defaultNetwork="devnet"
      app={{
        name: 'Citrus',
        icon: <img src="/comet-black-circle.svg" style={{ borderRadius: 100 }} />,
      }}
    >
      {children}
    </WalletKitProvider>
  );
};

export default Context;

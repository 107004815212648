import SolanaWalletButton from './SolanaWalletButton';
import citrusLogoMediumWhite from '../icons/citrusLogoMediumAllWhite3.svg';
import citrusLogoMedium from '../icons/citrusLogoMedium.svg';
import { ChainId, CHAIN_ID_AVAX } from '@certusone/wormhole-sdk';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import IconRoutes from './IconRoutes';
import clsx from 'clsx';

function CitrusHeader({ sourceChain, location }: { sourceChain: ChainId; location?: string }) {
  const logoLink = citrusLogoMediumWhite;
  const isTooBig = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className="flex justify-center">
      <div className={clsx('w-1400')}>
        <div className={clsx('flex flex-col items-center justify-between md:flex-row', 'w-full')}>
          <div className={clsx('flex flex-row justify-between ', isTooBig && 'w-11/12')}>
            <svg className="isolate md:mr-20" width="100" height="40">
              <image xlinkHref={logoLink} width="100" height="40" />
            </svg>
            <div className="inline md:hidden">
              <SolanaWalletButton />
            </div>
          </div>

          <IconRoutes />

          <div className="hidden md:inline">
            <SolanaWalletButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CitrusHeader;

import React, { useMemo } from 'react';

import { useState } from 'react';
import useStore from '../../store/useStore';
import { Reserve } from '../../utils/lending/jet/JetTypes';
import { deposit } from '../../utils/lending/jet/jet';
import NumericalInput from './NumericalInput';
import { useConnectedWallet, useWalletKit } from '@gokiprotocol/walletkit';
import clsx from 'clsx';
interface DepositTabProps {
  reserve: Reserve;
  icon: React.FC;
}

export default function DepositTab({ reserve, icon }: DepositTabProps) {
  const [value, setValue] = useState<string>('');
  const [user, program] = useStore(state => [state.user, state.program, state.set]);
  const wallet = useConnectedWallet();
  const { connect } = useWalletKit();

  const depositFunds = async () => {
    if (wallet == null) return;
    if (user == null) return;

    const res = await deposit(value, reserve, program, wallet as any, user.assets);
  };

  return (
    <div className="w-full">
      <div className="m-4">
        <div className="flex flex-row items-center justify-between py-4">
          <div className="text-base font-semibold text-slate-900">Deposit {reserve.abbrev}</div>
          <div className="h-8 w-8 rounded-full bg-slate-200">{icon}</div>
        </div>
        <div className="my-2 flex flex-row">
          <button
            className={clsx(
              'rounded-md border p-2 text-slate-900',
              'transition duration-100 ease-in-out hover:border-slate-900',
              'mr-2',
            )}
            onClick={() => setValue(user.walletBalances[reserve.abbrev]?.toString() ?? '0')}
          >
            MAX
          </button>
          <NumericalInput
            value={value}
            reserve={reserve}
            placeholder="Enter an amount"
            onUserInput={evt => setValue(evt)}
          />
        </div>
        {wallet == null && (
          <button
            onClick={() => connect()}
            className={clsx(
              'my-2 w-full rounded-lg bg-slate-900 p-2 font-semibold text-white hover:bg-purple-400',
              'transition duration-300 ease-in-out',
            )}
          >
            Connect Wallet
          </button>
        )}
        {wallet != null && (
          <button
            onClick={() => value !== '' && depositFunds()}
            className={clsx(
              'my-2 w-full rounded-lg p-2 font-bold text-white',
              value === '' ? 'cursor-not-allowed bg-slate-900' : 'bg-purple-400 ',
            )}
          >
            {value === '' ? 'Enter amount to Deposit' : `Deposit ${value} ${reserve.abbrev}`}
          </button>
        )}
        <div className="mt-2 rounded-lg bg-slate-900 p-4 text-white">
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">Maximum LTV</div>
            <div className="font-mono text-white">
              {Math.floor(
                1_000_000 /
                  (reserve.config.minCollateralRatio === 0
                    ? Number.POSITIVE_INFINITY
                    : reserve.config.minCollateralRatio),
              )}{' '}
              %
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">Deposit APY</div>
            <div className="font-mono text-white">
              {(100 * (isNaN(reserve.depositRate) ? 0 : reserve.depositRate)).toFixed(2)} %
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">Wallet Balance</div>
            <div className="font-mono text-white">
              {user == null ? <>-</> : user.walletBalances[reserve.abbrev]?.toString() ?? '0'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import avaxIcon from '../icons/avax.svg';
import bscIcon from '../icons/bsc.svg';
import ethIcon from '../icons/eth.svg';
import lunaIcon from '../icons/luna.svg';
import polygonIcon from '../icons/polygon.svg';
import solanaIcon from '../icons/solana.svg';
import terraIcon from '../icons/terra.svg';
import {
  CHAIN_ID_AVAX,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_ETHEREUM_ROPSTEN,
  CHAIN_ID_POLYGON,
  CHAIN_ID_SOLANA,
  CHAIN_ID_TERRA,
} from '@certusone/wormhole-sdk';
import type { ChainId } from '@certusone/wormhole-sdk';
import { PublicKey } from '@solana/web3.js';
import { getAddress } from 'ethers/lib/utils';

import { Coder } from '@project-serum/anchor';

import idl from '../jet.json';

export type Cluster = 'devnet' | 'testnet' | 'mainnet';

export const ADMIN_WBBNB_ACCOUNT_ADDRESS = '8wC7EjtfLdeayk18B2Xh9SioDotn8Gj4vE6sZZ38ZQEk';

export const ADMIN_WBBNB_ACCOUNT_ADDRESS_PK = new PublicKey(ADMIN_WBBNB_ACCOUNT_ADDRESS);

export const ADMIN_WETH_ACCOUNT_ADDRESS = '89mW7aoEv48L5s15NoKJt2GGmVxdQEUaMHNJiAfgiSk9';

export const ADMIN_WETH_ACCOUNT_ADDRESS_PK = new PublicKey(ADMIN_WETH_ACCOUNT_ADDRESS);

export const ADMIN_MATIC_ACCOUNT_ADDRESS = '4NigHYrbKyvaEnhFTqRVyF6yDfYfpMFw1JDsUhonTKpS';

export const ADMIN_MATIC_ACCOUNT_ADDRESS_PK = new PublicKey(ADMIN_MATIC_ACCOUNT_ADDRESS);

export const ADMIN_AVAX_ACCOUNT_ADDRESS = 'E9VDQnpobeA9rVZh2zgeMQS1fSkD18zztXX4gazBTKv';

export const ADMIN_AVAX_ACCOUNT_ADDRESS_PK = new PublicKey(ADMIN_AVAX_ACCOUNT_ADDRESS);

export const ADMIN_LUNA_ACCOUNT_ADDRESS = 'FZxtSdQSKXjxuiUKBZbB6jCZB5bwem1t5XYm8WsMWvg';

export const ADMIN_LUNA_ACCOUNT_ADDRESS_PK = new PublicKey(ADMIN_LUNA_ACCOUNT_ADDRESS);

export const SOLANA_TOKEN_BRIDGE_ADDRESS = 'B6RHG3mfcckmrYN1UhmJzyS1XX3fZKbkeUcpJe9Sy3FE';

export const SOLANA_CORE_BRIDGE_ADDRESS = 'Bridge1p5gheXUvJ6jGWGeCsgPKgnE3YgdGKRVCMY9o';

export const SOLANA_HOST = 'https://solana-api.projectserum.com/';
export const CLUSTER: Cluster = 'mainnet';

export const BSC_TOKEN_BRIDGE_ADDRESS = getAddress(
  CLUSTER === 'mainnet'
    ? '0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7'
    : CLUSTER === 'testnet'
    ? '0x9dcF9D205C9De35334D646BeE44b2D2859712A09'
    : '0x0290FB167208Af455bB137780163b7B7a9a10C16',
);
export const BSC_AND_ETH_CORE_BRIDGE_ADDRESS = getAddress(
  '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B',
);

export const TERRA_BRIDGE_ADDRESS =
  CLUSTER === 'mainnet'
    ? 'terra1dq03ugtd40zu9hcgdzrsq6z2z4hwhc9tqk2uy5'
    : CLUSTER === 'testnet'
    ? 'terra1pd65m0q9tl3v8znnz5f5ltsfegyzah7g42cx5v'
    : 'terra18vd8fpwxzck93qlwghaj6arh4p7c5n896xzem5';

export const TERRA_TOKEN_BRIDGE_ADDRESS =
  CLUSTER === 'mainnet'
    ? 'terra10nmmwe8r3g99a9newtqa7a75xfgs2e8z87r2sf'
    : CLUSTER === 'testnet'
    ? 'terra1pseddrv0yfsn76u4zxrjmtf45kdlmalswdv39a'
    : 'terra10pyejy66429refv3g35g2t7am0was7ya7kz2a4';

export const BSC_BRIDGE_ADDRESS = getAddress(
  CLUSTER === 'mainnet'
    ? '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B'
    : CLUSTER === 'testnet'
    ? '0x68605AD7b15c732a30b1BbC62BE8F2A509D74b4D'
    : '0xC89Ce4735882C9F0f0FE26686c53074E09B0D550',
);

export const ETH_TOKEN_BRIDGE_ADDRESS = getAddress(
  CLUSTER === 'mainnet'
    ? '0x3ee18B2214AFF97000D974cf647E7C347E8fa585'
    : CLUSTER === 'testnet'
    ? '0xF890982f9310df57d00f659cf4fd87e65adEd8d7'
    : '0x0290FB167208Af455bB137780163b7B7a9a10C16',
);

export const ETH_BRIDGE_ADDRESS = getAddress(
  CLUSTER === 'mainnet'
    ? '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B'
    : CLUSTER === 'testnet'
    ? '0x44F3e7c20850B3B5f3031114726A9240911D912a'
    : '0xC89Ce4735882C9F0f0FE26686c53074E09B0D550',
);

export const POLYGON_BRIDGE_ADDRESS = getAddress(
  CLUSTER === 'mainnet'
    ? '0x7A4B5a56256163F07b2C80A7cA55aBE66c4ec4d7'
    : CLUSTER === 'testnet'
    ? '0x0CBE91CF822c73C2315FB05100C2F714765d5c20'
    : '0xC89Ce4735882C9F0f0FE26686c53074E09B0D550',
);

export const POLYGON_TOKEN_BRIDGE_ADDRESS = getAddress(
  CLUSTER === 'mainnet'
    ? '0x5a58505a96D1dbf8dF91cB21B54419FC36e93fdE'
    : CLUSTER === 'testnet'
    ? '0x377D55a7928c046E18eEbb61977e714d2a76472a'
    : '0x0290FB167208Af455bB137780163b7B7a9a10C16',
);

export const WORMHOLE_RPC_HOSTS = [
  'https://wormhole-v2-mainnet-api.certus.one',
  'https://wormhole.inotel.ro',
  'https://wormhole-v2-mainnet-api.mcf.rocks',
  'https://wormhole-v2-mainnet-api.chainlayer.network',
  'https://wormhole-v2-mainnet-api.staking.fund',
  'https://wormhole-v2-mainnet.01node.com',
];

export const WORMHOLE_RPC_HOST = 'https://wormhole-v2-mainnet-api.certus.one';
export const SOL_BRIDGE_ADDRESS =
  CLUSTER === 'mainnet'
    ? 'worm2ZoG2kUd4vFXhvjh93UUH596ayRfgQ2MgjNMTth'
    : CLUSTER === 'testnet'
    ? '3u8hJUVTA4jH1wYAyUur7FFZVQ8H635K3tSHHF4ssjQ5'
    : 'Bridge1p5gheXUvJ6jGWGeCsgPKgnE3YgdGKRVCMY9o';

export const SOL_TOKEN_BRIDGE_ADDRESS =
  CLUSTER === 'mainnet'
    ? 'wormDTUJ6AWPNvk59vGQbDvGJmqbDTdgWgAqcLBCgUb'
    : CLUSTER === 'testnet'
    ? 'DZnkkTmCiFWfYTfT41X3Rd1kDgozqzxWaHqsw6W4x2oe'
    : 'B6RHG3mfcckmrYN1UhmJzyS1XX3fZKbkeUcpJe9Sy3FE';

export const AVAX_TOKEN_BRIDGE_ADDRESS = getAddress(
  CLUSTER === 'mainnet'
    ? '0x0e082F06FF657D94310cB8cE8B0D9a04541d8052'
    : CLUSTER === 'testnet'
    ? '0x61E44E506Ca5659E6c0bba9b678586fA2d729756'
    : '0x0290FB167208Af455bB137780163b7B7a9a10C16',
);

export const AVAX_BRIDGE_ADDRESS = getAddress(
  CLUSTER === 'mainnet'
    ? '0x54a8e5f9c4CbA08F9943965859F6c34eAF03E26c'
    : CLUSTER === 'testnet'
    ? '0x7bbcE28e64B3F8b84d876Ab298393c38ad7aac4C'
    : '0xC89Ce4735882C9F0f0FE26686c53074E09B0D550',
);

export const TERRA_HOST = {
  URL: 'https://lcd.terra.dev',
  chainID: 'columbus-5',
  name: 'mainnet',
};

export const BACKEND = 'http://localhost:3001';
// export const WEBSOCKET_URL = 'ws://localhost:3001';
export const WEBSOCKET_URL = 'wss://citrus-backend.herokuapp.com/';

export const ADMIN_PK = new PublicKey('FBhgDTFXejsmAejRXBY5RqkZpCtmHx1VAAmRQgCvLiMn');

export interface ChainInfo {
  id: ChainId;
  name: string;
  logo: string;
  coinIcon: string;
  shorthand: string;
}

export const CHAINS: ChainInfo[] = [
  {
    id: CHAIN_ID_ETH,
    name: 'Ethereum',
    logo: ethIcon,
    coinIcon: ethIcon,
    shorthand: 'ETH',
  },
  {
    id: CHAIN_ID_TERRA,
    name: 'Terra',
    logo: terraIcon,
    coinIcon: lunaIcon,
    shorthand: 'LUNA',
  },
  {
    id: CHAIN_ID_BSC,
    name: 'Binance Smart Chain',
    logo: bscIcon,
    coinIcon: bscIcon,
    shorthand: 'BNB',
  },
  {
    id: CHAIN_ID_POLYGON,
    name: 'Polygon',
    logo: polygonIcon,
    coinIcon: polygonIcon,
    shorthand: 'MATIC',
  },
  {
    id: CHAIN_ID_AVAX,
    name: 'Avalanche',
    logo: avaxIcon,
    coinIcon: avaxIcon,
    shorthand: 'AVAX',
  },
];

export const CHAIN_INFO = {
  [CHAIN_ID_ETH]: {
    id: CHAIN_ID_ETH,
    name: 'Ethereum',
    logo: ethIcon,
    coinIcon: ethIcon,
    shorthand: 'ETH',
  },
  [CHAIN_ID_TERRA]: {
    id: CHAIN_ID_TERRA,
    name: 'Terra',
    logo: terraIcon,
    coinIcon: lunaIcon,
    shorthand: 'LUNA',
  },
  [CHAIN_ID_BSC]: {
    id: CHAIN_ID_BSC,
    name: 'Binance Smart Chain',
    logo: bscIcon,
    coinIcon: bscIcon,
    shorthand: 'BNB',
  },
  [CHAIN_ID_POLYGON]: {
    id: CHAIN_ID_POLYGON,
    name: 'Polygon',
    logo: polygonIcon,
    coinIcon: polygonIcon,
    shorthand: 'MATIC',
  },
  [CHAIN_ID_AVAX]: {
    id: CHAIN_ID_AVAX,
    name: 'Avalanche',
    logo: avaxIcon,
    coinIcon: avaxIcon,
    shorthand: 'AVAX',
  },
  [CHAIN_ID_SOLANA]: {
    id: CHAIN_ID_SOLANA,
    name: 'Solana',
    logo: solanaIcon,
    coinIcon: solanaIcon,
    shorthand: 'currency',
  },
};

export const ETH_NETWORK_CHAIN_ID = CLUSTER === 'mainnet' ? 1 : CLUSTER === 'testnet' ? 5 : 1337;
export const ROPSTEN_ETH_NETWORK_CHAIN_ID =
  CLUSTER === 'mainnet' ? 1 : CLUSTER === 'testnet' ? 3 : 1337;
export const BSC_NETWORK_CHAIN_ID = CLUSTER === 'mainnet' ? 56 : CLUSTER === 'testnet' ? 97 : 1397;
export const POLYGON_NETWORK_CHAIN_ID =
  CLUSTER === 'mainnet' ? 137 : CLUSTER === 'testnet' ? 80001 : 1381;
export const AVAX_NETWORK_CHAIN_ID =
  CLUSTER === 'mainnet' ? 43114 : CLUSTER === 'testnet' ? 43113 : 1381;
export const OASIS_NETWORK_CHAIN_ID =
  CLUSTER === 'mainnet' ? 42262 : CLUSTER === 'testnet' ? 42261 : 1381;

export const getEvmChainId = (chainId: ChainId) =>
  chainId === CHAIN_ID_ETH
    ? ETH_NETWORK_CHAIN_ID
    : chainId === CHAIN_ID_ETHEREUM_ROPSTEN
    ? ROPSTEN_ETH_NETWORK_CHAIN_ID
    : chainId === CHAIN_ID_BSC
    ? BSC_NETWORK_CHAIN_ID
    : chainId === CHAIN_ID_POLYGON
    ? POLYGON_NETWORK_CHAIN_ID
    : chainId === CHAIN_ID_AVAX
    ? AVAX_NETWORK_CHAIN_ID
    : undefined;

export const getTokenBridgeAddressForChain = (chainId: ChainId) =>
  chainId === CHAIN_ID_SOLANA
    ? SOL_TOKEN_BRIDGE_ADDRESS
    : chainId === CHAIN_ID_ETH
    ? ETH_TOKEN_BRIDGE_ADDRESS
    : chainId === CHAIN_ID_BSC
    ? BSC_TOKEN_BRIDGE_ADDRESS
    : chainId === CHAIN_ID_TERRA
    ? TERRA_TOKEN_BRIDGE_ADDRESS
    : chainId === CHAIN_ID_POLYGON
    ? POLYGON_TOKEN_BRIDGE_ADDRESS
    : chainId === CHAIN_ID_AVAX
    ? AVAX_TOKEN_BRIDGE_ADDRESS
    : '';

export const TERRA_FCD_BASE =
  CLUSTER === 'mainnet'
    ? 'https://fcd.terra.dev'
    : CLUSTER === 'testnet'
    ? 'https://bombay-fcd.terra.dev'
    : 'http://localhost:3060';
export const TERRA_GAS_PRICES_URL = `${TERRA_FCD_BASE}/v1/txs/gas_prices`;

// markets
export const WBNB_USDC_MARKET = new PublicKey('3zzTxtDCt9PimwzGrgWJEbxZfSLetDMkdYegPanGNpMf');
export const WETH_SOL_MARKET = new PublicKey('7gtMZphDnZre32WfedWnDLhYYWJ2av1CCn1RES5g8QUf');

export const SOL_USDC_MARKET = new PublicKey('9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT');
export const MATIC_USDC_MARKET = new PublicKey('5WRoQxE59966N2XfD2wYy1uhuyKeoVJ9NBMH6r6RNYEF');
export const AVAX_USDC_MARKET = new PublicKey('E8JQstcwjuqN5kdMyUJLNuaectymnhffkvfg1j286UCr');
export const LUNA_USDC_MARKET = new PublicKey('HBTu8hNaoT3VyiSSzJYa8jwt9sDGKtJviSwFa11iXdmE');

export const SOLANA_ICON = solanaIcon;

export const USDC_MINT = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v');
export const AVAX_MINT = new PublicKey('KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE');
export const LUNA_MINT = new PublicKey('F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W');
export const BNB_MINT = new PublicKey('9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa');
export const MATIC_MINT = new PublicKey('Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG');
export const ETH_MINT = new PublicKey('7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs');
export const SOL_MINT = new PublicKey('So11111111111111111111111111111111111111112');

export function getTokenMint(chainId: ChainId) {
  switch (chainId) {
    case CHAIN_ID_AVAX: {
      return AVAX_MINT;
    }
    case CHAIN_ID_BSC: {
      return BNB_MINT;
    }
    case CHAIN_ID_TERRA: {
      return LUNA_MINT;
    }
    case CHAIN_ID_ETH: {
      return ETH_MINT;
    }
    case CHAIN_ID_POLYGON: {
      return MATIC_MINT;
    }
    default: {
      return USDC_MINT;
    }
  }
}

export enum TransactionState {
  NONE = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  DISABLED = 7,
}

export const DISCORD_LINK = 'https://discord.gg/xjbSaEQHgD';

// @ts-ignore
export const ANCHOR_CODER = new Coder(idl);

import useStore, { USER } from '../store/useStore';
import { useWalletKit } from '@gokiprotocol/walletkit';
import { Provider } from '@project-serum/anchor';
import { useConnectedWallet, useSolana } from '@saberhq/use-solana';
import { AnchorWallet, useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Connection } from '@solana/web3.js';
import React, { useCallback, useState } from 'react';
import { Menu } from '@headlessui/react';
import { useEffect } from 'react';
import SolanaWalletDisconnectButton from './SolanaWalletDisconnectButton';
import clsx from 'clsx';

function createProvider(connection: Connection, wallet: AnchorWallet | undefined) {
  if (wallet == null) return null;
  return new Provider(connection, wallet, { skipPreflight: true });
}
function SolanaWalletButton() {
  const setStore = useStore(store => store.set);
  const { disconnect, network, setNetwork } = useSolana();
  const { connect } = useWalletKit();
  const wallet = useConnectedWallet();

  const onDisconnect = () => {
    disconnect();
    setStore(state => {
      state.user = USER;
    });
  };

  return (
    <div className="flex w-48 justify-end ">
      {wallet == null ? (
        <button
          className={clsx(
            'text-purple-400',
            'rounded-sm border border-purple-400 bg-slate-900 px-4 py-2',
            'text-sm font-bold',
            'transition-colors duration-300 ease-in-out',
            'hover:bg-purple-400 hover:text-slate-50',
          )}
          onClick={() => connect()}
        >
          Connect Wallet
        </button>
      ) : (
        <SolanaWalletDisconnectButton disconnect={onDisconnect} wallet={wallet} />
      )}
    </div>
  );
}

export default SolanaWalletButton;

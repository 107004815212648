import { useState } from 'react';
import clsx from 'clsx';
import DepositTab from './DepositTab';
import WithdrawTab from './WithdrawTab';
import { Reserve, User } from '../../utils/lending/jet/JetTypes';
import BorrowTab from './BorrowTab';
import RepayTab from './RepayTab';

interface LendingTabsProps {
  reserve: Reserve;
  icon: React.FC;
}
interface LendingTab {
  id: number;
}
export default function LendingTabs({ reserve, icon }: LendingTabsProps) {
  const [activeLeftTab, setActiveLeftTab] = useState<number>(0);

  return (
    <div className="w-full">
      <div className="flex flex-col bg-slate-50 py-4 md:flex-row md:py-12">
        <div className="flex w-full items-center justify-center py-2">
          <div className="w-400 rounded-md border bg-white shadow-lg">
            <div className="flex flex-row border-b border-slate-200 ">
              <button
                className={clsx(
                  'flex w-1/4 justify-center py-2',
                  activeLeftTab === 0 && '-mb-px border-b-2 border-slate-900 text-slate-900',
                )}
                onClick={() => {
                  setActiveLeftTab(0);
                }}
              >
                Deposit
              </button>
              <button
                className={clsx(
                  'flex w-1/4 justify-center py-2',
                  activeLeftTab === 1 && '-mb-px border-b-2 border-slate-900 text-slate-900',
                )}
                onClick={() => {
                  setActiveLeftTab(1);
                }}
              >
                Withdraw
              </button>
              <button
                className={clsx(
                  'flex w-1/4 justify-center py-2',
                  activeLeftTab === 2 && '-mb-px border-b-2 border-slate-900 text-slate-900',
                )}
                onClick={() => {
                  setActiveLeftTab(2);
                }}
              >
                Borrow
              </button>
              <button
                className={clsx(
                  'flex w-1/4 justify-center py-2',
                  activeLeftTab === 3 && '-mb-px border-b-2 border-slate-900 text-slate-900',
                )}
                onClick={() => {
                  setActiveLeftTab(3);
                }}
              >
                Repay
              </button>
            </div>
            {activeLeftTab === 0 && <DepositTab reserve={reserve} icon={icon} />}
            {activeLeftTab === 1 && <WithdrawTab reserve={reserve} icon={icon} />}
            {activeLeftTab === 2 && <BorrowTab reserve={reserve} icon={icon} />}
            {activeLeftTab === 3 && <RepayTab reserve={reserve} icon={icon} />}
          </div>
        </div>
      </div>
    </div>
  );
}

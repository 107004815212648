import {
  selectFinalTxHash,
  selectTransactionState,
  selectTransferTxHash,
} from '../../store/selectors';
import { TransactionState } from '../../utils/consts';
import { getButtonColorClassName, getButtonTextColor } from '../../utils/getColorClass';
import { ChainId } from '@certusone/wormhole-sdk';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// import Tool

const PROGRESS_STRINGS = [
  'Awaiting transaction',
  'awaiting confirmation from Chain ⛓️',
  'Recieved Receipt ⛽',
  'Alerted Solana wormhole 🌌. This may take a while',
  'Tokens flying through wormhole ✨',
  'Transferring Tokens to SOL 📈', // TODO fix
  'Transaction completed! Thank you for using Citrus.',
];

function OrderTracker({ chainId }: { chainId: ChainId }) {
  const orderState = useSelector(selectTransactionState);
  // const orderState = 6;

  const progressColor = getButtonColorClassName(chainId, false);
  const progressPlaceholderColor = getButtonColorClassName(chainId, true);
  const [copiedSourceTx, setCopiedSourceTx] = useState<boolean>(false);
  const [copiedFinalTx, setCopiedFinalTx] = useState<boolean>(false);
  const transferTxHash = useSelector(selectTransferTxHash);
  const finalTxHash = useSelector(selectFinalTxHash);
  const buttonClassName = getButtonColorClassName(chainId, true);

  const textColorClassName = getButtonTextColor(chainId, true);
  // const transferTxHash =
  //   '656644564564564656566445645645646565664456456456465656644564564564656566445645645646565664456456456465';
  if (orderState === TransactionState.NONE || orderState === TransactionState.DISABLED) return null;
  return (
    <div
      className={clsx(
        'relative',
        'mx-auto',
        'rounded-xl bg-white',
        'max-w-lg',
        'w-11/12 md:w-full',
        'shadow-xl',
      )}
    >
      <div className="mt-8 py-4 px-2">
        <div className={clsx('w-full h-4 rounded-lg', progressPlaceholderColor)}>
          <div
            className={clsx('h-4 rounded-lg dark:bg-red-300', progressColor)}
            style={{ width: (orderState * 100) / 6 + '%' }}
          ></div>
        </div>
        <div className="pt-4 px-4 text-sm text-gray-800">{PROGRESS_STRINGS[orderState]}</div>
        {orderState > 1 && (
          <div>
            <div
              className={clsx(
                'w-full px-4 py-2 mt-4 text-sm font-medium text-left',
                buttonClassName,
                textColorClassName,
                'rounded-lg',
                'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
              )}
            >
              Transfer Hash
            </div>
            <Tooltip placement="top" title={copiedSourceTx ? 'Copied!' : 'Copy to clipboard'}>
              <div
                className={clsx(
                  'text-gray-800 mt-4 block px-4 py-2',
                  'flex flex-row items-center',
                  'hover:text-gray-600',
                  'cursor-pointer',
                )}
                onClick={() => {
                  navigator.clipboard.writeText(transferTxHash);
                  setCopiedSourceTx(true);
                  setTimeout(() => setCopiedSourceTx(false), 2000);
                }}
              >
                <p className="ml-3 truncate">{transferTxHash}</p>
              </div>
            </Tooltip>
          </div>
        )}
        {orderState === 6 && (
          <>
            <div
              className={clsx(
                'w-full px-4 py-2 mt-4 text-sm font-medium text-left',
                buttonClassName,
                textColorClassName,
                'rounded-lg',
                'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
              )}
            >
              Transaction Hash
            </div>
            <Tooltip placement="top" title={copiedFinalTx ? 'Copied!' : 'Copy to clipboard'}>
              <div
                className={clsx(
                  'text-gray-800 block mt-4 px-4 py-2',
                  'flex flex-row items-center',
                  'hover:text-gray-600',
                  'cursor-pointer',
                )}
                onClick={() => {
                  navigator.clipboard.writeText(finalTxHash);
                  setCopiedFinalTx(true);
                  setTimeout(() => setCopiedFinalTx(false), 2000);
                }}
              >
                <p className="ml-3 truncate">{finalTxHash}</p>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}

export default OrderTracker;

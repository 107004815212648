import { useEthereumProvider } from '../../contexts/EthereumProviderContext';
import useIsWalletReady from '../../hooks/useIsWalletReady';
import {
  selectSourceAmount,
  selectTargetAmount,
  selectTargetWalletAddress,
  selectTransactionState,
  selectTransferSourceChain,
} from '../../store/selectors';
import { setTransactionState } from '../../store/transferSlice';
import { isSolanaAddress } from '../../utils/actions';
import { TransactionState } from '../../utils/consts';
import { getButtonColorClassName, getButtonTextColor } from '../../utils/getColorClass';
import {
  INVALID_SOLANA_ADDRESS_STRING,
  SEND_TRANSACTION_STRING,
  SOURCE_TOO_SMALL_STRING,
  WALLET_NOT_CONNECTED_STRING,
} from '../../utils/strings';
import { transferLunaToSol, transferToSolEVM } from '../../utils/transfer';
import ws from '../../utils/ws';
import { ChainId, CHAIN_ID_ETH, CHAIN_ID_SOLANA, isEVMChain } from '@certusone/wormhole-sdk';
import { CircularProgress } from '@material-ui/core';
import { useConnectedWallet } from '@terra-money/wallet-provider';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function getButtonText(
  sourceChain: ChainId,
  sourceAmount: string,
  targetAmount: string,
  transactionState: TransactionState,
  isReady: boolean,
  solanaAddress: string,
) {
  if (transactionState !== TransactionState.NONE && transactionState !== TransactionState.SIX) {
    return (
      <div className="flex items-center justify-center">
        <CircularProgress
          style={{
            marginLeft: 12,
            marginBottom: -2,
            color: '#6b7280',
          }}
          size={16}
        />
        <div className="ml-2">Processing... DO NOT REFRESH THE PAGE</div>
      </div>
    );
  }
  // if (
  //   sourceChain !== CHAIN_ID_ETH &&
  //   !isNaN(Number.parseFloat(targetAmount)) &&
  //   Number.parseFloat(targetAmount) < 0.11
  // ) {
  //   return TRANSACTION_TOO_SMALL_STRING;
  // }
  if (!isNaN(Number.parseFloat(sourceAmount)) && Number.parseFloat(sourceAmount) < 0.01) {
    return SOURCE_TOO_SMALL_STRING;
  }
  if (!isReady) {
    return WALLET_NOT_CONNECTED_STRING;
  }
  if (!isSolanaAddress(solanaAddress)) {
    return INVALID_SOLANA_ADDRESS_STRING;
  }
  return SEND_TRANSACTION_STRING;
}

export default function SendTransactionButton(): ReactElement {
  // select solana address, amount, chain
  const sourceChain = useSelector(selectTransferSourceChain);
  const value = useSelector(selectSourceAmount);
  const solanaAddress = useSelector(selectTargetWalletAddress);
  const dispatch = useDispatch();
  const { signer } = useEthereumProvider();
  const terraWallet = useConnectedWallet();
  const { isReady } = useIsWalletReady(sourceChain);
  // TODO: put some status like transaction in progress

  const sourceAmount = useSelector(selectSourceAmount);
  const targetAmount = useSelector(selectTargetAmount);
  const transactionState = useSelector(selectTransactionState);
  const disabled = !(
    (
      isReady &&
      (transactionState === TransactionState.NONE || transactionState === TransactionState.SIX) &&
      isSolanaAddress(solanaAddress) &&
      !isNaN(Number.parseFloat(value)) &&
      sourceChain !== CHAIN_ID_SOLANA &&
      ws.readyState === ws.OPEN &&
      !isNaN(Number.parseFloat(sourceAmount)) &&
      Number.parseFloat(sourceAmount) > 0
    ));
  //   // &&
  //   // ((sourceChain === CHAIN_ID_ETH &&
  //   //   !(isNaN(Number.parseFloat(sourceAmount)) && Number.parseFloat(sourceAmount) > 0.01)) ||
  //   //   (!isNaN(Number.parseFloat(targetAmount)) && Number.parseFloat(targetAmount) > 0.11))
  // );
  // const disabled = true;

  const handleSubmit = () => {
    dispatch(setTransactionState(TransactionState.ONE));
    if (isEVMChain(sourceChain)) {
      if (signer == null) return;
      transferToSolEVM(sourceChain, signer, value, solanaAddress, dispatch);
    } else {
      if (terraWallet == null) return;
      transferLunaToSol(value, solanaAddress, terraWallet, dispatch);
    }
  };
  const buttonClassname = getButtonColorClassName(sourceChain, disabled);

  // const buttonText = `Swap currently down for maintenence`;
  const buttonText = getButtonText(
    sourceChain,
    sourceAmount,
    targetAmount,
    transactionState,
    isReady,
    solanaAddress,
  );
  const textColorClassName = getButtonTextColor(sourceChain, disabled);
  return (
    <button
      className={clsx(
        'rounded-lg',
        'w-full',
        'font-semibold',
        'border border-transparent hover:border-slate-200',
        'bg-slate-50',
        'mt-5 py-3 px-4',
        buttonClassname,
        textColorClassName,
        disabled && 'cursor-not-allowed',
      )}
      disabled={disabled}
      onClick={handleSubmit}
    >
      {buttonText}
    </button>
  );
}

import { ChainId } from '@certusone/wormhole-sdk';
import clsx from 'clsx';

const buttonClass = clsx('border border-slate-300 rounded-md text-sm font-medium text-slate-700');
const ToggleConnectedButton = ({
  connect,
  disconnect,
  connected,
  pk,
}: {
  connect(): any;
  disconnect(): any;
  connected: boolean;
  pk: string;
  sourceChain: ChainId;
}) => {
  const buttonColorClass = 'hover:bg-slate-50';

  const is0x = pk.startsWith('0x');

  return connected ? (
    <button onClick={disconnect} className={clsx(buttonClass, buttonColorClass)}>
      <div className="p-2">
        Disconnect {pk.substring(0, is0x ? 6 : 3)}...
        {pk.substr(pk.length - (is0x ? 4 : 3))}
      </div>
    </button>
  ) : (
    <button onClick={connect} className={clsx(buttonClass, buttonColorClass)}>
      <div className="p-2">Connect</div>
    </button>
  );
};

export default ToggleConnectedButton;

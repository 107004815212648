import axios from 'axios';
import { utils } from 'ethers';

async function querySolBalance(solAddress: string) {
  const solscanUrl = `https://public-api.solscan.io/account/${solAddress}`;
  const { data } = await axios.get(solscanUrl);
  if (data != null) {
    return utils.formatUnits(data.lamports?.toString() ?? '0', 9).toString();
  }
  return '...';
}

export default querySolBalance;

import App from './App';
import { EthereumProviderProvider } from './contexts/EthereumProviderContext';
import { TerraWalletProvider } from './contexts/TerraWalletContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Connection } from '@solana/web3.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const connection = new Connection('https://solana-api.projectserum.com');

ReactDOM.render(
  <React.StrictMode>
    {/* <JupiterProvider connection={connection} cluster="mainnet-beta" userPublicKey={ADMIN_PK}> */}
    <BrowserRouter>
      <Provider store={store}>
        <TerraWalletProvider>
          {/* @ts-ignore */}
          <EthereumProviderProvider>
            {/* @ts-ignore */}

            <App />
          </EthereumProviderProvider>
        </TerraWalletProvider>
      </Provider>
    </BrowserRouter>
    {/* </JupiterProvider> */}
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { setSourceChain } from '../../store/transferSlice';
import { CHAINS } from '../../utils/consts';
import AmountInputPanel from './AmountInputPanel';
import AppBody from './SwapAppBody';
import Disclosure from './Disclosure';
import OrderTracker from './OrderTracker';
import OutputPanel from './OutputPanel';
import SendTransactionButton from './SendTransactionButton';
import SolanaAddressInput from './SolanaAddressInput';
import SwapHeader from './SwapHeader';
import { ChainId, CHAIN_ID_AVAX } from '@certusone/wormhole-sdk';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

function Exchange({ sourceChain }: { sourceChain: ChainId }) {
  let { id } = useParams();
  console.log('id is', id);
  const dispatch = useDispatch();
  const handleSourceChange = useCallback(
    event => {
      dispatch(setSourceChain(event.target.value));
    },
    [dispatch],
  );
  return (
    <>
      <div className="bg-slate-900">
        <div
          className={clsx(
            'relative',
            'mx-auto',
            'rounded-t-md bg-white',
            'border border-b-0 border-slate-200',
            'max-w-lg',
            'w-11/12 md:w-full',
            'shadow-xl',
          )}
        >
          <SwapHeader sourceChain={sourceChain} onChange={handleSourceChange} chains={CHAINS} />
        </div>
      </div>
      <AppBody>
        <div className={clsx('relative p-2')}>
          <div className={clsx('grid', 'auto-rows-auto', 'gap-y-2')}>
            <div className={clsx('relative')}>
              <AmountInputPanel sourceChain={sourceChain} />
              {/* @ts-ignore */}
              <OutputPanel chainId={sourceChain} />
              <SolanaAddressInput />
              <SendTransactionButton />
            </div>
          </div>
        </div>
      </AppBody>

      <OrderTracker chainId={sourceChain} />
      <Disclosure chainId={sourceChain} />
    </>
  );
}

export default Exchange;

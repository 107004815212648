import { JetObligation } from '@jet-lab/jet-engine';
import { PublicKey } from '@solana/web3.js';
import { MIN_C_RATIO } from './consts';
import { CitrusReserve, Obligation, Reserve } from './jet/JetTypes';

export function getBorrowPercentage(position: Obligation, reserve: Reserve, uiValue: string) {
  // TODO see if borrow limit is the same for each reserve.
  const { depositedValue, borrowedValue, weightedCollateralValue } = position;

  const price = reserve.price ?? 1;
  const newBorrowValue = borrowedValue + price * Number.parseFloat(uiValue === '' ? '0' : uiValue);

  return (100 * newBorrowValue) / weightedCollateralValue;
}

export function calculateNetApy(obligation: JetObligation) {
  const positions = obligation.positions;
  // can be done with a reduce. Fuck it
  let netChange = 0;
  positions.forEach(position => {
    const positive =
      position.collateralBalance.tokens *
      (position.reserve.priceData.price ?? 0) *
      position.reserve.depositApy;
    const negative =
      position.loanBalance.tokens *
      (position.reserve.priceData.price ?? 0) *
      position.reserve.borrowApr;

    netChange += positive - negative;
  });

  const apyRatio = ((obligation.collateralValue + netChange) * 100) / obligation.collateralValue;
  // const netApy = apyRatio - 100;
  return apyRatio - 100;
}

// import { ChevronUpIcon } from '@heroicons/react/solid'
import { CHAIN_INFO, DISCORD_LINK } from '../../utils/consts';
import {
  getButtonColorClassName,
  getButtonTextColor,
  getHoverColorClassName,
} from '../../utils/getColorClass';
import {
  ATRIX_PLUG,
  ATRIX_PLUG_BODY,
  DISCLOSURE_HOW_IT_WORKS,
  DISCLOSURE_HOW_IT_WORKS_BODY,
  FEE_BODY,
  FEE_HEADER,
  WHAT_DOES_STAR_MEAN,
  WHAT_DOES_STAR_MEAN_BODY,
  WHAT_IS_CITRUS_BODY,
  WHAT_IS_CITRUS_HEADER,
  WHY_SOL_BODY,
  WHY_SOL_HEADER,
} from '../../utils/strings';
import { ChainId } from '@certusone/wormhole-sdk';
import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronsUp } from 'react-feather';
import { FaDiscord, FaTwitter, FaBookOpen, FaTelegramPlane, FaMedium } from 'react-icons/fa';

export default function DisclosureFAQ({ chainId }: { chainId: ChainId }) {
  const buttonClassName = getButtonColorClassName(chainId, true);
  const hoverButtonClassName = getHoverColorClassName(chainId);
  const textColorClassName = getButtonTextColor(chainId, true);
  const currencyName = CHAIN_INFO[chainId].shorthand;
  return (
    <div className="w-full px-4 pt-8 pb-4">
      <div className="mx-auto w-full max-w-lg rounded-md bg-white p-2 shadow-xl">
        <Disclosure as="div" className="mb-2">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={clsx(
                  'flex w-full justify-between px-4 py-2 text-left text-sm font-medium',
                  buttonClassName,
                  textColorClassName,
                  hoverButtonClassName,
                  'rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
                )}
              >
                <span>{WHAT_IS_CITRUS_HEADER}</span>
                <ChevronsUp
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 ${textColorClassName}`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-800">
                {WHAT_IS_CITRUS_BODY}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={clsx(
                  'flex w-full justify-between px-4 py-2 text-left text-sm font-medium',
                  buttonClassName,
                  textColorClassName,
                  hoverButtonClassName,
                  'rounded-lg',
                  'focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
                )}
              >
                <span>{ATRIX_PLUG}</span>
                <ChevronsUp
                  className={clsx(
                    `${open ? 'rotate-180 transform' : ''} h-5 w-5`,
                    textColorClassName,
                  )}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-800">
                {ATRIX_PLUG_BODY}{' '}
                <a
                  className="text-blue-600 underline visited:text-purple-600 hover:text-blue-800"
                  target="_blank"
                  href="https://jup.ag/"
                >
                  here
                </a>
                .
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={clsx(
                  'flex w-full justify-between px-4 py-2 text-left text-sm font-medium',
                  buttonClassName,
                  textColorClassName,
                  hoverButtonClassName,
                  'rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
                )}
              >
                <span>{DISCLOSURE_HOW_IT_WORKS}</span>
                <ChevronsUp
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 ${textColorClassName}`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-800">
                {() => DISCLOSURE_HOW_IT_WORKS_BODY(currencyName)}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={clsx(
                  'flex w-full justify-between px-4 py-2 text-left text-sm font-medium',
                  buttonClassName,
                  textColorClassName,
                  hoverButtonClassName,
                  'rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
                )}
              >
                <span>{WHAT_DOES_STAR_MEAN}</span>
                <ChevronsUp
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 ${textColorClassName}`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-800">
                {WHAT_DOES_STAR_MEAN_BODY}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button
                className={clsx(
                  'flex w-full justify-between px-4 py-2 text-left text-sm font-medium',
                  buttonClassName,
                  textColorClassName,
                  hoverButtonClassName,
                  'rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
                )}
              >
                <span>{FEE_HEADER}</span>
                <ChevronsUp
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 ${textColorClassName}`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-800">
                {FEE_BODY}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}

import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { ConnectedWallet } from '@saberhq/use-solana';
import { FaRegCopy, FaCopy, FaWallet } from 'react-icons/fa';
import { AiOutlineDisconnect } from 'react-icons/ai';

function displayShortAddress(address: string): string {
  if (address.length === 0) return '';
  return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`;
}
interface SolanaWalletDisconnectButtonProps {
  wallet: ConnectedWallet;
  disconnect: () => void;
}
export default function SolanaWalletDisconnectButton({
  wallet,
  disconnect,
}: SolanaWalletDisconnectButtonProps) {
  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={clsx(
              'inline-flex w-full justify-center rounded-sm border border-purple-400 bg-slate-900',
              'px-4 py-2 text-sm font-bold text-purple-400 hover:bg-purple-400 hover:text-white md:my-0',
              'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
              'transition-colors duration-300 ease-in-out',
            )}
          >
            <FaWallet className="mr-2 h-5 w-5" aria-hidden="true" />
            {displayShortAddress(wallet.publicKey.toString())}
            <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={clsx(
              'absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100',
              'rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
            )}
          >
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-purple-400 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => navigator.clipboard.writeText(wallet.publicKey.toString())}
                  >
                    {active ? (
                      <FaCopy className="mr-2 h-5 w-5" aria-hidden="true" />
                    ) : (
                      <FaRegCopy className="mr-2 h-5 w-5" aria-hidden="true" />
                    )}
                    Copy address
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-purple-400 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => disconnect()}
                  >
                    <AiOutlineDisconnect className="mr-2 h-5 w-5" aria-hidden="true" />
                    Disconnect
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

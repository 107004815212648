// import { DEX_PID } from '../store/useStore';
// import { getCurrentProvider } from '../utils/actions';
import * as anchor from '@project-serum/anchor';
import { Market, Orderbook as OrderbookSide } from '@project-serum/serum';
import { Order } from '@project-serum/serum/lib/market';
import Wallet from '@project-serum/sol-wallet-adapter';
import { Connection, PublicKey } from '@solana/web3.js';
import { useState, useRef, useEffect } from 'react';

export const RPC = 'https://solana-api.projectserum.com';
// const RPC = 'http://solana-api-delta.tt-prod.net';

export const connection = new Connection(RPC, {});

const DEX_PID = new PublicKey('9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin');

type Orderbook = {
  bids: OrderbookSide;
  asks: OrderbookSide;
};

type Bbo = {
  bestBid?: number;
  bestOffer?: number;
  mid?: number;
};

// side agnostic way of calculating the post slippage amount for an order on the ob.
export function calculatePostSlippageAmountSell(
  startAmount: string,
  orders: Generator<Order, any, unknown> | null,
) {
  if (orders == null) return '0';
  let amount = Number.parseFloat(startAmount);
  if (isNaN(amount)) return '0';
  let output = 0;
  while (amount > 0.0000001) {
    const currentOrder = orders.next();
    if (currentOrder.value == null) return output.toFixed(5).toString() + '*';
    const min = Math.min(currentOrder.value.size, amount);
    const price = currentOrder.value.price;
    output += min * price;
    amount = amount - min;
  }
  return output.toFixed(5);
}

export function calculatePostSlippageAmountBuy(
  startAmount: string,
  orders: Generator<Order, any, unknown> | null,
) {
  if (orders == null) return '0';
  let amount = Number.parseFloat(startAmount);
  if (isNaN(amount)) return '0';
  let output = 0;
  while (amount > 0.0000001) {
    const currentOrder = orders.next();
    if (currentOrder.value == null) return output.toFixed(5).toString() + '*';
    
    const min = Math.min(currentOrder.value.size, amount);
    const price = currentOrder.value.price;
    output += min / price;
    amount = amount - min;
  }
  return output.toFixed(5);
}

export function useBbo(market?: PublicKey): Bbo | undefined {
  const orderbook = useOrderbook(market);
  if (orderbook === undefined) {
    return undefined;
  }

  const bestBid = orderbook.bids.items(true).next().value;
  const bestOffer = orderbook.asks.items(false).next().value;
  if (!bestBid && !bestOffer) {
    return {};
  }
  if (!bestBid) {
    return { bestOffer: bestOffer.price };
  }
  if (!bestOffer) {
    return { bestBid: bestBid.price };
  }
  const mid = (bestBid.price + bestOffer.price) / 2.0;
  return { bestBid: bestBid.price, bestOffer: bestOffer.price, mid };
}

// Lazy load the orderbook for a given market.
export function useOrderbook(market?: PublicKey): Orderbook | undefined {
  const marketClient = useMarket(market);
  const [refresh, setRefresh] = useState(0);
  const [orderbook, setOrderbook] = useState<Orderbook>();
  const currMarket = useRef(market);

  useEffect(() => {
    setOrderbook(undefined);
    currMarket.current = market;
  }, [market]);

  useEffect(() => {
    let providerUrl = 'https://www.sollet.io';
    let wallet = new Wallet(providerUrl, 'test');

    const provider = new anchor.Provider(
      connection,
      wallet as any,
      // new Wallet(ADMIN),
      {
        commitment: 'processed',
      },
    );

    (async () => {
      if (!market || !marketClient) {
        return undefined;
      }

      if (!market.equals(marketClient.address)) {
        // console.log(market.toString(), marketClient.toString(), 'not eq');
        return;
      }

      if (_ORDERBOOK_CACHE.get(market.toString())) {
        const res = await _ORDERBOOK_CACHE.get(market.toString());
        if (currMarket.current?.toString() !== market.toString()) {
          return;
        }
        setOrderbook(res);
        return;
      }

      const orderbook = new Promise<Orderbook>(async resolve => {
        const [bids, asks] = await Promise.all([
          marketClient.loadBids(provider.connection),
          marketClient.loadAsks(provider.connection),
        ]);

        resolve({
          bids,
          asks,
        });
      });

      // orderbook.then((o) => {
      //   const bb = [] as any;
      //   for (const b of o.bids) {
      //     bb.push(b);
      //   }
      //   console.log(
      //     'bidding',
      //     marketClient.address.toString(),
      //     market?.toString(),
      //     bb.map((d) => d.price)
      //   );
      // });

      _ORDERBOOK_CACHE.set(market.toString(), orderbook);

      const res = await orderbook;

      if (currMarket.current?.toString() !== market.toString()) {
        return;
      }

      setOrderbook(res);
    })();
  }, [refresh, market, marketClient]);

  // Stream in bids updates.
  useEffect(() => {
    let providerUrl = 'https://www.sollet.io';
    let wallet = new Wallet(providerUrl, 'test');

    const provider = new anchor.Provider(
      connection,
      wallet as any,
      // new Wallet(ADMIN),
      {
        commitment: 'processed',
      },
    );

    let listener: number | undefined;
    if (marketClient?.bidsAddress) {
      listener = provider.connection.onAccountChange(marketClient?.bidsAddress, async info => {
        const bids = OrderbookSide.decode(marketClient, info.data);
        const orderbook = await _ORDERBOOK_CACHE.get(marketClient.address.toString());
        const oldBestBid = orderbook?.bids.items(true).next().value;
        const newBestBid = bids.items(true).next().value;
        if (orderbook && oldBestBid && newBestBid && oldBestBid.price !== newBestBid.price) {
          orderbook.bids = bids;
          setRefresh(r => r + 1);
        }
      });
    }
    return () => {
      if (listener) {
        provider.connection.removeAccountChangeListener(listener);
      }
    };
  }, [marketClient, marketClient?.bidsAddress]);

  // Stream in asks updates.
  useEffect(() => {
    let providerUrl = 'https://www.sollet.io';
    let wallet = new Wallet(providerUrl, 'test');

    const provider = new anchor.Provider(
      connection,
      wallet as any,
      // new Wallet(ADMIN),
      {
        commitment: 'processed',
      },
    );
    let listener: number | undefined;
    if (marketClient?.asksAddress) {
      listener = provider.connection.onAccountChange(marketClient?.asksAddress, async info => {
        const asks = OrderbookSide.decode(marketClient, info.data);
        const orderbook = await _ORDERBOOK_CACHE.get(marketClient.address.toString());
        const oldBestOffer = orderbook?.asks.items(false).next().value;
        const newBestOffer = asks.items(false).next().value;
        if (
          orderbook &&
          oldBestOffer &&
          newBestOffer &&
          oldBestOffer.price !== newBestOffer.price
        ) {
          orderbook.asks = asks;
          setRefresh(r => r + 1);
        }
      });
    }
    return () => {
      if (listener) {
        provider.connection.removeAccountChangeListener(listener);
      }
    };
  }, [marketClient, marketClient?.bidsAddress]);

  return orderbook;
}

// Lazy load a given market.
export function useMarket(market?: PublicKey): Market | undefined {
  const [mkt, setMkt] = useState<Market>();
  const currMarket = useRef(market);

  useEffect(() => {
    currMarket.current = market;
  }, [market]);

  useEffect(() => {
    let providerUrl = 'https://www.sollet.io';
    let wallet = new Wallet(providerUrl, 'test');

    const provider = new anchor.Provider(
      connection,
      wallet as any,
      // new Wallet(ADMIN),
      {
        commitment: 'processed',
      },
    );

    (async () => {
      if (!market) {
        return undefined;
      }
      if (_MARKET_CACHE.get(market.toString())) {
        const res = await _MARKET_CACHE.get(market.toString());
        if (currMarket.current?.toString() !== market.toString()) {
          return;
        }
        setMkt(res);
        return;
      }

      const marketClient = new Promise<Market>(async resolve => {
        // TODO: if we already have the mints, then pass them through to the
        //       market client here to save a network request.
        const marketClient = await Market.load(provider.connection, market, provider.opts, DEX_PID);
        resolve(marketClient);
      });

      _MARKET_CACHE.set(market.toString(), marketClient);

      const res = await marketClient;

      if (currMarket.current?.toString() !== market.toString()) {
        return;
      }

      setMkt(res);
    })();
  }, [market]);

  return mkt;
}

const _MARKET_CACHE = new Map<string, Promise<Market>>();
const _ORDERBOOK_CACHE = new Map<string, Promise<Orderbook>>();

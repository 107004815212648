import { PublicKey } from '@solana/web3.js';

// Arbitrary mint to represent SOL (not wrapped SOL).
export const SOL_MINT = new PublicKey(
  'Ejmc1UB4EsES5oAaRN63SpoxMJidt3ZGBrqrZk49vjTZ'
);

export const WRAPPED_SOL_MINT = new PublicKey(
  'So11111111111111111111111111111111111111112'
);

export const WSOL_MINT = new PublicKey(
  'DNmMghqjvHPuW7DLJkTF6QnTN3xgqDqL6VRXEQdF3KjK'
);

export const JET_USDC_MINT = new PublicKey(
  'DNmMghqjvHPuW7DLJkTF6QnTN3xgqDqL6VRXEQdF3KjK'
);

export const JET_USDC_FAUCET_PUBKEY = new PublicKey(
  '9BADYvZDaFBsGbeQEGeTQ9jBopLtd9fTKrycdjBXm7mZ'
);

import { selectTargetWalletAddress, selectTransactionState } from '../../store/selectors';
import { setTargetWalletAddress } from '../../store/transferSlice';
import { isSolanaAddress } from '../../utils/actions';
import querySolBalance from '../../utils/querySolBalance';
import { SOL_ADDRESS_PLACEHOLDER } from '../../utils/strings';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function getInputBorderColor(solanaAddress: string) {
  if (solanaAddress === '') {
    return 'border border-transparent';
  }
  if (isSolanaAddress(solanaAddress)) {
    return 'border border-purple-400';
  }
  return 'border border-red-300';
}

function SolanaAddressInput() {
  const dispatch = useDispatch();
  const solanaAddress = useSelector(selectTargetWalletAddress);
  const [solBalance, setSolBalance] = useState<string>('');
  const transactionState = useSelector(selectTransactionState);

  const handleSourceChange = useCallback(
    event => {
      dispatch(setTargetWalletAddress(event.target.value));
    },
    [dispatch],
  );
  useEffect(() => {
    // query solana address

    if (isSolanaAddress(solanaAddress)) {
      setSolBalance('...');
      const query = async () => {
        const lamports = await querySolBalance(solanaAddress);
        setSolBalance(lamports);
      };

      query();
    } else {
      setSolBalance('...');
    }
  }, [transactionState, solanaAddress]);

  const borderClass = getInputBorderColor(solanaAddress);
  return (
    <>
      <div
        id="container"
        className={clsx(
          'rounded-lg',
          'w-full',
          borderClass,
          'hover:shadow-inner',
          'bg-slate-50',
          'mt-1',
          'p-4',
        )}
      >
        <div className={clsx('flex flex-row', 'items-center justify-between')}>
          <input
            className={clsx(
              'text-xl font-medium text-slate-900',
              'trucate',
              'border-none outline-none',
              'bg-transparent',
              'w-full',
              'flex-auto',
            )}
            value={solanaAddress}
            onChange={handleSourceChange}
            // universal input options
            inputMode="text"
            autoComplete="off"
            autoCorrect="off"
            // text-specific options
            type="text"
            pattern="^[0-9]*[.,]?[0-9]*$"
            placeholder={SOL_ADDRESS_PLACEHOLDER}
            minLength={1}
            maxLength={79}
            spellCheck="false"
          />
        </div>
        <div className="text-trunacte tabular-nums text-slate-500">Balance: {solBalance}</div>
      </div>
      {/* <ValidAddress solanaAddress={solanaAddress} /> */}
    </>
  );
}

export default SolanaAddressInput;

import idl from '../jet.json';
import marketData from '../marketData.json';
import useStore from '../store/useStore';
import { CITRUS_LENDING_PROGRAM_ID } from '../utils/lending/consts';
import type { Reserve, ReserveMetadata } from '../utils/lending/jet/JetTypes';
import { getAssetPubkeys } from '../utils/lending/jet/jet';
import { subscribeToAssets, subscribeToMarket } from '../utils/lending/subscriptions';
import { getOwnedAssociatedTokenAccounts } from '../utils/lending/tokens';
import * as anchor from '@project-serum/anchor';
import { Connection, Keypair, PublicKey } from '@solana/web3.js';
import { useEffect, useRef } from 'react';
import { useConnectedWallet } from '@saberhq/use-solana';
import { SOL_MINT } from '../utils/consts';
import { TokenAmount } from '../utils/lending/jet/tokenAmount';

// TODO: load user obligation to this stuff
export function loadReserveMetaData(reserveMetas: any[]) {
  return reserveMetas.map(reserveMeta => {
    const accounts = reserveMeta.accounts;
    const accountsMapped = Object.fromEntries(
      Object.entries(accounts).map(([k, v]) => [k, new PublicKey(v as string)]),
    );

    return { ...reserveMeta, accounts: accountsMapped };
  });
}
const connection = new Connection('https://solana-api-delta.tt-prod.net/');

let first = true;

export default function useLoadLending() {
  const userWallet = useConnectedWallet() as unknown as anchor.Wallet;
  const setStore = useStore(store => store.set);

  const assetSubscriptions = useRef<Array<number>>([]);
  const marketSubscriptions = useRef<Array<number>>([]);

  useEffect(() => {
    const doit = async () => {
      const removeListeners1 = assetSubscriptions.current.map(id =>
        connection.removeAccountChangeListener(id),
      );
      const removeListeners2 = marketSubscriptions.current.map(id =>
        connection.removeAccountChangeListener(id),
      );
      await Promise.all([removeListeners1, removeListeners2]);

      const wallet = userWallet ?? new anchor.Wallet(new Keypair());
      const provider = new anchor.Provider(connection, wallet, {
        skipPreflight: true,
        // commitment: 'processed',
      });
      // @ts-ignore something wrong with IDL type imported this way doesn't matter
      const program = new anchor.Program(idl, CITRUS_LENDING_PROGRAM_ID, provider);

      setStore(state => {
        state.program = program;
      });

      // @ts-ignore
      const reserveMetadatas: ReserveMetadata[] = loadReserveMetaData(marketData.market.reserves);

      if (first) {
        const citrusReserves: Reserve[] = reserveMetadatas.map(reserveMeta => {
          return {
            name: reserveMeta.name,
            abbrev: reserveMeta.abbrev,
            marketSize: TokenAmount.zero(reserveMeta.decimals),
            outstandingDebt: TokenAmount.zero(reserveMeta.decimals),
            utilizationRate: 0,
            depositRate: 0,
            borrowRate: 0,
            maximumLTV: 0,
            liquidationPremium: 0,
            price: 0,
            decimals: reserveMeta.decimals,
            depositNoteExchangeRate: new anchor.BN(0),
            loanNoteExchangeRate: new anchor.BN(0),
            accruedUntil: new anchor.BN(0),
            config: {
              utilizationRate1: 0,
              utilizationRate2: 0,
              borrowRate0: 0,
              borrowRate1: 0,
              borrowRate2: 0,
              borrowRate3: 0,
              minCollateralRatio: 0,
              liquidationPremium: 0,
              manageFeeCollectionThreshold: new anchor.BN(0),
              manageFeeRate: 0,
              loanOriginationFee: 0,
              liquidationSlippage: 0,
              _reserved0: 0,
              liquidationDexTradeMax: 0,
              _reserved1: [],
              confidenceThreshold: 0,
            },

            accountPubkey: reserveMeta.accounts.reserve,
            vaultPubkey: reserveMeta.accounts.vault,
            availableLiquidity: TokenAmount.zero(reserveMeta.decimals),
            feeNoteVaultPubkey: reserveMeta.accounts.feeNoteVault,
            tokenMintPubkey: reserveMeta.accounts.tokenMint,
            tokenMint: TokenAmount.zero(reserveMeta.decimals),
            faucetPubkey: reserveMeta.accounts.faucet ?? null,
            depositNoteMintPubkey: reserveMeta.accounts.depositNoteMint,
            depositNoteMint: TokenAmount.zero(reserveMeta.decimals),
            loanNoteMintPubkey: reserveMeta.accounts.loanNoteMint,
            loanNoteMint: TokenAmount.zero(reserveMeta.decimals),
            pythPricePubkey: reserveMeta.accounts.pythPrice,
            pythProductPubkey: reserveMeta.accounts.pythProduct,
          };
        });

        setStore(state => {
          state.reserves = citrusReserves;
        });
        first = false;
      }

      marketSubscriptions.current = await subscribeToMarket(program, setStore, reserveMetadatas);

      if (userWallet != null) {
        const getAssetStore = getAssetPubkeys(program, wallet, reserveMetadatas);
        const getOwnedAccounts = getOwnedAssociatedTokenAccounts(connection, wallet.publicKey);

        const [assetStore, ownedAccounts, solInfo] = await Promise.all([
          // getJetUser,
          getAssetStore,
          getOwnedAccounts,
          provider.connection.getAccountInfo(wallet.publicKey),
        ]);
        ownedAccounts.push({
          publicKey: wallet.publicKey,
          // @ts-ignore
          account: {
            amount: new anchor.BN(solInfo?.lamports!),
            mint: SOL_MINT,
          },
        });
        setStore(state => {
          state.user = {
            ...state.user,
            assets: assetStore,
          };
        });

        //   // maybe put this in the store too?

        assetSubscriptions.current = await subscribeToAssets(
          wallet,
          assetStore,
          program,
          setStore,
          reserveMetadatas,
        );
      }
    };
    doit();
  }, [userWallet]);
}

import coingecko from '../../icons/coingecko.svg';
import fetchCoinStats from '../../utils/fetchCoinStats';
import { getBackgroundColorClass, getHoverColorClassWithHover } from '../../utils/getColorClass';
import AppBody from '../swap/SwapAppBody';
import { ChainId } from '@certusone/wormhole-sdk';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

function CommunityIconPlaceholder({
  index,
  coin,
  btcPrice,
  sourceChain,
}: {
  index: number;
  coin: any;
  btcPrice: number;
  sourceChain: ChainId;
}) {
  if (coin == null) {
    return (
      <div className={clsx('border shadow rounded-md p-4 w-full mx-auto', index > 0 && 'mt-4')}>
        <div className="animate-pulse items-center flex space-x-4">
          <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const { item } = coin;
  const { name, symbol, large, score, id, price_btc } = item;
  console.log(item);
  const price = (btcPrice * price_btc).toFixed(5);
  const borderClass = getHoverColorClassWithHover(sourceChain);

  return (
    <div
      className={clsx(
        'border shadow rounded-md py-2 px-4 w-full mx-auto text-slate-900',
        'hover:bg-slate-50',
        index > 0 && 'mt-4',
      )}
    >
      <a
        target="_blank"
        href={`https://www.coingecko.com/en/coins/${id}`}
        className={clsx('rounded-md mx-auto', borderClass)}
      >
        <div className="flex space-x-4 items-center">
          <div className="font-semibold text-lg">{score + 1}</div>
          <img className="rounded-full h-8 w-8" src={large} />
          <div className="flex-1 space-y-1 py-1">
            <div className="font-semibold">{symbol}</div>
            <div className="text-slate-500">{name}</div>
          </div>
          <div className="text-semibold">${price}</div>
        </div>
      </a>
    </div>
  );
}

function ListItem({ item }: { item: any }) {
  const { name, symbol, small, thumb, score, id } = item;

  console.log(item);
  const linkText = `https://www.coingecko.com/en/coins/${name}`;
  return (
    <li
      className={clsx(
        'w-full bg-slate-50 hover:bg-slate-100 rounded-xl py-2 pl-2',
        'flex flex-row items-center',
        score > 0 && 'mt-4',
        'border border-black',
      )}
    >
      <div className={clsx('pr-2')}>{score + 1}</div>
      <img src={thumb} />
      <a></a>
      {name}
    </li>
  );
}
export default function Stats({ sourceChain }: { sourceChain: ChainId }) {
  // coingecko returns top 7 coins
  const [coinStats, setCoinStats] = useState<any>([null, null, null, null, null, null, null]);
  const [bitcoinPrice, setBitcoinPrice] = useState<any>(0);

  const btcPrice = bitcoinPrice?.market_data?.current_price?.usd ?? 0;

  useEffect(() => {
    const fetchStats = async () => {
      const res = await fetchCoinStats();
      console.log(res);
      const { coins, bitcoinPrice } = res;
      setBitcoinPrice(bitcoinPrice);

      setCoinStats(coins);
    };

    bitcoinPrice === 0 && fetchStats();
  }, []);

  console.log(coinStats && 'hello');
  return (
    <div className={'pb-4'}>
      <AppBody>
        <div className="py-4 px-4">
          <div className="mb-4 text-slate-500 text-lg flex flex-row items-center">
            <div>Trending on </div>
            <svg className="mx-2" width="100" height="24">
              <image xlinkHref={coingecko} width="100" height="24" />
            </svg>
          </div>
          <div className={clsx('border rounded-md shadow flex flex-row items-center', 'p-1 mb-2')}>
            <div className="px-4">#</div>
            <div className="pl-12 flex-1">Name</div>
            <div className="mr-16">Price</div>
          </div>
          {coinStats &&
            coinStats.map((coin: any, index: number) => (
              <CommunityIconPlaceholder
                coin={coin}
                index={index}
                btcPrice={btcPrice}
                sourceChain={sourceChain}
              />
            ))}
        </div>
      </AppBody>
    </div>
  );
}

import { ClusterType } from '../@types/types';
import {
  Asset,
  AssetStore,
  CitrusReserve,
  Reserve,
  ReserveMetadata,
  User,
} from '../utils/lending/jet/JetTypes';
import { JetMarket, JetObligation, JetReserve, JetUser } from '@jet-lab/jet-engine';
import { BN, Provider } from '@project-serum/anchor';
import * as anchor from '@project-serum/anchor';
import { AccountInfo, AccountInfo as TokenAccount, MintInfo } from '@solana/spl-token';
import { ConfirmOptions, Connection, Keypair, PublicKey } from '@solana/web3.js';
import produce from 'immer';
import create, { State } from 'zustand';
import { CITRUS_LENDING_PROGRAM_ID } from '../utils/lending/consts';
import idl from '../jet.json';

export const ENDPOINTS = [
  {
    name: 'mainnet-beta',
    // url: 'https://api.mainnet-beta.solana.com',
    // websocket: 'https://api.mainnet-beta.solana.com',
    // url: 'https://solana-api.projectserum.com',
    // websocket: 'https://solana-api.projectserum.com',
    // url: 'http://solana-api-delta.tt-prod.net',
    // websocket: 'http://solana-api-delta.tt-prod.net',
    url: 'https://solana-api.projectserum.com',
    websocket: 'https://solana-api.projectserum.com',
    custom: false,
  },
  {
    name: 'devnet',
    // url: 'https://api.devnet.solana.com',
    // websocket: 'https://api.devnet.solana.com',
    url: 'https://liquid.devnet.rpcpool.com/6753529fc3f34308f83188d54815',
    websocket: 'https://liquid.devnet.rpcpool.com/6753529fc3f34308f83188d54815',
    custom: false,
  },
];
export const CLUSTER = process.env.REACT_APP_CLUSTER ?? 'mainnet-beta';
const ENDPOINT = ENDPOINTS.find(e => e.name === CLUSTER)!;

export const DEFAULT_TX_OPTS: ConfirmOptions = {
  skipPreflight: process.env.NODE_ENV !== 'development',
  // skipPreflight: false,
  // skipPreflight: true,
  commitment: 'processed',
  // commitment: 'confirmed',
  // commitment: 'finalized',
};

export const DEFAULT_CONNECTION = new Connection(ENDPOINT.url, DEFAULT_TX_OPTS.commitment);

const WEBSOCKET_CONNECTION = new Connection(ENDPOINT.websocket, DEFAULT_TX_OPTS.commitment);

export interface OwnedAccount {
  publicKey: PublicKey;
  account: AccountInfo;
}

export const connection = new Connection(ENDPOINT.url);

export const USER: User = {
  // Locale
  locale: null,
  geobanned: false,

  // Wallet
  connectingWallet: true,
  wallet: null,
  walletInit: false,
  tradeAction: 'deposit',

  // Assets and position
  assets: null,
  walletBalances: {},
  collateralBalances: {},
  loanBalances: {},
  position: {
    depositedValue: 0,
    borrowedValue: 0,
    // sum of LTV * deposited amount
    // equal to the users borrow limit
    weightedCollateralValue: 0,
    colRatio: 0,
    netApy: 0,
    utilizationRate: 0,
  },

  // Transaction Logs
  transactionLogs: [],
  transactionLogsInit: true,

  // Notifications
  notifications: [],

  // Add notification
  // addNotification: (n: Notification) => {
  //   const notifs = user.notifications ?? [];
  //   notifs.push(n);
  //   const index = notifs.indexOf(n);
  //   USER.update(user => {
  //     user.notifications = notifs;
  //     return user;
  //   });
  //   setTimeout(() => {
  //     if (user.notifications[index] && user.notifications[index].text === n.text) {
  //       user.clearNotification(index);
  //     }
  //   }, 5000);
  // },
  // Clear notification
  // clearNotification: (i: number) => {
  //   const notifs = user.notifications;
  //   notifs.splice(i, 1);
  //   USER.update(user => {
  //     user.notifications = notifs;
  //     return user;
  //   });
  // },

  // Settings
  darkTheme: localStorage.getItem('jetDark') === 'true',
  navExpanded: localStorage.getItem('jetNavExpanded') === 'true',
  language: localStorage.getItem('jetPreferredLanguage') ?? 'en',
  rpcNode: localStorage.getItem('jetPreferredNode') ?? '',
  rpcPing: 0,
  explorer: localStorage.getItem('jetPreferredExplorer') ?? 'Solscan',
};

const wallet = new anchor.Wallet(new Keypair());
const provider = new anchor.Provider(connection, wallet, {
  skipPreflight: true,
});

// @ts-ignore
const program = new anchor.Program(idl, CITRUS_LENDING_PROGRAM_ID, provider);

export interface Store extends State {
  connection: Connection;
  program: anchor.Program;
  associatedTokenAccounts: Array<{
    publicKey: PublicKey;
    account: TokenAccount;
  }>;
  reserves: Reserve[];
  wallet: anchor.Wallet | undefined;
  walletName: string;
  user: User;
  set: (fn: (store: Store) => void) => void;
}

const useStore = create<Store>((set, get) => ({
  connection: connection,
  program: program,
  associatedTokenAccounts: [],
  ownedTokenAccounts: [],
  reserves: [],
  reservesMetadata: [],
  wallet: undefined,
  walletName: '',
  set: fn => set(produce(fn)),
  user: USER,
}));

export default useStore;

import { setFinalTxHash, setTransactionState } from '../store/transferSlice';
import { TransactionState } from './consts';
import notification from 'antd/lib/notification';
import type { IMessageEvent } from 'websocket';

function handleWSMessage(message: IMessageEvent, dispatch: any) {
  const { data } = message;
  // @ts-ignore
  const parsed = JSON.parse(data);
  const { type } = parsed;
  switch (type) {
    case 'NOTIFICATION': {
      const { message, description, step } = parsed;
      dispatch(setTransactionState(step ?? 0));
      if ((step ?? 0) === 6) {
        dispatch(setFinalTxHash(description));
      }
      break;
    }
    // case 'FINISH': {
    //   setShowAtrix(true);
    //   dispatch(setTransactionState(TransactionState.NONE));
    // }
    // need a case to set transaction from ongoing to completed
  }
}

export default handleWSMessage;

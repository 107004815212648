import clsx from 'clsx';
import React from 'react';

// max width might need adjustment

/**
 * container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  return (
      <div
        className={clsx(
          'relative',
          'mx-auto',
          'rounded-b-md bg-white',
          'max-w-lg',
          'w-11/12 md:w-full',
          'shadow-xl',
          'border border-t-0 border-slate-200',
        )}
        {...rest}
      >
        {children}
      </div>
  );
}

import * as anchor from '@project-serum/anchor';
import {
  AccountInfo,
  PublicKey,
  Context,
  Commitment,
  Connection,
  TransactionInstruction,
  Signer,
  ConfirmOptions,
  Transaction,
} from '@solana/web3.js';
import {
  JetMarketReserveInfo,
  MarketAccount,
  ObligationAccount,
  ObligationPositionStruct,
  ReserveAccount,
  ReserveStateStruct,
  TxnResponse,
} from './JetTypes';
import notification from 'antd/lib/notification';

import { TokenAmount } from './tokenAmount';

import {
  AccountLayout as TokenAccountLayout,
  AccountInfo as TokenAccountInfo,
  MintLayout,
  MintInfo,
} from '@solana/spl-token';
import { BN } from '@project-serum/anchor';
import { ReserveConfig } from '@jet-lab/jet-engine';
import { MarketReserveInfoList, PositionInfoList, ReserveStateLayout } from './layout';

export const parseTokenAccount = (account: AccountInfo<Buffer>, accountPubkey: PublicKey) => {
  const data = TokenAccountLayout.decode(account.data);

  // PublicKeys and BNs are currently Uint8 arrays and
  // booleans are really Uint8s. Convert them
  const decoded: AccountInfo<TokenAccountInfo> = {
    ...account,
    data: {
      address: accountPubkey,
      mint: new PublicKey(data.mint),
      owner: new PublicKey(data.owner),
      amount: new BN(data.amount, undefined, 'le'),
      delegate: (data as any).delegateOption ? new PublicKey(data.delegate!) : null,
      delegatedAmount: new BN(data.delegatedAmount, undefined, 'le'),
      isInitialized: (data as any).state != 0,
      isFrozen: (data as any).state == 2,
      isNative: !!(data as any).isNativeOption,
      rentExemptReserve: new BN(0, undefined, 'le'), //  Todo: calculate. I believe this is lamports minus rent for wrapped sol
      closeAuthority: (data as any).closeAuthorityOption
        ? new PublicKey(data.closeAuthority!)
        : null,
    },
  };
  return decoded;
};

export const findDepositNoteAddress = async (
  program: anchor.Program,
  reserve: PublicKey,
  wallet: PublicKey,
): Promise<[depositNotePubkey: PublicKey, depositAccountBump: number]> => {
  return findProgramAddress(program.programId, ['deposits', reserve, wallet]);
};

/**
 * Find the obligation for the wallet.
 */
export const findObligationAddress = async (
  program: anchor.Program,
  market: PublicKey,
  wallet: PublicKey,
): Promise<[obligationPubkey: PublicKey, obligationBump: number]> => {
  return findProgramAddress(program.programId, ['obligation', market, wallet]);
};

/** Find loan note token account for the reserve, obligation and wallet. */
export const findLoanNoteAddress = async (
  program: anchor.Program,
  reserve: PublicKey,
  obligation: PublicKey,
  wallet: PublicKey,
): Promise<[loanNotePubkey: PublicKey, loanNoteBump: number]> => {
  return findProgramAddress(program.programId, ['loan', reserve, obligation, wallet]);
};

/** Find collateral account for the reserve, obligation and wallet. */
export const findCollateralAddress = async (
  program: anchor.Program,
  reserve: PublicKey,
  obligation: PublicKey,
  wallet: PublicKey,
): Promise<[collateralPubkey: PublicKey, collateralBump: number]> => {
  return findProgramAddress(program.programId, ['collateral', reserve, obligation, wallet]);
};

export interface ToBytes {
  toBytes(): Uint8Array;
}

export interface HasPublicKey {
  publicKey: PublicKey;
}

/**
 * Find a program derived address
 * @param programId The program the address is being derived for
 * @param seeds The seeds to find the address
 * @returns The address found and the bump seed required
 */
export const findProgramAddress = async (
  programId: PublicKey,
  seeds: (HasPublicKey | ToBytes | Uint8Array | string)[],
): Promise<[PublicKey, number]> => {
  const seed_bytes = seeds.map(s => {
    if (s == null) return s;
    if (typeof s == 'string') {
      return new TextEncoder().encode(s);
    } else if ('publicKey' in s) {
      return s.publicKey.toBytes();
    } else if ('toBytes' in s) {
      return s.toBytes();
    } else {
      return s;
    }
  });

  return await anchor.web3.PublicKey.findProgramAddress(seed_bytes, programId);
};

/**
 * Fetch an account for the specified public key and subscribe a callback
 * to be invoked whenever the specified account changes.
 *
 * @param connection Connection to use
 * @param publicKey Public key of the account to monitor
 * @param callback Function to invoke whenever the account is changed
 * @param commitment Specify the commitment level account changes must reach before notification
 * @return subscription id
 */
export const getAccountInfoAndSubscribe = async function (
  connection: anchor.web3.Connection,
  publicKey: anchor.web3.PublicKey,
  callback: (acc: AccountInfo<Buffer> | null, context: Context) => void,
  commitment?: Commitment | undefined,
): Promise<number> {
  let latestSlot: number = -1;
  let subscriptionId = connection.onAccountChange(
    publicKey,
    (account: AccountInfo<Buffer>, context: Context) => {
      if (context.slot >= latestSlot) {
        latestSlot = context.slot;
        callback(account, context);
      }
    },
    commitment,
  );

  const response = await connection.getAccountInfoAndContext(publicKey, commitment);
  if (response.context.slot >= latestSlot) {
    latestSlot = response.context.slot;
    if (response.value != null) {
      callback(response.value, response.context);
    } else {
      callback(null, response.context);
    }
  }

  return subscriptionId;
};

export const findDepositNoteDestAddress = async (
  program: anchor.Program,
  reserve: PublicKey,
  wallet: PublicKey,
): Promise<[depositNoteDestPubkey: PublicKey, depositNoteDestBump: number]> => {
  return findProgramAddress(program.programId, [reserve, wallet]);
};

/** Linear interpolation between (x0, y0) and (x1, y1)
 */
const interpolate = (x: number, x0: number, x1: number, y0: number, y1: number): number => {
  console.assert!(x >= x0);
  console.assert!(x <= x1);

  return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
};

export const getCcRate = (reserveConfig: ReserveConfig, utilRate: number): number => {
  const basisPointFactor = 10000;
  let util1 = reserveConfig.utilizationRate1 / basisPointFactor;
  let util2 = reserveConfig.utilizationRate2 / basisPointFactor;
  let borrow0 = reserveConfig.borrowRate0 / basisPointFactor;
  let borrow1 = reserveConfig.borrowRate1 / basisPointFactor;
  let borrow2 = reserveConfig.borrowRate2 / basisPointFactor;
  let borrow3 = reserveConfig.borrowRate3 / basisPointFactor;

  if (utilRate <= util1) {
    return interpolate(utilRate, 0, util1, borrow0, borrow1);
  } else if (utilRate <= util2) {
    return interpolate(utilRate, util1, util2, borrow1, borrow2);
  } else {
    return interpolate(utilRate, util2, 1, borrow2, borrow3);
  }
};

/** Borrow rate
 */
export const getBorrowRate = (ccRate: number, fee: number): number => {
  const basisPointFactor = 10000;
  fee = fee / basisPointFactor;
  const secondsPerYear: number = 365 * 24 * 60 * 60;
  const rt = ccRate / secondsPerYear;

  return Math.log1p((1 + fee) * Math.expm1(rt)) * secondsPerYear;
};

/** Deposit rate
 */
export const getDepositRate = (ccRate: number, utilRatio: number): number => {
  const secondsPerYear: number = 365 * 24 * 60 * 60;
  const rt = ccRate / secondsPerYear;

  return Math.log1p(Math.expm1(rt)) * secondsPerYear * utilRatio;
};

export const getTokenAccountAndSubscribe = async function (
  connection: Connection,
  publicKey: anchor.web3.PublicKey,
  decimals: number,
  callback: (amount: TokenAmount | undefined, context: Context) => void,
  commitment?: Commitment,
): Promise<number> {
  return getAccountInfoAndSubscribe(
    connection,
    publicKey,
    (account, context) => {
      if (account != null) {
        if (account.data.length != 165) {
          console.log('account data length', account.data.length);
          return;
        }
        const decoded = parseTokenAccount(account, publicKey);
        const amount = TokenAmount.tokenAccount(decoded.data, decimals);
        // console.log('amount :>> ', amount);
        callback(amount, context);
      } else {
        // callback(undefined, context);
      }
    },
    commitment,
  );
};

export const parseMarketAccount = (account: Buffer, coder: anchor.Coder) => {
  let market = coder.accounts.decode<MarketAccount>('Market', account);

  let reserveInfoData = new Uint8Array(market.reserves as any as number[]);
  let reserveInfoList = MarketReserveInfoList.decode(reserveInfoData) as JetMarketReserveInfo[];

  market.reserves = reserveInfoList;
  return market;
};

export const parseReserveAccount = (account: Buffer, coder: anchor.Coder) => {
  let reserve = coder.accounts.decode<ReserveAccount>('Reserve', account);

  const reserveState = ReserveStateLayout.decode(
    Buffer.from(reserve.state as any as number[]),
  ) as ReserveStateStruct;

  reserve.state = reserveState;
  return reserve;
};

export const parseObligationAccount = (account: Buffer, coder: anchor.Coder) => {
  let obligation = coder.accounts.decode<ObligationAccount>('Obligation', account);

  const parsePosition = (position: any) => {
    const pos: ObligationPositionStruct = {
      account: new PublicKey(position.account),
      amount: new BN(position.amount),
      side: position.side,
      reserveIndex: position.reserveIndex,
      _reserved: [],
    };
    return pos;
  };

  obligation.collateral = PositionInfoList.decode(
    Buffer.from(obligation.collateral as any as number[]),
  ).map(parsePosition);

  obligation.loans = PositionInfoList.decode(Buffer.from(obligation.loans as any as number[])).map(
    parsePosition,
  );

  return obligation;
};

export interface InstructionAndSigner {
  ix: TransactionInstruction[];
  signers?: Signer[];
}

export const sendTransaction = async (
  provider: anchor.Provider,
  instructions: TransactionInstruction[],
  signers?: Signer[],
  skipConfirmation?: boolean,
): Promise<[res: TxnResponse, txid: string[]]> => {
  if (!provider.wallet?.publicKey) {
    throw new Error('Wallet is not connected');
  }
  console.log('starting');
  // Building phase
  let transaction = new Transaction();
  transaction.instructions = instructions;
  transaction.recentBlockhash = (await provider.connection.getRecentBlockhash()).blockhash;
  transaction.feePayer = provider.wallet.publicKey;

  // Signing phase
  if (signers && signers.length > 0) {
    transaction.partialSign(...signers);
  }
  //Slope wallet funcs only take bs58 strings
  // if (walletName === 'Slope') {
  //   console.log('why the fuck is it slope');
  //   try {
  //     const { msg, data } = (await provider.wallet.signTransaction(
  //       bs58.encode(transaction.serializeMessage()) as any,
  //     )) as unknown as SlopeTxn;
  //     if (!data.publicKey || !data.signature) {
  //       throw new Error('Transaction Signing Failed');
  //     }
  //     transaction.addSignature(
  //       new PublicKey(data.publicKey),
  //       bs58.decode(data.signature),
  //     );
  //   } catch (err) {
  //     console.log('Signing Transactions Failed', err);
  //     return [TxnResponse.Cancelled, []];
  //   }
  // } else {
  try {
    transaction = await provider.wallet.signTransaction(transaction);
  } catch (err) {
    console.log('Signing Transactions Failed', err, [TxnResponse.Failed, null]);
    // wallet refused to sign
    return [TxnResponse.Cancelled, []];
  }
  notification.open({
    placement: 'bottomLeft',
    message: 'Beginning Transaction',
  });
  // }

  // Sending phase
  const rawTransaction = transaction.serialize();
  const txid = await provider.connection.sendRawTransaction(rawTransaction, provider.opts);

  // Confirming phase
  let res = TxnResponse.Success;
  if (!skipConfirmation) {
    const status = (await provider.connection.confirmTransaction(txid, provider.opts.commitment))
      .value;

    if (status?.err && txid.length) {
      res = TxnResponse.Failed;
    }
  }
  return [res, [txid]];
};

export const sendAllTransactions = async (
  provider: anchor.Provider,
  transactions: InstructionAndSigner[],
  skipConfirmation?: boolean,
): Promise<[res: TxnResponse, txids: string[]]> => {
  if (!provider.wallet?.publicKey) {
    throw new Error('Wallet is not connected');
  }

  // Building and partial sign phase
  const recentBlockhash = await provider.connection.getRecentBlockhash();
  const txs: Transaction[] = [];
  for (const tx of transactions) {
    if (tx.ix.length == 0) {
      continue;
    }
    let transaction = new Transaction();
    transaction.instructions = tx.ix;
    transaction.recentBlockhash = recentBlockhash.blockhash;
    transaction.feePayer = provider.wallet.publicKey;
    if (tx.signers && tx.signers.length > 0) {
      transaction.partialSign(...tx.signers);
    }
    txs.push(transaction);
  }

  // Signing phase
  let signedTransactions: Transaction[] = [];
  //Slope wallet funcs only take bs58 strings
  // TODO: enable slope
  // if (user.wallet?.name === 'Slope') {
  //   try {
  //     const { msg, data } = (await provider.wallet.signAllTransactions(
  //       txs.map((txn) => bs58.encode(txn.serializeMessage())) as any,
  //     )) as unknown as SlopeTxn;
  //     const txnsLen = txs.length;
  //     if (!data.publicKey || data.signatures?.length !== txnsLen) {
  //       throw new Error('Transactions Signing Failed');
  //     }
  //     for (let i = 0; i < txnsLen; i++) {
  //       txs[i].addSignature(
  //         new PublicKey(data.publicKey),
  //         bs58.decode(data.signatures[i]),
  //       );
  //       signedTransactions.push(txs[i]);
  //     }
  //   } catch (err) {
  //     console.log('Signing All Transactions Failed', err);
  //     // wallet refused to sign
  //     return [TxnResponse.Cancelled, []];
  //   }
  // } else {
  try {
    //solong does not have a signAllTransactions Func so we sign one by one
    if (!provider.wallet.signAllTransactions) {
      for (const tx of txs) {
        const signedTxn = await provider.wallet.signTransaction(tx);
        signedTransactions.push(signedTxn);
      }
    } else {
      signedTransactions = await provider.wallet.signAllTransactions(txs);
    }
  } catch (err) {
    console.log('Signing All Transactions Failed', err);
    // wallet refused to sign
    return [TxnResponse.Cancelled, []];
  }
  // }

  // Sending phase
  console.log('Transactions', txs);
  let res = TxnResponse.Success;
  const txids: string[] = [];
  for (let i = 0; i < signedTransactions.length; i++) {
    notification.open({
      placement: 'bottomLeft',
      message: `Beginning Transaction ${i + 1} of ${signedTransactions.length + 1}`,
    });
    const transaction = signedTransactions[i];

    // Transactions can be simulated against an old slot that
    // does not include previously sent transactions. In most
    // conditions only the first transaction can be simulated
    // safely
    const skipPreflightSimulation = i !== 0;
    const opts: ConfirmOptions = {
      ...provider.opts,
      skipPreflight: skipPreflightSimulation,
    };
    console.log(opts);

    const rawTransaction = transaction.serialize();
    const txid = await provider.connection.sendRawTransaction(rawTransaction, opts);
    console.log(`Transaction ${txid} ${rawTransaction.byteLength} of 1232 bytes...`);
    txids.push(txid);

    // Confirming phase
    if (!skipConfirmation) {
      const status = (await provider.connection.confirmTransaction(txid, provider.opts.commitment))
        .value;

      if (status?.err) {
        res = TxnResponse.Failed;
      }
    }
  }
  return [res, txids];
};

export const getMintInfoAndSubscribe = async function (
  connection: Connection,
  publicKey: anchor.web3.PublicKey,
  callback: (amount: TokenAmount | undefined, context: Context) => void,
  commitment?: Commitment | undefined,
): Promise<number> {
  return getAccountInfoAndSubscribe(
    connection,
    publicKey,
    (account, context) => {
      if (account != null) {
        let mintInfo = MintLayout.decode(account.data) as MintInfo;
        let amount = TokenAmount.mint(mintInfo);
        callback(amount, context);
      } else {
        callback(undefined, context);
      }
    },
    commitment,
  );
};

export interface SlopeTxn {
  msg: string;
  data: {
    publicKey?: string;
    signature?: string;
    signatures?: string[];
  };
}

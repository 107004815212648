import { BACKEND, TERRA_FCD_BASE } from './consts';
import { canonicalAddress, isNativeDenom } from '@certusone/wormhole-sdk';
import { PublicKey } from '@solana/web3.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';
import axios from 'axios';
import { ethers } from 'ethers';

// jank way to test, but if its a publik key then we assume its an address
export function isSolanaAddress(address: string): boolean {
  try {
    new PublicKey(address);
    return true;
  } catch (e) {}
  return false;
}

export const isValidTerraAddress = (address: string) => {
  if (isNativeDenom(address)) {
    return true;
  }
  try {
    const startsWithTerra = address && address.startsWith('terra');
    const isParseable = canonicalAddress(address);
    const isLength20 = isParseable.length === 20;
    return !!(startsWithTerra && isParseable && isLength20);
  } catch (error) {
    return false;
  }
};


export async function getTerraBalance(wallet: ConnectedWallet) {
  const fcd = TERRA_FCD_BASE;
  const address = wallet.walletAddress;
  const url = `${fcd}/bank/balances/${address}`;
  // an array of tokens by the user
  const result = (await axios.get(url)).data.result;
  // @ts-ignore
  const filtered = result.filter(token => token.denom === 'uluna');
  if (filtered.length !== 0) {
    // fixes decimals
    return ethers.utils.formatUnits(filtered[0].amount, 6);
  }
  return '0.0';
}

import React from 'react';
import clsx from 'clsx';
import useStore from '../../store/useStore';
import { useMediaQuery } from 'react-responsive';

function Header() {
  const user = useStore(state => state.user);

  const fixedSupplyBalance = (
    isNaN(user.position.depositedValue) ? 0 : user.position.depositedValue
  )
    .toFixed(8)
    .toString()
    .split('.');
  const fixedBorrowBalance = (isNaN(user.position.borrowedValue) ? 0 : user.position.borrowedValue)
    .toFixed(8)
    .toString()
    .split('.');

  // TODO fix
  const borrowLimit = isNaN(user.position.weightedCollateralValue - user.position.borrowedValue)
    ? 0
    : user.position.weightedCollateralValue - user.position.borrowedValue;
  const borrowLimitPercent = user.position.utilizationRate;
  const borrowLimitDollars = borrowLimit;

  const netApy = (isNaN(user.position.netApy) ? 0 : user.position.netApy).toFixed(2);

  const isShort = useMediaQuery({
    query: '(min-width: 768px)',
  });
  return (
    <div className={'bg-slate-900 pt-10 text-white'}>
      <div className={clsx('flex w-full justify-center')}>
        {isShort ? (
          <div
            style={{ width: '700px' }}
            className={clsx('flex w-full flex-row items-center  justify-between font-bold')}
          >
            <div className="flex flex-col items-center justify-center text-center">
              <p className="text-sm text-purple-400">Supply Balance</p>
              <div className="pt-2">
                <span className="text-2xl">${fixedSupplyBalance[0]}.</span>
                <span className="text-2xl text-slate-500">{fixedSupplyBalance[1]}</span>
              </div>
            </div>
            <div className="flex h-32 w-32 flex-col items-center justify-center rounded-full border-2 border-purple-400">
              <p className="text-sm text-slate-500">Net Apy</p>
              <div className="text-lg">{<div>{netApy}%</div>}</div>
            </div>
            <div className="flex flex-col items-center justify-center text-center">
              <p className="text-sm text-emerald-400">Borrow Balance</p>
              <div className="pt-2">
                <span className="text-2xl">${fixedBorrowBalance[0]}.</span>
                <span className="text-2xl text-slate-500">{fixedBorrowBalance[1]}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="mx-4 flex w-full justify-start">
            <div className="flex h-32 w-32 flex-col items-center justify-center rounded-full border-2 border-purple-400">
              <p className="text-sm text-slate-500">Net Apy</p>
              <div className="text-lg">{<div>{netApy}%</div>}</div>
            </div>
            <div className="ml-4 flex flex-col justify-between text-left font-bold">
              <p className="text-sm text-purple-400">Supply Balance</p>
              <div className="">
                <span className="text-base">${fixedSupplyBalance[0]}.</span>
                <span className="text-base text-slate-500">{fixedSupplyBalance[1]}</span>
              </div>
              <p className="text-sm text-emerald-400">Borrow Balance</p>
              <div className="">
                <span className="text-base">${fixedBorrowBalance[0]}.</span>
                <span className="text-base text-slate-500">{fixedBorrowBalance[1]}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="mx-4 flex flex-row items-center justify-center space-x-2 py-12">
          <div className="whitespace-nowrap text-slate-500">Borrow Limit</div>
          <div className="font-bold text-white">{Math.floor(borrowLimitPercent).toString()}%</div>
          <div style={{ width: '800px' }} className="flex flex-row items-center">
            <div
              style={{ width: `${borrowLimitPercent}%` }}
              className="h-1 rounded-lg bg-purple-400"
            >
              {' '}
            </div>
            <div
              style={{ width: `${100 - borrowLimitPercent}%` }}
              className="h-0.5 rounded-lg bg-slate-600"
            >
              {' '}
            </div>
          </div>
          <div className="text-slate-500">${borrowLimitDollars.toFixed(2).toString()}</div>
        </div>
      </div>
    </div>
  );
}

export default Header;

import EthereumSignerKey from './EthereumSignerKey';
// import TerraWalletKey from './TerraWalletKey';
import { ChainId, CHAIN_ID_TERRA, isEVMChain } from '@certusone/wormhole-sdk';
import TerraWalletKey from './TerraWalletKey';

function KeyAndBalance({ chainId }: { chainId: ChainId }) {
  if (isEVMChain(chainId)) {
    return (
      <>
        <EthereumSignerKey sourceChain={chainId} />
      </>
    );
  }
  if (chainId === CHAIN_ID_TERRA) {
    return <TerraWalletKey sourceChain={chainId} />;
  }
  return null;
}

export default KeyAndBalance;

import {
  calculatePostSlippageAmountSell,
  calculatePostSlippageAmountBuy,
  useOrderbook,
} from '../../hooks/useOrderboox';
import { selectSourceAmount, selectTargetAmount } from '../../store/selectors';
import { setTargetAmount } from '../../store/transferSlice';
import {
  AVAX_USDC_MARKET,
  getTokenMint,
  LUNA_USDC_MARKET,
  MATIC_USDC_MARKET,
  SOLANA_ICON,
  SOL_MINT,
  SOL_USDC_MARKET,
  WBNB_USDC_MARKET,
  WETH_SOL_MARKET,
} from '../../utils/consts';
import NumericalInput from '../NumericalInput';
import {
  ChainId,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
  CHAIN_ID_SOLANA,
  CHAIN_ID_TERRA,
} from '@certusone/wormhole-sdk';
import { CHAIN_ID_AVAX } from '@certusone/wormhole-sdk/lib/esm/utils';
// import { useJupiter } from '@jup-ag/react-hook';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function getMarket(chainId: number) {
  if (chainId === CHAIN_ID_TERRA) {
    return LUNA_USDC_MARKET;
  }
  if (chainId === CHAIN_ID_AVAX) {
    return AVAX_USDC_MARKET;
  }
  if (chainId === CHAIN_ID_POLYGON) {
    return MATIC_USDC_MARKET;
  }
  if (chainId === CHAIN_ID_BSC) {
    return WBNB_USDC_MARKET;
  }
  return WETH_SOL_MARKET;
}

type OutputPanelProps = {
  chainId: ChainId;
};

function OutputPanel({ chainId }: OutputPanelProps) {
  // const jupiter = useJupiter({
  //   amount: 0.01, // integer amount in term of input mint
  //   inputMint: getTokenMint(chainId),
  //   outputMint: SOL_MINT,
  //   slippage: 1,
  //   debounceTime: 10, // debounce time before refresh
  // });

  // const { routeMap } = jupiter;
  // console.log('route map is', routeMap);
  const market = getMarket(chainId);
  const orders = useOrderbook(market);
  const solOrders = useOrderbook(SOL_USDC_MARKET);
  const dispatch = useDispatch();

  // noop
  const onUserInput = () => {};
  const sourceValue = useSelector(selectSourceAmount);
  const outputValue = useSelector(selectTargetAmount);

  if (orders != null && solOrders != null && sourceValue !== '') {
    if (chainId === CHAIN_ID_ETH) {
      const outputAmount = calculatePostSlippageAmountSell(sourceValue, orders.bids.items(true));
      dispatch(setTargetAmount(outputAmount));
    } else if (chainId !== CHAIN_ID_SOLANA) {
      const outputAmountUsdc = calculatePostSlippageAmountSell(
        sourceValue,
        orders.bids.items(true),
      ).toString();
      const outputAmount = calculatePostSlippageAmountBuy(
        outputAmountUsdc,
        solOrders.asks.items(false),
      );
      dispatch(setTargetAmount(outputAmount));
    }
  } else if (sourceValue === '') {
    dispatch(setTargetAmount(''));
  }

  return (
    <div
      id="container"
      className={clsx(
        'rounded-lg',
        'w-full',
        'mt-1 p-4',
        'border border-transparent hover:border-slate-200 hover:shadow-inner',
        'bg-slate-50',
      )}
    >
      <div className={clsx('flex flex-row', 'items-center justify-between')}>
        <NumericalInput
          className="token-amount-input"
          value={outputValue}
          onUserInput={onUserInput}
        />
        <div
          className={clsx(
            'rounded-xl border border-slate-200 shadow-inner',
            'flex h-full items-center',
            'flex-row',
            'px-4 py-2',
          )}
        >
          <svg width="24" height="24">
            <image xlinkHref={SOLANA_ICON} width="24" height="24" />
          </svg>
          <div className="ml-2 mt-0.5">SOL</div>
        </div>
      </div>
    </div>
  );
}

export default OutputPanel;

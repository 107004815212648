import {
  ChainId,
  CHAIN_ID_AVAX,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
  CHAIN_ID_TERRA,
} from '@certusone/wormhole-sdk';

export function getBackgroundColorClass(sourceChain: ChainId, location?: string): string {
  return 'bg-slate-50';
  // switch (sourceChain) {
  //   case CHAIN_ID_ETH: {
  //     return 'bg-gradient-to-tr from-sky-500 to-fuchsia-200';
  //     // return 'bg-sky-500';
  //   }
  //   case CHAIN_ID_BSC: {
  //     return 'bg-gradient-to-tr	from-amber-600 to-purple-200';
  //     // return 'bg-purple-400';
  //   }
  //   case CHAIN_ID_POLYGON: {
  //     return 'bg-gradient-to-tr	from-violet-600 to-violet-200';
  //     // return 'bg-violet-400';
  //   }
  //   case CHAIN_ID_AVAX: {
  //     return 'bg-gradient-to-tr	from-red-600 to-red-200';
  //     // return 'bg-red-400';
  //   }
  //   case CHAIN_ID_TERRA: {
  //     return 'bg-gradient-to-tr	from-indigo-600 to-indigo-200';
  //     // return 'bg-indigo-500';
  //   }
  //   default: {
  //     return 'bg-gradient-to-tr from-solanaPurple to-solanaGreen';
  //     // return 'bg-purple-400';
  //   }
  // }
}

export function getHoverColorClassWithHover(sourceChain: ChainId): string {
  return 'hover:border-purple-400';
  switch (sourceChain) {
    case CHAIN_ID_ETH: {
      return 'hover:border-sky-500';
    }
    case CHAIN_ID_BSC: {
      return 'hover:border-purple-600';
    }
    case CHAIN_ID_POLYGON: {
      return 'hover:border-violet-400';
    }
    case CHAIN_ID_AVAX: {
      return 'hover:border-red-400';
    }
    case CHAIN_ID_TERRA: {
      return 'hover:border-indigo-500';
    }
    default: {
      return 'hover:border-purple-400';
    }
  }
}

export function getButtonColorClassName(sourceChain: ChainId, disabled: boolean): string {
  return disabled ? 'bg-purple-200' : 'bg-purple-400';
  switch (sourceChain) {
    case CHAIN_ID_ETH: {
      return disabled ? 'bg-sky-100' : 'bg-sky-400';
    }
    case CHAIN_ID_BSC: {
      return disabled ? 'bg-amber-100' : 'bg-amber-400';
    }
    case CHAIN_ID_POLYGON: {
      return disabled ? 'bg-violet-100' : 'bg-violet-400';
    }
    case CHAIN_ID_AVAX: {
      return disabled ? 'bg-red-200' : 'bg-red-500';
    }
    case CHAIN_ID_TERRA: {
      return disabled ? 'bg-indigo-100' : 'bg-indigo-400';
    }
    default: {
      return disabled ? 'bg-purple-100' : 'bg-purple-400';
    }
  }
}

export function getButtonTextColor(sourceChain: ChainId, disabled: boolean) {
  return disabled ? 'text-purple-900' : 'text-white';
  switch (sourceChain) {
    case CHAIN_ID_ETH: {
      return disabled ? 'text-sky-900' : 'text-white';
    }
    case CHAIN_ID_BSC: {
      return disabled ? 'text-amber-900' : 'text-white';
    }
    case CHAIN_ID_POLYGON: {
      return disabled ? 'text-violet-900' : 'text-white';
    }
    case CHAIN_ID_AVAX: {
      return disabled ? 'text-red-900' : 'text-white';
    }
    case CHAIN_ID_TERRA: {
      return disabled ? 'text-indigo-900' : 'text-white';
    }
    default: {
      return disabled ? 'text-purple-900' : 'text-white';
    }
  }
}

export function getHoverColorClassName(sourceChain: ChainId) {
  return 'hover:bg-purple-300';

  switch (sourceChain) {
    case CHAIN_ID_ETH: {
      return 'hover:bg-sky-200';
    }
    case CHAIN_ID_BSC: {
      return 'hover:bg-amber-200';
    }
    case CHAIN_ID_POLYGON: {
      return 'hover:bg-violet-200';
    }
    case CHAIN_ID_AVAX: {
      return 'hover:bg-red-200';
    }
    case CHAIN_ID_TERRA: {
      return 'hover:bg-indigo-200';
    }
    default: {
      return 'hover:bg-purple-200';
    }
  }
}

import React, { useMemo, useState } from 'react';

import useStore from '../../store/useStore';
import { Reserve } from '../../utils/lending/jet/JetTypes';
import { withdraw } from '../../utils/lending/jet/jet';
import clsx from 'clsx';
import NumericalInput from './NumericalInput';
import { useConnectedWallet } from '@saberhq/use-solana';
import { useWalletKit } from '@gokiprotocol/walletkit';

interface WithdrawTabProps {
  reserve: Reserve;
  icon: React.FC;
}

export default function WithdrawTab({ reserve, icon }: WithdrawTabProps) {
  const [value, setValue] = useState<string>('');

  const [user, reserves, program] = useStore(state => [state.user, state.reserves, state.program]);

  const maxWithdrawAmount = user.assets?.tokens[reserve.abbrev].maxWithdrawAmount;

  const wallet = useConnectedWallet();
  const { connect } = useWalletKit();

  const withdrawFunds = async () => {
    if (wallet == null) return;

    await withdraw(value, reserve, reserves, wallet as any, user.assets, program);
  };

  return (
    <div className="m-4">
      <div className="flex flex-row items-center justify-between py-4">
        <div className="text-base font-semibold text-slate-900">Withdraw {reserve.abbrev}</div>
        <div className="h-8 w-8 rounded-full bg-slate-200">{icon}</div>
      </div>
      <div className="my-2 flex flex-row">
        <button
          className={clsx(
            'rounded-md border p-2 text-slate-900',
            'transition duration-100 ease-in-out hover:border-slate-900',
            'mr-2',
          )}
          // TODO fixme
          onClick={() => setValue(maxWithdrawAmount?.toString() ?? '0')}
        >
          MAX
        </button>
        <NumericalInput
          value={value}
          reserve={reserve}
          placeholder="Enter an amount"
          onUserInput={evt => setValue(evt)}
        />
      </div>
      {wallet == null && (
        <button
          onClick={() => connect()}
          className={clsx(
            'my-2 w-full rounded-lg bg-slate-900 p-2 font-semibold text-white hover:bg-purple-400',
            'transition duration-300 ease-in-out',
          )}
        >
          Connect Wallet
        </button>
      )}
      {wallet != null && (
        <button
          onClick={() => value !== '' && withdrawFunds()}
          className={clsx(
            'my-2 w-full rounded-lg p-2 font-bold text-white',
            value === '' ? 'cursor-not-allowed bg-slate-900' : 'bg-purple-400 ',
          )}
        >
          {value === '' ? 'Enter amount to Withdraw' : `Withdraw ${value} ${reserve.abbrev}`}
        </button>
      )}
      <div className="mt-2 rounded-lg bg-slate-900 p-4 text-white">
        <div className="flex flex-row justify-between">
          <div className="text-slate-400">Supply Balance</div>
          <div className="font-mono text-white">
            {maxWithdrawAmount?.toFixed(2) || <>-</>}
            {/* {ownedAccounts.length === 0 ? (
              <>--</>
            ) : (
              ethers.utils.formatUnits(
                walletBalances[reserve.accounts.tokenMint.toString()]?.toString() ?? '0',
                reserve.decimals,
              )
            )} */}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-slate-400">Deposit APY</div>
          <div className="font-mono text-white">
            {' '}
            {(100 * (isNaN(reserve.depositRate) ? 0 : reserve.depositRate)).toFixed(2)} %
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEthereumProvider } from '../../contexts/EthereumProviderContext';
import ToggleConnectedButton from './ToggleConnectedButton';
import { ChainId } from '@certusone/wormhole-sdk';
import { Typography } from '@material-ui/core';
import notification from 'antd/lib/notification';
import clsx from 'clsx';

const EthereumSignerKey = ({ sourceChain }: { sourceChain: ChainId }) => {
  const { connect, disconnect, signerAddress, providerError } = useEthereumProvider();
  if (providerError) {
    notification.error({
      message: 'Ethereum Provider Issue',
      description: providerError,
      placement: 'bottomLeft',
      duration: 10,
    });
  }
  return (
    <ToggleConnectedButton
      sourceChain={sourceChain}
      connect={connect}
      disconnect={disconnect}
      connected={!!signerAddress}
      pk={signerAddress || ''}
    />
  );
};

export default EthereumSignerKey;

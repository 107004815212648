import { setTransactionState, setTransferTxHash } from '../store/transferSlice';
import {
  ETH_TOKEN_BRIDGE_ADDRESS,
  ADMIN_WBBNB_ACCOUNT_ADDRESS_PK,
  WORMHOLE_RPC_HOSTS,
  ADMIN_MATIC_ACCOUNT_ADDRESS_PK,
  POLYGON_TOKEN_BRIDGE_ADDRESS,
  AVAX_TOKEN_BRIDGE_ADDRESS,
  AVAX_BRIDGE_ADDRESS,
  POLYGON_BRIDGE_ADDRESS,
  BSC_TOKEN_BRIDGE_ADDRESS,
  ADMIN_WETH_ACCOUNT_ADDRESS_PK,
  BSC_AND_ETH_CORE_BRIDGE_ADDRESS,
  TERRA_TOKEN_BRIDGE_ADDRESS,
  ADMIN_LUNA_ACCOUNT_ADDRESS_PK,
  TERRA_HOST,
  TERRA_GAS_PRICES_URL,
  ADMIN_AVAX_ACCOUNT_ADDRESS_PK,
  TransactionState,
} from './consts';
import { waitForTerraExecution } from './terra';
import ws from './ws';
import {
  CHAIN_ID_POLYGON,
  transferFromEthNative,
  transferFromTerra,
  ChainId,
  CHAIN_ID_SOLANA,
  CHAIN_ID_ETH,
  CHAIN_ID_AVAX,
  parseSequenceFromLogEth,
  getEmitterAddressEth,
  getSignedVAA,
  CHAIN_ID_BSC,
  parseSequenceFromLogTerra,
  CHAIN_ID_TERRA,
  getEmitterAddressTerra,
} from '@certusone/wormhole-sdk';
import { PublicKey } from '@solana/web3.js';
import { LCDClient } from '@terra-money/terra.js';
import { ConnectedWallet } from '@terra-money/wallet-provider';
import notification from 'antd/lib/notification';
import axios from 'axios';
import { ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';

export let CURRENT_WORMHOLE_RPC_HOST = -1;

export const getNextRpcHost = () => ++CURRENT_WORMHOLE_RPC_HOST % WORMHOLE_RPC_HOSTS.length;

export async function getSignedVAAWithRetry(
  emitterChain: ChainId,
  emitterAddress: string,
  sequence: string,
  retryAttempts?: number,
) {
  let result;
  let attempts = 0;
  while (!result) {
    console.log('attempt number', attempts);
    attempts++;
    await new Promise(resolve => setTimeout(resolve, 1000));
    try {
      result = await getSignedVAA(
        WORMHOLE_RPC_HOSTS[getNextRpcHost()],
        emitterChain,
        emitterAddress,
        sequence,
      );
    } catch (e) {
      if (retryAttempts !== undefined && attempts > retryAttempts) {
        notification.error({
          message: 'There was an error connecting to wormhole nodes',
          description: 'You can redeem the tokens using the Wormhole recovery process',
          duration: 0,
          placement: 'bottomLeft',
        });
        throw e;
      }
    }
  }
  return result;
}

// theres 8 decimals in ethereum
export function fixEthDecimals(amount: string): ethers.BigNumber {
  return ethers.utils.parseEther(amount);
}

export async function transferToSolEVM(
  sourceChain: ChainId,
  signer: ethers.Signer,
  amount: string,
  targetAddress: string,
  dispatch: any,
) {
  console.log('started transfer to sol EVM');
  const fixedAmount = fixEthDecimals(amount);
  if (signer == null) return;
  switch (sourceChain) {
    case CHAIN_ID_BSC: {
      return transferBnbToSol(signer, fixedAmount, targetAddress, dispatch);
    }
    case CHAIN_ID_ETH: {
      return transferEthToSol(signer, fixedAmount, targetAddress, dispatch);
    }
    case CHAIN_ID_POLYGON: {
      return transferMaticToSol(signer, fixedAmount, targetAddress, dispatch);
    }
    case CHAIN_ID_AVAX: {
      return transferAvaxToSol(signer, fixedAmount, targetAddress, dispatch);
    }
  }
}

export async function transferBnbToSol(
  signer: ethers.Signer,
  amount: ethers.BigNumberish,
  userSolanaAddress: string,
  dispatch: any,
) {
  transferEthLike(
    signer,
    amount,
    userSolanaAddress,
    ADMIN_WBBNB_ACCOUNT_ADDRESS_PK,
    BSC_TOKEN_BRIDGE_ADDRESS,
    BSC_AND_ETH_CORE_BRIDGE_ADDRESS,
    CHAIN_ID_BSC,
    dispatch,
  );
}

export async function transferEthToSol(
  signer: ethers.Signer,
  amount: ethers.BigNumberish,
  userSolanaAddress: string,
  dispatch: any,
) {
  transferEthLike(
    signer,
    amount,
    userSolanaAddress,
    ADMIN_WETH_ACCOUNT_ADDRESS_PK,
    ETH_TOKEN_BRIDGE_ADDRESS,
    BSC_AND_ETH_CORE_BRIDGE_ADDRESS,
    CHAIN_ID_ETH,
    dispatch,
  );
}

export async function transferMaticToSol(
  signer: ethers.Signer,
  amount: ethers.BigNumberish,
  userSolanaAddress: string,
  dispatch: any,
) {
  transferEthLike(
    signer,
    amount,
    userSolanaAddress,
    ADMIN_MATIC_ACCOUNT_ADDRESS_PK,
    POLYGON_TOKEN_BRIDGE_ADDRESS,
    POLYGON_BRIDGE_ADDRESS,
    CHAIN_ID_POLYGON,
    dispatch,
  );
}

export async function transferAvaxToSol(
  signer: ethers.Signer,
  amount: ethers.BigNumberish,
  userSolanaAddress: string,
  dispatch: any,
) {
  return transferEthLike(
    signer,
    amount,
    userSolanaAddress,
    ADMIN_AVAX_ACCOUNT_ADDRESS_PK,
    AVAX_TOKEN_BRIDGE_ADDRESS,
    AVAX_BRIDGE_ADDRESS,
    CHAIN_ID_AVAX,
    dispatch,
  );
}

export async function transferEthLike(
  signer: ethers.Signer,
  amount: ethers.BigNumberish,
  userSolanaAddress: string,
  adminAccountPk: PublicKey,
  tokenBridgeAddress: string,
  bridgeAddress: string,
  chainId: ChainId,
  dispatch: any,
) {
  let receipt;
  try {
    receipt = await transferFromEthNative(
      tokenBridgeAddress,
      signer,
      amount,
      CHAIN_ID_SOLANA,
      adminAccountPk.toBytes(),
    );
    console.log('reciept', receipt);
  } catch (e) {
    console.log('e3', e);
    dispatch(setTransactionState(TransactionState.NONE));
  }
  if (receipt == null) return;

  console.log('receipt is', receipt);
  dispatch(setTransferTxHash(receipt.transactionHash));
  dispatch(setTransactionState(2));

  const sequence = parseSequenceFromLogEth(receipt, bridgeAddress);
  const emitterAddress = getEmitterAddressEth(tokenBridgeAddress);

  // Fetch the signedVAA from the Wormhole Network (this may require retries while you wait for confirmation)
  const response = await getSignedVAAWithRetry(chainId, emitterAddress, sequence, 2000);

  console.log(JSON.stringify(Array.from(response.vaaBytes)));

  const signedVAA = Array.from(response.vaaBytes);

  const wsMessage = {
    signedVAA,
    userSolanaAddress,
    chain: chainId,
  };

  ws.send(JSON.stringify(wsMessage));
}

export async function transferLunaToSol(
  amount: string,
  userSolanaAddress: string,
  wallet: ConnectedWallet,
  dispatch: any,
) {
  const ASSET = 'uluna';
  const LUNA_DECIMALS = 6;
  const amountParsed = parseUnits(amount, LUNA_DECIMALS).toString();

  let msgs;
  try {
    msgs = await transferFromTerra(
      wallet.terraAddress,
      TERRA_TOKEN_BRIDGE_ADDRESS,
      ASSET,
      amountParsed,
      CHAIN_ID_SOLANA,
      ADMIN_LUNA_ACCOUNT_ADDRESS_PK.toBytes(),
    );
  } catch (e) {
    dispatch(setTransactionState(TransactionState.NONE));
  }
  if (msgs == null) return;

  const lcd = new LCDClient(TERRA_HOST);
  const gasPrices = await axios.get(TERRA_GAS_PRICES_URL).then(result => result.data);
  const accountInfo = await lcd.auth.accountInfo(wallet.terraAddress);
  const blockInfo = await lcd.tendermint.blockInfo();

  const signerData = [
    {
      sequenceNumber: accountInfo.getSequenceNumber(),
      publicKey: accountInfo.getPublicKey(),
    },
  ];
  const feeEstimate = await lcd.tx.estimateFee(signerData, {
    msgs,
    memo: 'Wormhole - Initiate Transfer',
    feeDenoms: ['uluna'],
    gasPrices,
  });

  const result = await wallet.post({
    msgs,
    memo: 'Wormhole - Initiate Transfer',
    feeDenoms: ['uluna'],
    gasPrices,
    fee: feeEstimate,
  });
  dispatch(setTransactionState(1));

  const info = await waitForTerraExecution(result);
  dispatch(setTransferTxHash(info.txhash));

  const sequence = parseSequenceFromLogTerra(info);

  const emitterAddress = await getEmitterAddressTerra(TERRA_TOKEN_BRIDGE_ADDRESS);

  const { vaaBytes } = await getSignedVAAWithRetry(CHAIN_ID_TERRA, emitterAddress, sequence, 2000);

  dispatch(setTransactionState(2));

  console.log(vaaBytes);

  const signedVAA = Array.from(vaaBytes);
  const wsMessage = {
    signedVAA,
    userSolanaAddress,
    chain: CHAIN_ID_TERRA,
  };
  console.log('message', wsMessage);
  ws.send(JSON.stringify(wsMessage));
}

function getChainName(chainId: ChainId): string {
  switch (chainId) {
    case CHAIN_ID_ETH: {
      return 'Ethereum';
    }
    case CHAIN_ID_BSC: {
      return 'Bnb';
    }
    case CHAIN_ID_POLYGON: {
      return 'Matic';
    }
    case CHAIN_ID_AVAX: {
      return 'AVAX';
    }
    case CHAIN_ID_TERRA: {
      return 'Luna';
    }
    default: {
      return 'Solana';
    }
  }
}

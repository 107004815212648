import {
  CHAIN_ID_AVAX,
  CHAIN_ID_TERRA,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
  CHAIN_ID_SOLANA,
} from '@certusone/wormhole-sdk';
import { useLocation } from 'react-router-dom';

function urlToChain() {
  if (window == null) return CHAIN_ID_SOLANA;
  const url = window.location.href.toLowerCase();
  const pathname = window.location.pathname.toLowerCase();
  switch (pathname) {
    case '/exchange/avax': {
      return CHAIN_ID_AVAX;
    }
    case '/exchange/eth': {
      return CHAIN_ID_ETH;
    }
    case '/exchange/matic': {
      return CHAIN_ID_POLYGON;
    }
    case '/exchange/terra': {
      return CHAIN_ID_TERRA;
    }
    case '/exchange/bsc': {
      return CHAIN_ID_BSC;
    }
  }
  if (url.includes('bnb')) {
    return CHAIN_ID_BSC;
  }
  if (url.includes('eth')) {
    return CHAIN_ID_ETH;
  }
  if (url.includes('polygon') || url.includes('matic')) {
    return CHAIN_ID_POLYGON;
  }
  if (url.includes('terra') || url.includes('luna')) {
    return CHAIN_ID_TERRA;
  }
  if (url.includes('avax') || url.includes('avalance')) {
    return CHAIN_ID_AVAX;
  }
  return CHAIN_ID_ETH;
}

export default urlToChain;

import { ChainInfo } from '../../utils/consts';
import ChainSelect from './ChainSelect';
import KeyAndBalance from './KeyAndBalance';
import {
  ChainId,
  CHAIN_ID_AVAX,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
} from '@certusone/wormhole-sdk';
import { CHAIN_ID_TERRA } from '@certusone/wormhole-sdk/lib/esm/utils';
import clsx from 'clsx';
import React from 'react';

function getIconColor(sourceChain: ChainId) {
  switch (sourceChain) {
    case CHAIN_ID_ETH: {
      return 'text-sky-400';
    }
    case CHAIN_ID_BSC: {
      return 'text-amber-400';
    }
    case CHAIN_ID_POLYGON: {
      return 'text-violet-400';
    }
    case CHAIN_ID_AVAX: {
      return 'text-red-400';
    }
    case CHAIN_ID_TERRA: {
      return 'text-indigo-400';
    }
  }
}
type SwapHeaderProps = {
  onChange: any;
  sourceChain: ChainId;
  chains: ChainInfo[];
};
function SwapHeader({ onChange, sourceChain, chains }: SwapHeaderProps) {
  // const textColor = getIconColor(sourceChain);
  return (
    <div className={clsx('flex flex-row-reverse items-center justify-between px-5 py-4')}>
      <ChainSelect onChange={onChange} value={sourceChain} chains={chains} />{' '}
      <KeyAndBalance chainId={sourceChain} />
    </div>
  );
}

export default SwapHeader;

import clsx from 'clsx';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function IconRoutes() {
  const location = useLocation();
  const isLending = location.pathname === '/lending';
  const textColor = 'text-purple-400';
  // const textColor = 'text-white';
  return (
    <>
      <div className={clsx('max-w-md', 'mt-6 w-full font-bold md:mt-0')}>
        <div
          className={clsx(
            'text-md flex flex-row justify-end',
            'transition-none',
            'justify-between',
            'pr-5 pl-5',
            'text-base',
          )}
        >
          {[
            {
              route: '/',
              text: 'Lending',
            },
            {
              route: '/exchange',
              text: 'Exchange',
            },
          ].map(({ route, text }) => (
            <Link key={route} to={route}>
              <div
                className={clsx(
                  'py-2',
                  'border border-r-0 border-l-0 border-t-0 border-purple-400',
                  route === location.pathname || (location.pathname !== '/' && route !== '/')
                    ? 'border-b-1 text-purple-400'
                    : 'border-b-0 text-white',
                  `transition-none hover:${textColor}`,
                )}
              >
                <div className={clsx('flex flex-row items-center justify-center')}>
                  {/* <Icon className={clsx('mr-2 h-3 w-3')} />  */}
                  {text}
                </div>
              </div>
            </Link>
          ))}
          {[
            {
              href: 'https://discord.gg/gJJyxzpyaA',
              text: 'Discord',
            },
            {
              href: 'https://twitter.com/CitrusExchange',
              text: 'Twitter',
            },
            { href: 'https://citrusapp.gitbook.io/citrus-protocol/', text: 'Docs' },
          ].map(({ href, text }) => (
            <div key={href}>
              <a target="_blank" className={clsx(`transition-none hover:${textColor}`)} href={href}>
                <div
                  className={clsx(
                    'flex flex-row items-center justify-center py-2 text-white hover:text-purple-400',
                  )}
                >
                  {/* <Icon className={clsx('mx-auto hover:opacity-80', 'mr-2 h-3 w-3')} /> */}
                  {text}
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default IconRoutes;

import { JetMarket, ReserveDexMarketAccounts } from '@jet-lab/jet-engine';
import { BN, Wallet, Program } from '@project-serum/anchor';
import { NATIVE_MINT } from '@solana/spl-token';
import { Connection } from '@solana/web3.js';
import produce from 'immer';
import { OwnedAccount, Store } from '../../store/useStore';
import { SOL_DECIMALS } from './jet/jet';
import { Asset, AssetStore, CitrusReserve, Reserve, ReserveMetadata } from './jet/JetTypes';
import { MarketReserveInfoList } from './jet/layout';
import { parsePriceData } from '@pythnetwork/client';

import {
  getAccountInfoAndSubscribe,
  getBorrowRate,
  getCcRate,
  getDepositRate,
  getMintInfoAndSubscribe,
  getTokenAccountAndSubscribe,
  parseMarketAccount,
  parseObligationAccount,
  parseReserveAccount,
} from './jet/programUtil';
import { TokenAmount } from './jet/tokenAmount';
// import { TokenAmount } from './jet/tokenAmount';
import { ANCHOR_CODER } from '../consts';
import { CITRUS_MARKET_PUBLICKEY, MARKET_MIN_C_RATIO, MIN_C_RATIO } from './consts';
// but what if they werent...
export const subscribeToMarket = async (
  program: Program,
  setStore: (fn: (store: Store) => void) => void,
  reserves: ReserveMetadata[],
) => {
  let promise: Promise<number>;
  const promises: Promise<number>[] = [];

  const connection = program.provider.connection;

  // Market subscription
  let timeStart = Date.now();
  promise = getAccountInfoAndSubscribe(connection, CITRUS_MARKET_PUBLICKEY, account => {
    if (account != null) {
      console.assert(MarketReserveInfoList.span == 12288);
      const coder = program.coder;
      const decoded = parseMarketAccount(account.data, coder);
      setStore(state => {
        let reservesMut = state.reserves;

        for (const reserveStruct of decoded.reserves) {
          reservesMut = reservesMut.map(reserve => {
            if (reserve.accountPubkey.equals(reserveStruct.reserve)) {
              return {
                ...reserve,
                liquidationPremium: reserveStruct.liquidationBonus,
                depositNoteExchangeRate: reserveStruct.depositNoteExchangeRate,
                loanNoteExchangeRate: reserveStruct.loanNoteExchangeRate,
              };
            }
            return reserve;
          });
        }
        state.reserves = reservesMut;
      });
    }
  });
  promises.push(promise);
  // Set ping of RPC call
  // promise.then(() => {
  //   let timeEnd = Date.now();
  //   USER.update(user => {
  //     user.rpcPing = timeEnd - timeStart;
  //     return user;
  //   });
  // });
  // promises.push(promise);

  reserves.forEach(reserve => {
    //   // Reserve
    promise = getAccountInfoAndSubscribe(connection, reserve.accounts.reserve, account => {
      if (account != null) {
        const coder = program.coder;
        const decoded = parseReserveAccount(account.data, coder);

        setStore(state => {
          // let reservesMut = state.reserves;

          const reservesMut = state.reserves.map(r => {
            if (r.accountPubkey.equals(reserve.accounts.reserve)) {
              return {
                ...r,
                maximumLTV: decoded.config.minCollateralRatio,
                liquidationPremium: decoded.config.liquidationPremium,
                outstandingDebt: new TokenAmount(decoded.state.outstandingDebt, r.decimals).divb(
                  new BN(Math.pow(10, 15)),
                ),
                accruedUntil: decoded.state.accruedUntil,
                config: decoded.config,
              };
            }
            return r;
          });

          state.reserves = reservesMut;
        });
      }
    });
    promises.push(promise);
    //   // Deposit Note Mint
    promise = getMintInfoAndSubscribe(connection, reserve.accounts.depositNoteMint, amount => {
      if (amount != null) {
        setStore(state => {
          // let reservesMut = state.reserves;

          const reservesMut = state.reserves.map(r => {
            if (r.accountPubkey.equals(reserve.accounts.reserve)) {
              return {
                ...r,
                depositNoteMint: amount,
              };
            }
            return r;
          });

          state.reserves = reservesMut;
        });
        deriveValues(reserve.abbrev, setStore);
      }
    });
    promises.push(promise);
    //   // Loan Note Mint
    promise = getMintInfoAndSubscribe(connection, reserve.accounts.loanNoteMint, amount => {
      if (amount != null) {
        setStore(state => {
          const reservesMut = state.reserves.map(r => {
            if (r.accountPubkey.equals(reserve.accounts.reserve)) {
              return {
                ...r,
                loanNoteMint: amount,
              };
            }
            return r;
          });

          state.reserves = reservesMut;
        });
        deriveValues(reserve.abbrev, setStore);
      }
    });
    promises.push(promise);
    // Reserve Vault
    promise = getTokenAccountAndSubscribe(
      connection,
      reserve.accounts.vault,
      reserve.decimals,
      amount => {
        if (amount != null) {
          setStore(state => {
            const reservesMut = state.reserves.map(r => {
              if (r.accountPubkey.equals(reserve.accounts.reserve)) {
                return {
                  ...r,
                  availableLiquidity: amount,
                };
              }
              return r;
            });

            state.reserves = reservesMut;
          });
          deriveValues(reserve.abbrev, setStore);
        }
      },
    );
    promises.push(promise);
    // Reserve Token Mint
    promise = getMintInfoAndSubscribe(connection, reserve.accounts.tokenMint, amount => {
      if (amount != null) {
        setStore(state => {
          const reservesMut = state.reserves.map(r => {
            if (r.accountPubkey.equals(reserve.accounts.reserve)) {
              return {
                ...r,
                tokenMint: amount,
              };
            }
            return r;
          });

          state.reserves = reservesMut;
        });
        deriveValues(reserve.abbrev, setStore);
      }
    });
    promises.push(promise);
    // Pyth Price
    promise = getAccountInfoAndSubscribe(connection, reserve.accounts.pythPrice, account => {
      if (account != null) {
        setStore(state => {
          if (parsePriceData(account.data).price) {
            const reservesMut = state.reserves.map(r => {
              if (r.accountPubkey.equals(reserve.accounts.reserve)) {
                return {
                  ...r,
                  price: parsePriceData(account.data).price || 0,
                };
              }
              return r;
            });

            state.reserves = reservesMut;
          }
        });
      }
      deriveValues(reserve.abbrev, setStore);
    });
    promises.push(promise);
    // }
  });

  return Promise.all(promises);
};

export const subscribeToAssets = async (
  wallet: Wallet,
  assetStore: AssetStore,
  program: Program,
  setStore: (fn: (store: Store) => void) => void,
  reserves: ReserveMetadata[],
) => {
  let promise: Promise<number>;
  const promises: Promise<number>[] = [];
  const connection = program.provider.connection;
  const coder = program.coder;
  // const coder =
  // Obligation
  promise = getAccountInfoAndSubscribe(
    connection,
    assetStore.obligationPubkey,
    account => {
      if (account != null) {
        setStore(state => {
          if (state.user.assets != null) {
            state.user.assets = {
              ...state.user.assets,
              obligation: {
                ...account,
                data: parseObligationAccount(account.data, coder),
              },
            };
          }
        });
      }
    },
    'processed',
  );
  promises.push(promise);

  // Wallet native SOL balance
  promise = getAccountInfoAndSubscribe(connection, wallet.publicKey, account => {
    // Need to be careful constructing a BN from a number.
    // If the user has more than 2^53 lamports it will throw for not having enough precision.
    setStore(state => {
      if (state.user.assets != null) {
        state.user.assets.tokens.SOL.walletTokenBalance = new TokenAmount(
          new BN(account?.lamports.toString() ?? 0),
          SOL_DECIMALS,
        );
        state.user.walletBalances.SOL =
          state.user.assets.tokens.SOL.walletTokenBalance.uiAmountFloat;
      }
    });
    deriveValues('SOL', setStore);
  });
  promises.push(promise);

  Object.entries(assetStore.tokens).forEach(([abbrev, asset]) => {
    const reserve = reserves.find(r => r.accounts.tokenMint.equals(asset.tokenMintPubkey));
    if (reserve == null) return;
    // Wallet token account
    promise = getTokenAccountAndSubscribe(
      connection,
      asset.walletTokenPubkey,
      reserve.decimals,
      amount => {
        setStore(state => {
          if (state.user.assets != null) {
            state.user.assets.tokens[abbrev].walletTokenBalance =
              amount ?? new TokenAmount(new BN(0), reserve.decimals);
            state.user.assets.tokens[abbrev].walletTokenExists = !!amount;
            if (!asset.tokenMintPubkey.equals(NATIVE_MINT)) {
              state.user.walletBalances[reserve.abbrev] = amount?.uiAmountFloat ?? 0;
            }
          }
        });
        deriveValues(reserve.abbrev, setStore);
      },
    );
    promises.push(promise);
    // Reserve deposit notes
    promise = getTokenAccountAndSubscribe(
      connection,
      asset.depositNoteDestPubkey,
      reserve.decimals,
      (amount, context) => {
        setStore(state => {
          if (state.user.assets) {
            state.user.assets.tokens[abbrev].depositNoteDestBalance =
              amount ?? TokenAmount.zero(reserve.decimals);
            state.user.assets.tokens[abbrev].depositNoteDestExists = !!amount;
            const user = state.user;
          }
        });
        deriveValues(reserve.abbrev, setStore);
      },
    );
    promises.push(promise);
    // Deposit notes account
    promise = getTokenAccountAndSubscribe(
      connection,
      asset.depositNotePubkey,
      reserve.decimals,
      amount => {
        setStore(state => {
          if (state.user.assets) {
            state.user.assets.tokens[reserve.abbrev].depositNoteBalance =
              amount ?? TokenAmount.zero(reserve.decimals);
            state.user.assets.tokens[reserve.abbrev].depositNoteExists = !!amount;
          }
        });
        deriveValues(reserve.abbrev, setStore);
      },
    );
    promises.push(promise);
    // Obligation loan notes
    promise = getTokenAccountAndSubscribe(
      connection,
      asset.loanNotePubkey,
      reserve.decimals,
      amount => {
        setStore(state => {
          if (state.user.assets) {
            state.user.assets.tokens[reserve.abbrev].loanNoteBalance =
              amount ?? TokenAmount.zero(reserve.decimals);
            state.user.assets.tokens[reserve.abbrev].loanNoteExists = !!amount;

            const user = state.user;
          }
        });
        deriveValues(reserve.abbrev, setStore);
      },
    );
    promises.push(promise);
    // Obligation collateral notes
    promise = getTokenAccountAndSubscribe(
      connection,
      asset.collateralNotePubkey,
      reserve.decimals,
      amount => {
        setStore(state => {
          if (state.user.assets && amount != null) {
            state.user.assets.tokens[reserve.abbrev].collateralNoteBalance =
              amount ?? TokenAmount.zero(reserve.decimals);
            state.user.assets.tokens[reserve.abbrev].collateralNoteExists = !!amount;
          }
        });
        deriveValues(reserve.abbrev, setStore);
      },
    );
    promises.push(promise);
  });

  return Promise.all(promises);
};

const deriveValues = (abbrev: string, setStore: (fn: (store: Store) => void) => void) => {
  setStore(state => {
    const user = state.user;
    const reserves = state.reserves;
    const reserve = state.reserves.find(r => r.abbrev === abbrev)!;
    const index = state.reserves.findIndex(r => r.abbrev === abbrev)!;

    reserve.marketSize = reserve.outstandingDebt.add(reserve.availableLiquidity);

    reserve.utilizationRate = reserve.marketSize.isZero()
      ? 0
      : reserve.outstandingDebt.uiAmountFloat / reserve.marketSize.uiAmountFloat;
    // @ts-ignore
    const ccRate = getCcRate(reserve.config, reserve.utilizationRate);
    reserve.borrowRate = getBorrowRate(ccRate, reserve.config.manageFeeRate);
    reserve.depositRate = getDepositRate(ccRate, reserve.utilizationRate);

    reserves[index] = reserve;
    state.reserves = reserves;

    const asset = user.assets?.tokens[abbrev];

    if (asset != null) {
      asset.depositBalance = asset.depositNoteBalance
        .mulb(reserve.depositNoteExchangeRate)
        .divb(new BN(Math.pow(10, 15)));
      asset.loanBalance = asset.loanNoteBalance
        .mulb(reserve.loanNoteExchangeRate)
        .divb(new BN(Math.pow(10, 15)));
      asset.collateralBalance = asset.collateralNoteBalance
        .mulb(reserve.depositNoteExchangeRate)
        .divb(new BN(Math.pow(10, 15)));

      if (user == null) return;
      // console.log(
      //   'info',
      //   reserve.abbrev,
      //   asset.collateralNoteBalance,
      //   reserve.depositNoteExchangeRate.toString(),
      //   reserve.price,
      // );
      user.collateralBalances[reserve.abbrev] = asset.collateralBalance?.uiAmountFloat ?? 0;
      user.loanBalances[reserve.abbrev] = asset.loanBalance?.uiAmountFloat ?? 0;

      // console.log('assetMut :>> ', assetMut);
      const userPosition = {
        depositedValue: 0,
        borrowedValue: 0,
        weightedCollateralValue: 0,
        colRatio: 0,
        netApy: 0,
        utilizationRate: 0,
      };

      let netChange = 0;

      //update user positions
      for (const ab in user.assets?.tokens) {
        const currentRes = state.reserves.find(res => res.abbrev === ab);
        if (currentRes == null) continue;
        const ltv = 10_000 / currentRes.config.minCollateralRatio;
        userPosition.depositedValue += user.collateralBalances[ab] * (currentRes?.price ?? 0);
        userPosition.weightedCollateralValue +=
          user.collateralBalances[ab] * (currentRes?.price ?? 0) * ltv;
        userPosition.borrowedValue += user.loanBalances[ab] * (currentRes?.price ?? 0);
        userPosition.colRatio = userPosition.borrowedValue
          ? userPosition.depositedValue / userPosition.borrowedValue
          : 0;
        userPosition.utilizationRate = userPosition.depositedValue
          ? userPosition.borrowedValue / userPosition.depositedValue
          : 0;

        netChange +=
          user.collateralBalances[ab] * (currentRes?.depositRate ?? 0) * (currentRes?.price ?? 0);
        netChange -=
          user.loanBalances[ab] * (currentRes?.borrowRate ?? 0) * (currentRes?.price ?? 0);
      }

      userPosition.netApy =
        ((userPosition.depositedValue + netChange) * 100) / userPosition.depositedValue - 100;

      asset.maxDepositAmount = user.walletBalances[reserve.abbrev];

      // Max withdraw
      asset.maxWithdrawAmount = userPosition.borrowedValue
        ? (userPosition.depositedValue - MIN_C_RATIO * userPosition.borrowedValue) / reserve.price
        : asset.collateralBalance.uiAmountFloat;
      if (asset.maxWithdrawAmount > asset.collateralBalance.uiAmountFloat) {
        asset.maxWithdrawAmount = asset.collateralBalance.uiAmountFloat;
      }

      // Max borrow
      asset.maxBorrowAmount =
        (userPosition.depositedValue / MARKET_MIN_C_RATIO - userPosition.borrowedValue) /
        reserve.price;
      if (asset.maxBorrowAmount > reserve.availableLiquidity.uiAmountFloat) {
        asset.maxBorrowAmount = reserve.availableLiquidity.uiAmountFloat;
      }

      // Max repay
      if (user.walletBalances[reserve.abbrev] < asset.loanBalance.uiAmountFloat) {
        asset.maxRepayAmount = user.walletBalances[reserve.abbrev];
      } else {
        asset.maxRepayAmount = asset.loanBalance.uiAmountFloat;
      }
      user.position = userPosition;
      if (user.assets?.tokens != null) {
        user.assets.tokens[abbrev] = asset;
      }

      state.user = user;
    }
  });
};

import { useEthereumProvider } from '../contexts/EthereumProviderContext';
import { CLUSTER, getEvmChainId } from '../utils/consts';
import {
  ChainId,
  CHAIN_ID_TERRA,
  isEVMChain,
} from '@certusone/wormhole-sdk';
import { hexlify, hexStripZeros } from '@ethersproject/bytes';
import { useConnectedWallet } from '@terra-money/wallet-provider';
import { useCallback, useMemo } from 'react';

const createWalletStatus = (
  isReady: boolean,
  statusMessage: string = '',
  forceNetworkSwitch: () => void,
  walletAddress?: string,
) => ({
  isReady,
  statusMessage,
  forceNetworkSwitch,
  walletAddress,
});

function useIsWalletReady(
  chainId: ChainId,
  enableNetworkAutoswitch: boolean = true,
): {
  isReady: boolean;
  statusMessage: string;
  walletAddress?: string;
  forceNetworkSwitch: () => void;
} {
  const autoSwitch = enableNetworkAutoswitch;
  const terraWallet = useConnectedWallet();
  const hasTerraWallet = !!terraWallet;
  const {
    provider,
    signerAddress,
    chainId: evmChainId,
  } = useEthereumProvider();
  const hasEthInfo = !!provider && !!signerAddress;
  const correctEvmNetwork = getEvmChainId(chainId);
  const hasCorrectEvmNetwork = evmChainId === correctEvmNetwork;
  const forceNetworkSwitch = useCallback(() => {
    if (provider && correctEvmNetwork) {
      if (!isEVMChain(chainId)) {
        return;
      }
      try {
        provider.send('wallet_switchEthereumChain', [
          { chainId: hexStripZeros(hexlify(correctEvmNetwork)) },
        ]);
      } catch (e) {}
    }
  }, [provider, correctEvmNetwork, chainId]);

  return useMemo(() => {
    if (
      chainId === CHAIN_ID_TERRA &&
      hasTerraWallet &&
      terraWallet?.walletAddress
    ) {
      // TODO: terraWallet does not update on wallet changes
      return createWalletStatus(
        true,
        undefined,
        forceNetworkSwitch,
        terraWallet.walletAddress,
      );
    }
    if (isEVMChain(chainId) && hasEthInfo && signerAddress) {
      if (hasCorrectEvmNetwork) {
        return createWalletStatus(
          true,
          undefined,
          forceNetworkSwitch,
          signerAddress,
        );
      } else {
        if (provider && correctEvmNetwork && autoSwitch) {
          forceNetworkSwitch();
        }
        return createWalletStatus(
          false,
          `Wallet is not connected to ${CLUSTER}. Expected Chain ID: ${correctEvmNetwork}`,
          forceNetworkSwitch,
          undefined,
        );
      }
    }

    return createWalletStatus(
      false,
      'Wallet not connected',
      forceNetworkSwitch,
      undefined,
    );
  }, [
    chainId,
    autoSwitch,
    forceNetworkSwitch,
    hasTerraWallet,
    hasEthInfo,
    correctEvmNetwork,
    hasCorrectEvmNetwork,
    provider,
    signerAddress,
    terraWallet,
  ]);
}

export default useIsWalletReady;

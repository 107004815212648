// TODO: replace this whole file with something more modern. This is all copied
//       from sollet.

// @ts-ignore
import * as BufferLayout from 'buffer-layout';
import { BN } from '@project-serum/anchor';
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  AccountInfo as TokenAccount,
} from '@solana/spl-token';
import { Connection, PublicKey } from '@solana/web3.js';
//@ts-ignore
import * as bs58 from 'bs58';
import { AssetStore } from './jet/JetTypes';

export const getTokenAccounts = async (connection: Connection, publicKey: PublicKey) => {
  let filters = getOwnedAccountsFilters(publicKey);
  let res = await connection.getProgramAccounts(TOKEN_PROGRAM_ID, {
    commitment: connection.commitment,
    filters,
  });
  return res
    .map(({ pubkey, account: { data, executable, owner, lamports } }: any) => ({
      publicKey: new PublicKey(pubkey),
      accountInfo: {
        data,
        executable,
        owner: new PublicKey(owner),
        lamports,
      },
    }))
    .map(({ publicKey, accountInfo }: any) => {
      return { publicKey, account: parseTokenAccountData(accountInfo.data) };
    });
};

export const getTokenAccountFromAll = async (
  connection: Connection,
  owner: PublicKey,
  accToFind: PublicKey,
) => {
  const { account } = await getTokenAccounts(connection, owner).then(
    accs => accs.find(a => a.publicKey.equals(accToFind)!)!,
  );
  return account;
};

export async function getSolBalance(connection: Connection, publicKey: PublicKey) {
  const accountInfo = await connection.getAccountInfo(publicKey)!;
  console.log('account info', accountInfo);
  // const balance = await connection.getBalance(publicKey);
  return accountInfo;
}

export async function getOwnedAssociatedTokenAccounts(
  connection: Connection,
  publicKey: PublicKey,
): Promise<Array<{ publicKey: any; account: TokenAccount }>> {
  try {
    const accs = await getTokenAccounts(connection, publicKey);
    // @ts-expect-error
    return (
      (
        await Promise.all(
          accs
            // @ts-ignore
            .map(async ta => {
              const ata = await Token.getAssociatedTokenAddress(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                ta.account.mint,
                publicKey,
              );
              return [ta, ata];
            }),
        )
      )
        // @ts-ignore
        .filter(([ta, ata]) => ta.publicKey.equals(ata))
        // @ts-ignore
        .map(([ta]) => ta)
    );
  } catch (err) {
    console.log(err);
    return [];
  }
}

const ACCOUNT_LAYOUT = BufferLayout.struct([
  BufferLayout.blob(32, 'mint'),
  BufferLayout.blob(32, 'owner'),
  BufferLayout.nu64('amount'),
  BufferLayout.blob(93),
]);

export function parseTokenAccountData(data: Buffer): TokenAccount {
  // @ts-ignore
  let { mint, owner, amount } = ACCOUNT_LAYOUT.decode(data);
  // console.log('lll', ACCOUNT_LAYOUT.decode(data));
  // @ts-ignore
  return {
    mint: new PublicKey(mint),
    owner: new PublicKey(owner),
    // https://github.com/indutny/bn.js/issues/218
    amount: new BN(String(amount)),
  };
}

function getOwnedAccountsFilters(publicKey: PublicKey) {
  return [
    {
      memcmp: {
        offset: ACCOUNT_LAYOUT.offsetOf('owner'),
        bytes: publicKey.toBase58(),
      },
    },
    {
      dataSize: ACCOUNT_LAYOUT.span,
    },
  ];
}

export function parseAssetStore(assetStore: AssetStore) {
  console.log('started', assetStore);
}

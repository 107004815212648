import React, { useState } from 'react';

import useStore from '../../store/useStore';
import { Reserve } from '../../utils/lending/jet/JetTypes';
import { repay } from '../../utils/lending/jet/jet';
import clsx from 'clsx';
import NumericalInput from './NumericalInput';
import { useConnectedWallet } from '@saberhq/use-solana';
import { useWalletKit } from '@gokiprotocol/walletkit';
import { ethers } from 'ethers';

interface RepayTabProps {
  reserve: Reserve;
  icon: React.FC;
}

export default function RepayModalTab({ reserve, icon }: RepayTabProps) {
  const [value, setValue] = useState<string>('');
  const [user, program] = useStore(state => [state.user, state.program]);

  const wallet = useConnectedWallet();
  const { connect } = useWalletKit();

  const youOwe = user.assets?.tokens[reserve.abbrev].maxRepayAmount.toString();

  const repayFunds = async () => {
    if (wallet == null) return;

    await repay(value, reserve, wallet as any, program, user.assets);
  };

  return (
    <div className="w-full">
      <div className="m-4">
        <div className="flex flex-row items-center justify-between py-4">
          <div className="text-base font-semibold text-slate-900">Repay {reserve.abbrev}</div>
          <div className="h-8 w-8 rounded-full bg-slate-200">{icon}</div>
        </div>
        <div className="my-2 flex flex-row">
          <button
            className={clsx(
              'rounded-md border p-2 text-slate-900',
              'transition duration-100 ease-in-out hover:border-slate-900',
              'mr-2',
            )}
            onClick={() => setValue(youOwe ?? '0')}
          >
            MAX
          </button>
          <NumericalInput
            reserve={reserve}
            value={value}
            placeholder="Enter an amount"
            onUserInput={evt => setValue(evt)}
          />
        </div>
        {wallet == null && (
          <button
            onClick={() => connect()}
            className={clsx(
              'my-2 w-full rounded-lg bg-slate-900 p-2 font-semibold text-white hover:bg-purple-400',
              'transition duration-300 ease-in-out',
            )}
          >
            Connect Wallet
          </button>
        )}
        {wallet != null && (
          <button
            onClick={() => value !== '' && repayFunds()}
            className={clsx(
              'my-2 w-full rounded-lg p-2 font-bold text-white',
              value === '' ? 'cursor-not-allowed bg-slate-900' : 'bg-purple-400 ',
            )}
          >
            {value === '' ? 'Enter amount to Repay' : `Repay ${value} ${reserve.abbrev}`}
          </button>
        )}
        <div className="mt-2 rounded-lg bg-slate-900 p-4 text-white">
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">You Owe</div>
            <div className="font-mono text-white">{youOwe ?? <div>-</div>}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">Wallet Balance</div>
            <div className="font-mono text-white">
              {user.walletBalances[reserve.abbrev] == null ? (
                <>-</>
              ) : (
                user.walletBalances[reserve.abbrev]
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">Borrow APR</div>
            <div className="font-mono text-white">
              {' '}
              {(100 * (isNaN(reserve.borrowRate) ? 0 : reserve.borrowRate)).toFixed(2)} %
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

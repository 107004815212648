import { PublicKey } from '@solana/web3.js';

export const CITRUS_LENDING_PROGRAM_ID = new PublicKey(
  'J5oCcjjE2KmNynhSwjLbHzEVtbT2e6obZMWXcoymFj61',
);

export const CITRUS_MARKET_PUBLICKEY = new PublicKey(
  'AMfVYhnqjbK4jdMRLNfcMDDwSsrzZQr9Qr4i1hT2nSk5',
);

export const CITRUS_MARKET_AUTHORITY_PUBKEY = new PublicKey(
  'EG9oNbdvvvK1UDEkj8M6R5MNvCDjGhxhdujtNmnM43eG',
);

export const MIN_C_RATIO = 1.25;

// Hardcode minimum c-ratio to 130% for now
// for withdraws and shit
export const MARKET_MIN_C_RATIO = 1.3;

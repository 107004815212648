import { useWalletKit } from '@gokiprotocol/walletkit';
import { useConnectedWallet } from '@saberhq/use-solana';
import clsx from 'clsx';
import React, { useState } from 'react';

import useStore from '../../store/useStore';
import { MIN_C_RATIO } from '../../utils/lending/consts';
import { getBorrowPercentage } from '../../utils/lending/getBorrowLimit';
import { borrow } from '../../utils/lending/jet/jet';
import { Reserve } from '../../utils/lending/jet/JetTypes';
import NumericalInput from './NumericalInput';

interface BorrowtTabProps {
  reserve: Reserve;
  icon: React.FC;
}

export default function BorrowtTab({ reserve, icon }: BorrowtTabProps) {
  const [value, setValue] = useState<string>('');
  const [user, reserves, program, setStore] = useStore(state => [
    state.user,
    state.reserves,
    state.program,
    state.set,
  ]);

  const wallet = useConnectedWallet();
  const { connect } = useWalletKit();

  const borrowFunds = () => {
    if (wallet == null) return;

    borrow(value, reserve, reserves, wallet as any, user.assets, program, setStore);
  };

  // TODO hardcoded constant for lending. In the future, should raise this constant and allow users to willingfully enter unhealthy positions.
  const borrowLimit = isNaN(user.position.weightedCollateralValue - user.position.borrowedValue)
    ? 0
    : (user.position.weightedCollateralValue - user.position.borrowedValue) * 0.8;
  // hardcode because don't want to literally fuck users
  // const maxBorrowLimit = Math.max(0, borrowLimit - 0.5);

  const maxBorrowTokens = Math.min(
    borrowLimit / (reserve.price ?? 1),
    reserve.availableLiquidity.uiAmountFloat - 0.01,
  );
  const currentBorrowPercent = user.position.utilizationRate;
  const newBorrowPercent = getBorrowPercentage(user.position, reserve, value);

  return (
    <div className="w-full">
      <div className="m-4">
        <div className="flex flex-row items-center justify-between py-4">
          <div className="text-base font-semibold text-slate-900">Borrow {reserve.abbrev}</div>
          <div className="h-8 w-8 rounded-full bg-slate-200">{icon}</div>
        </div>
        <div className="my-2 flex flex-row">
          <button
            className={clsx(
              'rounded-md border p-2 text-slate-900',
              'transition duration-100 ease-in-out hover:border-slate-900',
              'mr-2',
            )}
            onClick={() => setValue(maxBorrowTokens.toFixed(2).toString())}
          >
            MAX
          </button>
          <NumericalInput
            reserve={reserve}
            value={value}
            placeholder="Enter an amount"
            onUserInput={evt => setValue(evt)}
          />
        </div>
        {wallet == null && (
          <button
            onClick={() => connect()}
            className={clsx(
              'my-2 w-full rounded-lg bg-slate-900 p-2 font-semibold text-white hover:bg-purple-400',
              'transition duration-300 ease-in-out',
            )}
          >
            Connect Wallet
          </button>
        )}
        {wallet != null && (
          <button
            onClick={() => value !== '' && borrowFunds()}
            className={clsx(
              'my-2 w-full rounded-lg p-2 font-bold text-white',
              value === '' ? 'cursor-not-allowed bg-slate-900' : 'bg-purple-400 ',
              newBorrowPercent > 80 && 'bg-red-500',
            )}
          >
            {value === '' ? 'Enter amount to Borrow' : `Borrow ${value} ${reserve.abbrev}`}
          </button>
        )}
        <div className="mt-2 rounded-lg bg-slate-900 p-4 text-white">
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">Borrow Limit</div>
            <div className="font-mono text-white">${borrowLimit.toFixed(2)}</div>
          </div>{' '}
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">Used Borrow Limit</div>
            <div className="font-mono text-white">
              {currentBorrowPercent.toFixed(1)} % ▶{' '}
              {(isNaN(newBorrowPercent) ? 0 : newBorrowPercent).toFixed(1)} %
            </div>
          </div>{' '}
          <div className="flex flex-row justify-between">
            <div className="text-slate-400">Borrow APR</div>
            <div className="font-mono text-white">
              {' '}
              {(100 * (isNaN(reserve.borrowRate) ? 0 : reserve.borrowRate)).toFixed(2)} % %
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import useStore from '../../../store/useStore';
import { getOwnedAssociatedTokenAccounts } from '../tokens';
import { JET_USDC_FAUCET_PUBKEY, JET_USDC_MINT, WSOL_MINT } from './pubkeys';
import * as anchor from '@project-serum/anchor';
import { BN, Provider, Wallet } from '@project-serum/anchor';
import { ASSOCIATED_TOKEN_PROGRAM_ID, NATIVE_MINT } from '@solana/spl-token';
import {
  AccountLayout as TokenAccountLayout,
  Token,
  TOKEN_PROGRAM_ID,
  u64,
} from '@solana/spl-token';
import {
  Connection,
  Keypair,
  PublicKey,
  Signer,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from '@solana/web3.js';

// import Rollbar from 'rollbar';

export enum TxnResponse {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED',
}

const FAUCET_PROGRAM_ID = new PublicKey('4bXpkKSV8swHSnwqtzuboGPaPDeEgAn4Vt8GfarV5rZt');

export const airdrop = async (
  abbrev: string,
  lamports: BN,
  wallet: Wallet,
  provider: Provider,
): Promise<[res: TxnResponse, txid: string[]]> => {
  console.log(abbrev);
  const connection = provider.connection;

  // if (program == null || user.assets == null || !user.wallet) {
  //   return [TxnResponse.Failed, []];
  // }

  // let reserve = market.reserves[abbrev];
  // const asset = Object.values(user.assets.tokens).find((asset) =>
  //   asset.tokenMintPubkey.equals(reserve.tokenMintPubkey)
  // );

  // if (asset == null) {
  //   return [TxnResponse.Failed, []];
  // }

  let ix: TransactionInstruction[] = [];
  let signers: Signer[] = [];

  //optionally create a token account for wallet

  let res: TxnResponse = TxnResponse.Failed;
  let txid: string[] = [];

  const walletTokenAccounts = await getOwnedAssociatedTokenAccounts(connection, wallet.publicKey);

  console.log(
    'mints',
    walletTokenAccounts.map(tokenAccount => tokenAccount.account.mint.toString()),
  );

  const mappedTokenAccounts = walletTokenAccounts
    .filter(tokenAccount => tokenAccount.account.mint.toString() === JET_USDC_MINT.toString())
    .map(tokenAccount => tokenAccount.publicKey);

  const userTokenAccountPubkey = mappedTokenAccounts.length === 0 ? null : mappedTokenAccounts[0];

  console.log(userTokenAccountPubkey);

  // TODO: Create a token account for the user if they don't have one
  const walletTokenPubkey = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    JET_USDC_MINT,
    wallet.publicKey,
  );

  if (userTokenAccountPubkey === null) {
    const createTokenAccountIx = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      JET_USDC_MINT,
      walletTokenPubkey,
      wallet.publicKey,
      wallet.publicKey,
    );
    ix.push(createTokenAccountIx);
  }

  // if (reserve.tokenMintPubkey.equals(NATIVE_MINT)) {
  //   // Sol airdrop
  //   try {
  //     // Use a specific endpoint. A hack because some devnet endpoints are unable to airdrop
  //     const endpoint = new anchor.web3.Connection(
  //       'https://api.devnet.solana.com',
  //       anchor.Provider.defaultOptions().commitment
  //     );
  //     const airdropTxnId = await endpoint.requestAirdrop(
  //       wallet.publicKey,
  //       parseInt(lamports.toString())
  //     );
  //     const confirmation = await endpoint.confirmTransaction(airdropTxnId);
  //     if (confirmation.value.err) {
  //       console.error(`Airdrop error: `);
  //       return [TxnResponse.Failed, []];
  //     } else {
  //       return [TxnResponse.Success, [airdropTxnId]];
  //     }
  //   } catch (error) {
  //     return [TxnResponse.Failed, []];
  //   }
  // Faucet airdrop
  const faucetAirdropIx = await buildFaucetAirdropIx(
    lamports,
    JET_USDC_MINT,
    walletTokenPubkey,
    JET_USDC_FAUCET_PUBKEY,
  );
  console.log('random');
  ix.push(faucetAirdropIx);

  [res, txid] = await sendTransaction(provider, ix, walletName, signers);
  // } else {
  //   // Mint to the destination token account
  //   const mintToIx = Token.createMintToInstruction(
  //     TOKEN_PROGRAM_ID,
  //     reserve.tokenMintPubkey,
  //     asset.walletTokenPubkey,
  //     user.wallet.publicKey,
  //     [],
  //     new u64(lamports.toArray())
  //   );
  //   ix.push(mintToIx);

  //   [res, txid] = await sendTransaction(program.provider, ix, signers);
  // }

  return [res, txid];
};

const buildFaucetAirdropIx = async (
  amount: BN,
  tokenMintPublicKey: PublicKey,
  destinationAccountPubkey: PublicKey,
  faucetPubkey: PublicKey,
) => {
  const pubkeyNonce = await PublicKey.findProgramAddress(
    [new TextEncoder().encode('faucet')],
    FAUCET_PROGRAM_ID,
  );

  const keys = [
    { pubkey: pubkeyNonce[0], isSigner: false, isWritable: false },
    {
      pubkey: tokenMintPublicKey,
      isSigner: false,
      isWritable: true,
    },
    { pubkey: destinationAccountPubkey, isSigner: false, isWritable: true },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    { pubkey: faucetPubkey, isSigner: false, isWritable: false },
  ];

  return new TransactionInstruction({
    programId: FAUCET_PROGRAM_ID,
    data: Buffer.from([1, ...amount.toArray('le', 8)]),
    keys,
  });
};

import './App.css';
import CitrusHeader from './components/CitrusHeader';
import IconRoutes from './components/IconRoutes';
import SolanaWalletAdapterContext from './components/SolanaWalletAdatperContext';
import Lending from './components/lending/Lending';
import Stats from './components/stats/Stats';
import Exchange from './components/swap/Exchange';
import { selectTransferSourceChain } from './store/selectors';
import { setTransactionState } from './store/transferSlice';
import { TransactionState } from './utils/consts';
import { getBackgroundColorClass } from './utils/getColorClass';
import handleWSMessage from './utils/handleWSMessage';
import ws from './utils/ws';
import { notification } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';

const testing = false;

function App() {
  const sourceChain = useSelector(selectTransferSourceChain);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log('connected');
      testing && ws.send('hello');
    };
    ws.onmessage = message => {
      handleWSMessage(message, dispatch);
    };
    ws.onclose = () => {
      notification.error({
        message: 'Connection closed',
        description: 'could not connect to servers. please refresh the page!',
        duration: 0,
        placement: 'bottomLeft',
      });
      dispatch(setTransactionState(TransactionState.DISABLED));
      console.log('closed');
    };
  }, [dispatch]);

  const backgroundColorClass = getBackgroundColorClass(sourceChain, location.pathname);
  // const bodySize = location.pathname === '/' ? 'max-w-lg' : 'max-w-3xl';

  return (
    <SolanaWalletAdapterContext>
      <div
        className={clsx(
          'min-h-screen w-full',
          'transition-all duration-500',
          backgroundColorClass,
          // 'background-animate',
          'text-slate-800',
        )}
      >
        <div className={clsx('bg-slate-900', location.pathname !== '/secret' && 'py-12')}>
          <CitrusHeader sourceChain={sourceChain} location={location.pathname} />
        </div>

        <div>
          <Routes>
            <Route path="/stats" element={<Stats sourceChain={sourceChain} />} />
            {['/exchange', '/avax', '/matic', '/bnb', '/eth', '/luna'].map(route => (
              <Route key={route} path={route} element={<Exchange sourceChain={sourceChain} />} />
            ))}
            {['/', '/lending'].map(route => (
              <Route key={route} path={route} element={<Lending />} />
            ))}
          </Routes>
        </div>
      </div>
    </SolanaWalletAdapterContext>
  );
}

export default App;
